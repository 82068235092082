import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  CalculsFormCalendrier,
  CalculsFormCalendrierDepense,
  CalculsFormCapitalisation,
  CalculsGlobal,
} from 'src/constants/calculs';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { isPrejudiceVictimeIndirecte } from 'src/helpers/prejudices/prejudice';
import { Bareme } from 'src/types/bareme.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  Prejudice,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierDepenseCapitalisationRow,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { displayDernierArrerage } from '../capitalisation';
import { debitSoldeAEchoirPrint } from '../debitSoldeAEchoirPrint';
import { FormTableRow, prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import {
  calendrierDepenseRecapitulatif,
  calendrierDepenseTableRows,
} from './calendrierDepensePrint';
import { fDate } from 'src/helpers/formatTime';
import { getMontantRevalorisePrint } from '../revalorisationPrint';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';
import { Victime } from 'src/types/victime.type';

type FormTablePrejudiceFormCalendrierDepenseCapitalisationRow = FormTableRow<
  PrejudiceFormCalendrierDepenseCapitalisationRow,
  keyof PrejudiceFormCalendrierDepenseCapitalisationRow,
  PrejudiceFormCalendrierDepenseCapitalisationRow[keyof PrejudiceFormCalendrierDepenseCapitalisationRow]
>;

export const calendrierDepenseCapitalisationPrint = ({
  procedure,
  prejudice,
  monetaryErosions,
  formData,
  tiersPayeurs,
  baremes,
  victimesIndirectes,
  victime,
}: {
  procedure: Procedure;
  prejudice: Pick<Prejudice, 'formData' | 'formType' | 'type'>;
  monetaryErosions: MonetaryErosion[];
  formData: PrejudiceFormCalendrierDepenseCapitalisation;
  tiersPayeurs: string[];
  baremes: Bareme[];
  victimesIndirectes: VictimeIndirecte[];
  victime: Victime;
}): (Paragraph | Table | TableOfContents)[] => {
  const isVictimeIndirecte = isPrejudiceVictimeIndirecte(
    prejudice.type || 'DEPENSE_SANTE_FUTURES',
  );
  const bareme = baremes.find(
    (bareme) => bareme._id === formData.capitalisation.bareme,
  );
  const baremeLabel = bareme ? getCapitalisationBaremeSourceLabel(bareme) : '';
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
  });
  const tiersPayeursCapitalisationRows: FormTablePrejudiceFormCalendrierDepenseCapitalisationRow[] =
    tiersPayeurs?.map((tiersPayeur) => ({
      name: 'capitalisationTiersPayeurs',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisationTiersPayeurs.parTiersPayeur.print.columnHeader',
        {
          tiersPayeur,
        },
      ),
      render: (row) => {
        const tiersPayeurRow =
          row.capitalisationTiersPayeurs.parTiersPayeur.find(
            (tiersPayeurRow) => tiersPayeurRow.tiersPayeur === tiersPayeur,
          );
        return i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisationTiersPayeurs.parTiersPayeur.print.value',
          {
            montantCapitalise: fCurrency(
              tiersPayeurRow?.montantCapitalise || 0,
            ),
            coefficient: fDecimalNumber(tiersPayeurRow?.coefficient || 0, 3),
            sommeACapitaliser: fCurrency(
              tiersPayeurRow?.sommeACapitaliser || 0,
            ),
          },
        );
      },
    }));
  const capitalisationRows: FormTablePrejudiceFormCalendrierDepenseCapitalisationRow[] =
    [
      {
        name: 'montantUnitaireAnnualise',
        headerLabel:
          i18next.t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.montantUnitaireAnnualise.columnHeader',
          ) || '',
        render: (row) =>
          CalculsFormCalendrierDepense.isDepenseCapitalisee({
            ...row,
            dateLiquidation: procedure.dateLiquidation
              ? new Date(procedure.dateLiquidation)
              : undefined,
          })
            ? fCurrency(
                CalculsFormCapitalisation.getMontantUnitaireAnnualise(row) || 0,
              )
            : '',
      },
      {
        name: 'montantUnitaireAnnualise',
        headerLabel:
          i18next.t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.montantUnitaireAnnualise.print.revalorise.columnHeader',
          ) || '',
        render: (row) =>
          CalculsFormCalendrierDepense.isDepenseCapitalisee({
            ...row,
            dateLiquidation: procedure.dateLiquidation
              ? new Date(procedure.dateLiquidation)
              : undefined,
          }) && !row.montantsDejaRevalorises
            ? getMontantRevalorisePrint({
                montant:
                  CalculsFormCapitalisation.getMontantUnitaireAnnualise(row) ||
                  0,
                montantsDejaRevalorises: row.montantsDejaRevalorises,
                anneeOrDateMontant: row.dateJustificatif || undefined,
                monetaryErosions,
                anneeOrDateLiquidation: procedure.dateLiquidation || undefined,
                displayStar: true,
                coefficientsType: formData.revalorisationCoefficientsType,
              })
            : '',
      },
      {
        name: 'capitalisation',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.dateAttribution.columnHeader',
        ),
        render: (row) =>
          row.capitalisation.dateAttribution
            ? fDate(row.capitalisation.dateAttribution)
            : '',
      },
      {
        name: 'capitalisation',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.bareme.columnHeader',
        ),
        render: () => baremeLabel,
      },
      {
        name: 'capitalisation',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.ageDernierArrerage.derniersArreragesColumnHeader',
        ),
        render: (row) =>
          displayDernierArrerage(
            row.capitalisation.ageDernierArrerage,
            row.capitalisation.isLastArrerageViager,
          ),
      },
      {
        name: 'capitalisation',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.coefficient.columnHeader',
        ),
        render: (row) =>
          row.capitalisation.coefficient || row.capitalisation.coefficient === 0
            ? fDecimalNumber(row.capitalisation.coefficient)
            : '',
      },
      {
        name: 'capitalisation',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.montantCapitalise.columnHeader',
        ),
        render: (row) => fCurrency(row.capitalisation.montantCapitalise),
      },
      ...tiersPayeursCapitalisationRows,
    ];
  const table = prejudiceFormTable(formData.rows, [
    ...calendrierDepenseTableRows({
      dateLiquidation: procedure.dateLiquidation,
      monetaryErosions,
      tiersPayeurs,
      victimesIndirectes,
      isVictimeIndirecte,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      prejudiceType: prejudice.type,
    }),
    ...(!shouldNotDisplayCapitalisation ? capitalisationRows : []),
  ]);
  const indemniteGlobaleARepartir =
    CalculsFormCapitalisation.getIndemnitesRepartie({
      rows: formData.rows,
      capitalisation: formData.capitalisation,
      capitalisationTiersPayeurs: formData.capitalisationTiersPayeurs,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      monetaryErosions,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      tiersPayeurs: procedure.tiersPayeurs,
      partResponsabilite: procedure.partResponsabilite,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
  const totaux = CalculsFormCalendrier.totauxDepenses({
    rows: formData.rows,
    tiersPayeurs,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    monetaryErosions,
    isCalculCapitalisation: true,
    isVictimesIndirectes: false,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });
  const totauxDepensesCapitalisation =
    CalculsFormCalendrierDepense.totauxDepensesCapitalisation({
      rows: formData.rows,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
    });
  const debitSoldeTable = debitSoldeAEchoirPrint({
    title: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.indemniteRepartie.print.title',
    ),
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
  });

  const notesPrint = prejudiceNotesPrint(formData.notes);
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text:
          i18next.t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.print.title',
            {
              prejudiceType: prejudice.type,
            },
          ) || '',
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...(table ? table : []),
    getParagraph(i18next.t('prejudice.revalorisation.print.footnote') || ''),
    getEmptyLine(),
    ...calendrierDepenseRecapitulatif({
      tiersPayeurs,
      totaux,
      totauxCapitalisation: {
        ...totauxDepensesCapitalisation,
        capitalisationTiersPayeurs: {
          parTiersPayeur:
            formData.capitalisationTiersPayeurs.parTiersPayeur.reduce(
              (accumulator, { tiersPayeur, montantCapitalise }) => ({
                ...accumulator,
                [tiersPayeur]: montantCapitalise,
              }),
              {},
            ),
          montantCapitalise:
            formData.capitalisationTiersPayeurs.montantCapitalise,
        },
        rentes: {
          montantAnnuel: 0,
          montantMensuel: 0,
          montantTrimestriel: 0,
        },
      },
      indemniteGlobaleARepartir: CalculsGlobal.getIndemnitesRepartie(
        totaux.resteACharge,
        totaux.priseEnChargeTiersPayeurs,
        procedure.partResponsabilite,
      ).indemniteGlobaleARepartir.solde,
      partResponsabilite: procedure.partResponsabilite,
      isCapitalisation: true,
      shouldNotDisplayCapitalisation,
    }),
    getEmptyLine(),
    ...debitSoldeTable,
  ];
};
