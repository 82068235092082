import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { capitalize } from 'lodash';
import {
  CalculsFormCalendrier,
  CalculsFormCalendrierDepense,
} from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { fDate } from 'src/helpers/formatTime';
import { isPrejudiceVictimeIndirecte } from 'src/helpers/prejudices/prejudice';
import {
  MonetaryErosion,
  MonetaryErosionsCoefficientsType,
} from 'src/types/monetaryErosion.type';
import {
  Prejudice,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseRow,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';
import { debitSoldeEchusPrint } from '../debitSoldeEchusPrint';
import {
  getMontantRevalorisePrint,
  getQuotientRevalorisationPrint,
} from '../revalorisationPrint';
import { getPeriodePrintByFrequence } from '../periodePrint';
import { FormTableRow, prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { debitSoldeEchusIndirectePrint } from '../debitSoldeEchusIndirectePrint';
import { TotauxDepensesCapitalisation } from 'src/constants/calculs/type';

export const calendrierDepenseTableRows = ({
  dateLiquidation,
  monetaryErosions,
  tiersPayeurs,
  isVictimeIndirecte,
  victimesIndirectes,
  prejudiceType,
  revalorisationCoefficientsType,
}: {
  dateLiquidation?: Date | string | null;
  monetaryErosions: MonetaryErosion[];
  tiersPayeurs: string[];
  isVictimeIndirecte: boolean;
  victimesIndirectes: VictimeIndirecte[];
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'smic'
  >;
  prejudiceType: PrejudiceType;
}): FormTableRow<
  PrejudiceFormCalendrierDepenseRow,
  keyof PrejudiceFormCalendrierDepenseRow,
  PrejudiceFormCalendrierDepenseRow[keyof PrejudiceFormCalendrierDepenseRow]
>[] => {
  const victimeIndirecteColumn: FormTableRow<
    PrejudiceFormCalendrierDepenseRow,
    keyof PrejudiceFormCalendrierDepenseRow,
    PrejudiceFormCalendrierDepenseRow[keyof PrejudiceFormCalendrierDepenseRow]
  > = {
    name: 'victimeIndirecte',
    headerLabel:
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.victimeIndirecte.columnHeader',
      ) || '',
    render: (row) => {
      const victimeIndirecte = victimesIndirectes.find(
        (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecte,
      );
      if (!victimeIndirecte) {
        return '';
      }
      return i18next.t('victimeIndirecte.displayNameLong', {
        nom: victimeIndirecte.nom,
        prenom: victimeIndirecte.prenom,
        lienVictime: victimeIndirecte.lienVictime,
        dateNaissance: victimeIndirecte.dateNaissance
          ? new Date(victimeIndirecte.dateNaissance).toLocaleDateString()
          : '',
      });
    },
  };

  return [
    {
      name: 'numerosPieces',
      headerLabel: i18next.t('numeroPiece.form.columnHeader') || '',
      render: (row) =>
        row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
    },
    ...(isVictimeIndirecte ? [victimeIndirecteColumn] : []),
    {
      name: 'intitule',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.intitule.columnHeader',
        ) || '',
    },
    ...(prejudiceType !== 'FRAIS_OBSEQUES'
      ? ([
          {
            name: 'dateDebut',
            headerLabel:
              i18next.t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateDebut.periodeColumnHeader',
              ) || '',
            render: (row) =>
              row.type === 'recurrente'
                ? getPeriodePrintByFrequence({
                    dateDebut: row.dateDebut,
                    dateFin: row.dateFin,
                    frequenceMontant: row.frequenceMontant,
                    dureeDansUniteChoisie: row.dureeDansUniteChoisie,
                  })
                : row.dateDebut
                  ? fDate(row.dateDebut)
                  : '',
          },
        ] as FormTableRow<
          PrejudiceFormCalendrierDepenseRow,
          keyof PrejudiceFormCalendrierDepenseRow,
          PrejudiceFormCalendrierDepenseRow[keyof PrejudiceFormCalendrierDepenseRow]
        >[])
      : []),
    {
      name: 'frequenceMontant',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.periodiciteColumnHeader',
        ) || '',
      render: (row) =>
        row.frequenceMontant
          ? row.frequenceMontant === 'non_renseigne'
            ? i18next.t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.print.ponctuelleOption',
              )
            : row.frequenceMontant === 'viagere'
              ? capitalize(
                  i18next.t(
                    'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.renouvellementMaintenance.print.value',
                    {
                      renouvellementMaintenance: row.renouvellementMaintenance,
                    },
                  ) || '',
                )
              : i18next.t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.quantite.print.value',
                  {
                    quantite: row.quantite,
                    frequenceMontant: row.frequenceMontant,
                  },
                )
          : '',
    },
    {
      name: 'dateJustificatif',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateJustificatif.columnHeader',
        ) || '',
      render: (row) =>
        row.dateJustificatif ? fDate(row.dateJustificatif) : '',
    },
    {
      name: 'montantUnitaire',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantUnitaire.columnHeader',
        ) || '',
      render: (row) => fCurrency(row.montantUnitaire),
    },
    {
      name: 'priseEnChargeTiersPayeurs',
      children: tiersPayeurs.map((tiersPayeur) => ({
        headerLabel:
          i18next.t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.priseEnChargeTiersPayeurs.columnHeader',
            {
              tiersPayeur,
            },
          ) || '',
        render: (row) =>
          fCurrency(
            row.priseEnChargeTiersPayeurs?.find(
              (priseEnChargeTiersPayeur) =>
                priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
            )?.montant || 0,
          ),
      })),
    },
    {
      name: 'montantTotal',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantTotal.columnHeader',
        ) || '',
      render: (row) => fCurrency(row.montantTotal),
    },
    {
      name: 'resteACharge',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.columnHeader',
        ) || '',
      render: (row) => fCurrency(row.resteACharge),
    },
    {
      name: 'resteACharge',
      headerLabel:
        i18next.t('prejudice.revalorisation.quotient.columnHeader') || '',
      render: (row) =>
        row.montantsDejaRevalorises
          ? ''
          : getQuotientRevalorisationPrint({
              coefficientsType: revalorisationCoefficientsType,
              anneeOrDateLiquidation: dateLiquidation || undefined,
              monetaryErosions,
              anneeOrDateMontant: row.dateJustificatif || undefined,
            }),
    },
    {
      name: 'resteACharge',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.print.revaloriseColumnHeader',
        ) || '',
      render: (row) =>
        row.montantsDejaRevalorises
          ? ''
          : getMontantRevalorisePrint({
              coefficientsType: revalorisationCoefficientsType,
              montant: row.resteACharge,
              anneeOrDateLiquidation: dateLiquidation || undefined,
              monetaryErosions,
              anneeOrDateMontant: row.dateJustificatif || undefined,
              montantsDejaRevalorises: row.montantsDejaRevalorises,
              displayStar: false,
            }),
    },
  ];
};

export const calendrierDepenseRecapitulatif = ({
  tiersPayeurs,
  totaux,
  totauxCapitalisation,
  indemniteGlobaleARepartir,
  partResponsabilite,
  isCapitalisation,
  displayTitle = true,
  shouldNotDisplayCapitalisation,
}: {
  tiersPayeurs: string[];
  totaux: ReturnType<typeof CalculsFormCalendrier.totauxDepenses>;
  totauxCapitalisation?: TotauxDepensesCapitalisation;
  indemniteGlobaleARepartir: number;
  partResponsabilite: number;
  isCapitalisation: boolean;
  displayTitle?: boolean;
  shouldNotDisplayCapitalisation?: boolean;
}): (Paragraph | Table | TableOfContents)[] => [
  ...(displayTitle
    ? [
        getParagraph({
          children: getTextRun({
            text:
              i18next.t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.total.print.title',
              ) || '',
            bold: true,
          }),
        }),
        getEmptyLine(),
      ]
    : []),
  simpleHorizontalTablePrint(
    [
      '',
      ...tiersPayeurs.map((tiersPayeur) => tiersPayeur),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.columnHeader',
      ) || '',
      ...(isCapitalisation && !shouldNotDisplayCapitalisation
        ? [
            i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.montantCapitalise.columnHeader',
            ),
            i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisationTiersPayeurs.montantCapitalise.print.total.columnHeader',
            ),
          ]
        : []),
    ],
    [
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.total.totalRow.print.rowHeader',
        ),
        columns: [
          ...tiersPayeurs.map((tiersPayeur) =>
            fCurrency(totaux.priseEnChargeTiersPayeurs[tiersPayeur] || 0),
          ),
          fCurrency(totaux.resteACharge),
          ...(isCapitalisation && !shouldNotDisplayCapitalisation
            ? [
                fCurrency(totauxCapitalisation?.montantCapitalise || 0),
                fCurrency(
                  totauxCapitalisation?.capitalisationTiersPayeurs
                    .montantCapitalise || 0,
                ),
                fCurrency(
                  (totauxCapitalisation?.montantCapitalise || 0) -
                    (totauxCapitalisation?.capitalisationTiersPayeurs
                      .montantCapitalise || 0),
                ),
              ]
            : []),
        ],
      },
    ],
  ),
  getParagraph(
    i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.total.indemniteRepartieRow.value',
      {
        value: fCurrency(indemniteGlobaleARepartir / partResponsabilite),
      },
    ) || '',
  ),
];

export const calendrierDepensePrint = ({
  procedure,
  prejudice,
  monetaryErosions,
  formData,
  tiersPayeurs,
  victimesIndirectes,
}: {
  procedure: Procedure;
  prejudice: Pick<Prejudice, 'type'>;
  monetaryErosions: MonetaryErosion[];
  formData: PrejudiceFormCalendrierDepense;
  tiersPayeurs: string[];
  victimesIndirectes: VictimeIndirecte[];
}): (Paragraph | Table | TableOfContents)[] => {
  const isVictimeIndirecte = isPrejudiceVictimeIndirecte(
    prejudice.type || 'DEPENSE_SANTE_ACTUELLES',
  );
  const table = prejudiceFormTable(
    formData.rows,
    calendrierDepenseTableRows({
      dateLiquidation: procedure.dateLiquidation,
      monetaryErosions,
      tiersPayeurs,
      isVictimeIndirecte,
      victimesIndirectes,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      prejudiceType: prejudice.type,
    }),
  );
  const totaux = CalculsFormCalendrier.totauxDepenses({
    rows: formData.rows,
    tiersPayeurs: procedure.tiersPayeurs,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    monetaryErosions,
    isCalculCapitalisation: false,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });
  let debitSoldeTable: (Paragraph | Table | TableOfContents)[] = [];
  let indemniteGlobaleARepartir = 0;
  if (isVictimeIndirecte) {
    const indemniteRepartie =
      CalculsFormCalendrierDepense.getIndemniteRepartieEchusIndirecte({
        rows: formData.rows,
        partResponsabilite:
          formData.partResponsabilite || procedure.partResponsabilite,
        tiersPayeurs: procedure.tiersPayeurs,
        dateLiquidation: procedure.dateLiquidation
          ? new Date(procedure.dateLiquidation)
          : undefined,
        monetaryErosions,
        revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      });
    debitSoldeTable = debitSoldeEchusIndirectePrint({
      indemniteGlobaleARepartir: indemniteRepartie,
      partResponsabilite:
        formData.partResponsabilite || procedure.partResponsabilite,
      victimesIndirectes,
    });
    indemniteGlobaleARepartir =
      indemniteRepartie.indemniteGlobaleARepartir.solde;
  } else {
    const indemniteRepartie =
      CalculsFormCalendrierDepense.getIndemniteRepartieEchus({
        rows: formData.rows,
        partResponsabilite: procedure.partResponsabilite,
        tiersPayeurs: procedure.tiersPayeurs,
        dateLiquidation: procedure.dateLiquidation
          ? new Date(procedure.dateLiquidation)
          : undefined,
        monetaryErosions,
        revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      });

    debitSoldeTable = debitSoldeEchusPrint({
      indemniteGlobaleARepartir: indemniteRepartie,
      partResponsabilite: procedure.partResponsabilite,
    });
    indemniteGlobaleARepartir =
      indemniteRepartie.indemniteGlobaleARepartir.solde;
  }

  const notesPrint = prejudiceNotesPrint(formData.notes);
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...(table ? table : []),
    getParagraph(i18next.t('prejudice.revalorisation.print.footnote') || ''),
    getEmptyLine(),
    ...calendrierDepenseRecapitulatif({
      tiersPayeurs,
      totaux,
      indemniteGlobaleARepartir,
      partResponsabilite: isVictimeIndirecte
        ? formData.partResponsabilite || procedure.partResponsabilite
        : procedure.partResponsabilite,
      isCapitalisation: false,
    }),
    getEmptyLine(),
    ...debitSoldeTable,
  ];
};
