import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Box, Grid, InputAdornment, Stack, Tabs } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee,
  PerteGainProfessionnelsActuelSituationRevenusReelsAnnee,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  PerteGainProfessionnelsFutursSituation,
  PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { StepManager } from '../prejudiceFormComponents/perteGainsProfessionnelsActuel/StepManager';
import { RegimeType, REGIME_TYPE_LISTE } from '../../../types/regime.type';
import { TotalPerteGainsFuturs } from '../prejudiceFormComponents/perteGainsProfessionnelsFuturs/TotalPerteGainsFuturs';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import {
  getCapitalisationBaremes,
  getShouldNotDisplayCapitalisation,
} from 'src/helpers/prejudices/capitalisation';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Procedure } from 'src/types/procedure.type';
import { useTranslation } from 'react-i18next';
import { dateString } from 'src/helpers/yup';
import {
  validationSchemaNumeroPieces,
  getDefaultMinAndMaxDate,
  validationSchemaPrejudiceBase,
  validationSchemaTiersPayeursCapitalisationObject,
  validationSchemaPrejudiceFormCapitalisationOrRenteBase,
} from 'src/constants/prejudice/validation';
import { intersection, omit } from 'lodash';
import { Victime } from 'src/types/victime.type';
import i18next from 'i18next';
import { PrejudiceTab } from '../PrejudiceTab';
import { TotalCapitalisation } from '../prejudiceFormComponents/Capitalisation/TotalCapitalisation';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';

export const defaultPrejudiceFormPerteGainProfessionnelsFuturTiersPayeurIndemnite =
  (
    tiersPayeur: string,
    anneeConsolidation: number,
    anneeLiquidation: number,
  ): PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs => ({
    tiersPayeur,
    netPaye: 0,
    csgRds: null,
    numerosPieces: { rows: [] },
    anneeTiersPayeursIndemnites: [
      ...Array(anneeLiquidation - anneeConsolidation + 1),
    ].map((_, index) => ({
      annee: anneeConsolidation + index,
      montantsDejaRevalorises: false,
      netFiscal: null,
      netPaye: null,
      csgRds: null,
    })),
  });

export const defaultPrejudiceFormPerteGainProfessionnelsFuturTiersPayeursIndemnite =
  (
    procedure: Procedure,
    anneeConsolidation: number,
    anneeLiquidation: number,
  ): PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[] =>
    procedure.tiersPayeurs.map((tiersPayeur) =>
      defaultPrejudiceFormPerteGainProfessionnelsFuturTiersPayeurIndemnite(
        tiersPayeur,
        anneeConsolidation,
        anneeLiquidation,
      ),
    );

export const defaultPrejudiceFormPerteGainProfessionnelsFuturAnneeRevenusReels =
  (annee: number): PerteGainProfessionnelsActuelSituationRevenusReelsAnnee => ({
    annee,
    netFiscal: 0,
    netPaye: 0,
    smicBrut: 0,
    victimeRedevable: false,
    montantsDejaRevalorises: false,
    numerosPieces: { rows: [] },
  });

export const defaultPrejudiceFormPerteGainProfessionnelsFuturAnneesRevenusReels =
  (
    anneeConsolidation: number,
    anneeLiquidation: number,
  ): PerteGainProfessionnelsActuelSituationRevenusReelsAnnee[] =>
    [...Array(anneeLiquidation - anneeConsolidation + 1)].map((_, index) =>
      defaultPrejudiceFormPerteGainProfessionnelsFuturAnneeRevenusReels(
        anneeConsolidation + index,
      ),
    );

export const defaultPrejudiceFormPerteGainProfessionnelsFuturAnneeRevenuTheoriqueValues =
  (annee: number) => ({
    annee,
    netFiscal: null,
    netPaye: null,
    smicBrut: 0,
    victimeRedevable: false,
    montantsDejaRevalorises: false,
    numerosPieces: { rows: [] },
  });

export const defaultPrejudiceFormPerteGainProfessionnelsFuturAnneesRevenuTheoriqueValues =
  (
    anneeConsolidation: number,
  ): PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee[] =>
    [
      anneeConsolidation - 3,
      anneeConsolidation - 2,
      anneeConsolidation - 1,
    ].map((annee) =>
      defaultPrejudiceFormPerteGainProfessionnelsFuturAnneeRevenuTheoriqueValues(
        annee,
      ),
    );

const defaultPrejudiceFormPerteGainProfessionnelsFutursValues = (
  anneeConsolidation: number,
  anneeLiquidation: number,
  procedure: Procedure,
  regime: RegimeType,
): PerteGainProfessionnelsFutursSituation => ({
  regime,
  anneesRevenusTheoriques:
    defaultPrejudiceFormPerteGainProfessionnelsFuturAnneesRevenuTheoriqueValues(
      anneeConsolidation,
    ),
  revenuTheorique: 0,
  anneesRevenusReels:
    defaultPrejudiceFormPerteGainProfessionnelsFuturAnneesRevenusReels(
      anneeConsolidation,
      anneeLiquidation,
    ),
  revenusReelsNet: 0,
  revenuDeReference: 0,
  totalPerteDeGain: 0,
  tiersPayeursIndemnites:
    defaultPrejudiceFormPerteGainProfessionnelsFuturTiersPayeursIndemnite(
      procedure,
      anneeConsolidation,
      anneeLiquidation,
    ),
  totalIndemnitesNet: 0,
  totalIndemnitesCsgCrds: 0,
  arrerageVictime: 0,
});

export const getDefaultPrejudiceFormPerteGainProfessionnelsFutursSituations = ({
  dateDebut,
  dateFin,
  procedure,
  existingSituations,
}: {
  dateDebut: string | null | undefined;
  dateFin: string | null | undefined;
  procedure: Procedure;
  existingSituations: PerteGainProfessionnelsFutursSituation[];
}): PerteGainProfessionnelsFutursSituation[] =>
  existingSituations.map((situation) => {
    const anneeDateDebut = dateDebut
      ? new Date(dateDebut).getFullYear()
      : undefined;
    const anneeDateFin = dateFin ? new Date(dateFin).getFullYear() : undefined;
    return {
      ...(anneeDateDebut && anneeDateFin
        ? defaultPrejudiceFormPerteGainProfessionnelsFutursValues(
            anneeDateDebut,
            anneeDateFin,
            procedure,
            situation.regime,
          )
        : {}),
      ...situation,
      anneesRevenusTheoriques: situation.anneesRevenusTheoriques
        ? situation.anneesRevenusTheoriques.map((anneeRevenusTheoriques) => ({
            ...defaultPrejudiceFormPerteGainProfessionnelsFuturAnneeRevenuTheoriqueValues(
              anneeRevenusTheoriques.annee,
            ),
            ...anneeRevenusTheoriques,
          }))
        : anneeDateDebut
          ? defaultPrejudiceFormPerteGainProfessionnelsFuturAnneesRevenuTheoriqueValues(
              anneeDateDebut,
            )
          : [],
      anneesRevenusReels: situation.anneesRevenusReels
        ? situation.anneesRevenusReels.map((anneeRevenusReels) => ({
            ...defaultPrejudiceFormPerteGainProfessionnelsFuturAnneeRevenusReels(
              anneeRevenusReels.annee,
            ),
            ...anneeRevenusReels,
          }))
        : anneeDateDebut && anneeDateFin
          ? defaultPrejudiceFormPerteGainProfessionnelsFuturAnneesRevenusReels(
              anneeDateDebut,
              anneeDateFin,
            )
          : [],
      tiersPayeursIndemnites:
        anneeDateDebut && anneeDateFin
          ? procedure.tiersPayeurs.map((tiersPayeur) => {
              const tiersPayeurIndemnites =
                situation.tiersPayeursIndemnites.find(
                  (tiersPayeurIndemnites) =>
                    tiersPayeurIndemnites.tiersPayeur === tiersPayeur,
                );
              return tiersPayeurIndemnites
                ? {
                    ...defaultPrejudiceFormPerteGainProfessionnelsFuturTiersPayeurIndemnite(
                      tiersPayeur,
                      anneeDateDebut,
                      anneeDateFin,
                    ),
                    ...tiersPayeurIndemnites,
                  }
                : defaultPrejudiceFormPerteGainProfessionnelsFuturTiersPayeurIndemnite(
                    tiersPayeur,
                    anneeDateDebut,
                    anneeDateFin,
                  );
            })
          : [],
    };
  });

export const validationSchemaOldFormPerteGainsProfessionnelsFuturs = ({
  procedure,
  prejudiceType,
  victime,
}: {
  procedure: Procedure;
  prejudiceType: PrejudiceType;
  victime: Victime;
}): yup.ObjectSchema<OldPrejudiceFormPerteGainProfessionnelsFuturs> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    victime,
    procedure,
    prejudiceType,
  });
  return validationSchemaPrejudiceBase
    .concat(validationSchemaPrejudiceFormCapitalisationOrRenteBase)
    .shape({
      situations: yup
        .array()
        .defined()
        .of(
          yup.object({
            regime: yup
              .string()
              .oneOf([...REGIME_TYPE_LISTE])
              .required(),
            anneesRevenusTheoriques: yup
              .array()
              .defined()
              .length(3)
              .of(
                yup.object({
                  numerosPieces: validationSchemaNumeroPieces,
                  annee: yup.number().required(),
                  netFiscal: yup.number().nullable().optional().defined(),
                  netPaye: yup.number().nullable().optional(),
                  smicBrut: yup.number().optional(),
                  victimeRedevable: yup.boolean().required(),
                  montantsDejaRevalorises: yup.boolean().required(),
                }),
              ),
            revenuDeReference: yup.number().nullable().required(),
            revenuTheorique: yup.number().required(),
            anneesRevenusReels: yup
              .array()
              .defined()
              .of(
                yup.object({
                  numerosPieces: validationSchemaNumeroPieces,
                  annee: yup.number().required(),
                  netFiscal: yup.number().required(),
                  smicBrut: yup.number().required().min(0),
                  victimeRedevable: yup.boolean().required(),
                  montantsDejaRevalorises: yup.boolean().required(),
                }),
              ),
            revenusReelsNet: yup.number().required(),
            tiersPayeursIndemnites: yup
              .array()
              .defined()
              .length(procedure.tiersPayeurs.length)
              .of(
                yup.object({
                  numerosPieces: validationSchemaNumeroPieces,
                  tiersPayeur: yup.string().required(),
                  netPaye: yup.number().optional().min(0),
                  anneeTiersPayeursIndemnites: yup
                    .array()
                    .defined()
                    .of(
                      yup.object({
                        annee: yup.number().required(),
                        netFiscal: yup.number().nullable().optional(),
                        netPaye: yup.number().nullable().optional(),
                        csgRds: yup.number().nullable().optional().defined(),
                      }),
                    ),
                  netFiscal: yup.number().nullable().optional(),
                  csgRds: yup.number().nullable().optional().defined(),
                  //TODO Find a way to validate those fields
                  // .test(
                  //   'anneeTiersPayeursIndemnites',
                  //   "Au moins une année d'indemnité est requise*",
                  //   (anneeTiersPayeursIndemnites) =>
                  //     anneeTiersPayeursIndemnites?.some(
                  //       (annee) =>
                  //       // annee.csgRds !== undefined,
                  //     ) ?? true,
                  // ),
                }),
              ),
            totalIndemnitesNet: yup.number().required().min(0),
            totalIndemnitesCsgCrds: yup.number().min(0).defined(),
            totalPerteDeGain: yup.number().defined(),
            arrerageVictime: yup.number().defined(),
          }),
        ),
      dateDebut: dateString()
        .nullable()
        .required()
        .minDate(undefined, minDate.message, minDate.date)
        .maxDate(undefined, maxDate.message, maxDate.date),
      dateFin: dateString()
        .nullable()
        .required()
        .minDate(
          'dateDebut',
          i18next.t('validation.prejudices.dates.minDateDebut') || '',
        )
        .maxDate(undefined, maxDate.message, maxDate.date),
      victimeSommeACapitaliser: yup.number().required().min(0),
      ageDernierArrerage: yup.lazy((value) =>
        value === ''
          ? (yup
              .string()
              .defined()
              .transform(() => null) as unknown as yup.NumberSchema<
              number,
              yup.AnyObject,
              undefined,
              ''
            >)
          : yup.number().defined().nullable().min(0),
      ),
      isLastArrerageViager: yup.boolean().optional(),
      baremeCapitalisation: yup.string().optional(),
      victimeCoefficientCapitalisation: yup.number().required().min(0),
      victimeTotalCapitalise: yup.number().required().min(0),
      ageDateAttribution: yup.number().optional(),
      capitalisationTiersPayeurs: yup.object(
        omit(validationSchemaTiersPayeursCapitalisationObject(), [
          'montantCapitalise',
        ]),
      ),
      tiersPayeursTotalCapitalise: yup.number().required().min(0),
      totalRevenuDeReference: yup.number().nullable().optional().defined(),
      PGPFReliquat: yup.number().nullable().defined(),
      activatePGPFReliquat: yup.boolean().nullable().defined(),
      formType: yup.string().oneOf(['DECLARATION_FISCALES']).required(),
      totalPerteDeGainAnnualise: yup.number().nullable().defined(),
      revalorisationCoefficientsType: yup
        .string()
        .oneOf(['smic', 'annuel'])
        .required(),
      totalCapitalisation: yup.number().defined(),
      enableCapitalisationDifferee: yup.boolean().optional(),
    });
};
export const oldPGPFDefaultValues = ({
  procedure,
  values,
  prejudiceType,
}: {
  procedure: Procedure;
  values: OldPrejudiceFormPerteGainProfessionnelsFuturs | undefined;
  prejudiceType: PrejudiceType;
}): OldPrejudiceFormPerteGainProfessionnelsFuturs => {
  const defaultDateDebut = values?.dateDebut || procedure.dateConsolidation;
  const defaultDateFin = values?.dateFin || procedure.dateLiquidation;
  return {
    ...prejudiceBaseDefaultValues({
      prejudiceType,
      values,
    }),
    notes: values?.notes || '',
    situations:
      getDefaultPrejudiceFormPerteGainProfessionnelsFutursSituations({
        dateDebut: defaultDateDebut,
        dateFin: defaultDateFin,
        procedure,
        existingSituations: values?.situations || [],
      }) || [],
    dateDebut: values?.dateDebut || procedure.dateConsolidation || null,
    dateFin: values?.dateFin || procedure.dateLiquidation || null,
    victimeSommeACapitaliser: values?.victimeSommeACapitaliser || 0,
    ageDernierArrerage: values?.ageDernierArrerage || null,
    isLastArrerageViager: values?.isLastArrerageViager || false,
    baremeCapitalisation: values?.baremeCapitalisation,
    victimeCoefficientCapitalisation:
      values?.victimeCoefficientCapitalisation || 0,
    victimeTotalCapitalise: values?.victimeTotalCapitalise || 0,
    ageDateAttribution: values?.ageDateAttribution,
    capitalisationTiersPayeurs: {
      parTiersPayeur: procedure.tiersPayeurs.map((tiersPayeur) => ({
        tiersPayeur,
        sommeACapitaliser: 0,
        coefficient: 0,
        montantCapitalise: 0,
        ...values?.capitalisationTiersPayeurs?.parTiersPayeur?.find(
          (row) => row.tiersPayeur === tiersPayeur,
        ),
      })),
    },
    tiersPayeursTotalCapitalise: values?.tiersPayeursTotalCapitalise || 0,
    activatePGPFReliquat: values?.activatePGPFReliquat || false,
    PGPFReliquat: values?.PGPFReliquat || 0,
    totalRevenuDeReference: values?.totalRevenuDeReference || 0,
    formType: values?.formType || 'DECLARATION_FISCALES',
    isRentesOption: values?.isRentesOption || false,
    rentes: values?.rentes || {
      montant: 0,
    },
    totalPerteDeGainAnnualise: values?.totalPerteDeGainAnnualise || 0,
    revalorisationCoefficientsType:
      values?.revalorisationCoefficientsType || 'smic',
    totalCapitalisation: values?.totalCapitalisation || 0,
    enableCapitalisationDifferee: values?.enableCapitalisationDifferee ?? false,
  };
};
interface Props
  extends PrejudiceFormProps<OldPrejudiceFormPerteGainProfessionnelsFuturs> {
  tauxIPP: number | null;
}

export const OldFormPerteGainsProfessionnelsFuturs: React.FC<Props> = ({
  victime,
  procedure,
  values,
  baremes,
  monetaryErosions,
  dateConsolidation,
  dateLiquidation,
  tauxIPP,
  allNumerosPieces,
  onPrevious,
  dateDeces,
  ...props
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTabs] = useState(0);

  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, victime.sexe),
    [baremes, victime.sexe],
  );
  const useFormReturn = useForm<OldPrejudiceFormPerteGainProfessionnelsFuturs>({
    defaultValues: oldPGPFDefaultValues({
      procedure,
      values,
      prejudiceType: props.prejudiceType,
    }),
    resolver: yupResolver(
      validationSchemaOldFormPerteGainsProfessionnelsFuturs({
        procedure,
        victime,
        prejudiceType: props.prejudiceType,
      }),
    ),
  });

  const {
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useFormReturn;

  const isFirstTabError = useMemo(() => {
    const firstTabFields: (keyof OldPrejudiceFormPerteGainProfessionnelsFuturs)[] =
      ['situations', 'dateDebut', 'dateFin'];
    return (
      intersection(Object.keys(errors), firstTabFields).length > 0 &&
      activeTab !== 0
    );
  }, [errors, activeTab]);

  const isSecondTabError = useMemo(() => {
    const secondTabFields: (keyof OldPrejudiceFormPerteGainProfessionnelsFuturs)[] =
      [
        'victimeSommeACapitaliser',
        'isLastArrerageViager',
        'baremeCapitalisation',
        'ageDernierArrerage',
        'victimeCoefficientCapitalisation',
        'victimeTotalCapitalise',
        'capitalisationTiersPayeurs',
        'PGPFReliquat',
        'activatePGPFReliquat',
        'totalRevenuDeReference',
        'notes',
      ];
    return (
      intersection(Object.keys(errors), secondTabFields).length > 0 &&
      activeTab !== 1
    );
  }, [errors, activeTab]);
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation,
    dateLiquidation,
    dateDeces,
  });

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<OldPrejudiceFormPerteGainProfessionnelsFuturs>
      {...props}
      victime={victime}
      procedure={procedure}
      monetaryErosions={monetaryErosions}
      baremes={baremes}
      {...useFormReturn}
      renderPrejudice={({ setAdditionalTitle }) => (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <TextFieldForm
              control={control}
              name="notes"
              label={t('prejudice.fields.notes.label')}
              placeholder={t('prejudice.fields.notes.placeholder') || ''}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DintilhacButton onClick={() => setShowDintilhac(true)} />
                  </InputAdornment>
                ),
              }}
              multiline
              maxRows={4}
              fullWidth
            />
            <DintilhacText
              open={showDintilhac}
              setOpen={setShowDintilhac}
              label={t('prejudice.fields.dintilhac.label')}
              content={i18next.t(
                `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
                {
                  defaultValue: '',
                },
              )}
            />
            <Tabs
              value={activeTab}
              onChange={(_, value) => setActiveTabs(value)}
            >
              <PrejudiceTab
                label={t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.PGPF',
                  {
                    context: victime.dateDeces ? 'deces' : undefined,
                  },
                )}
                value={0}
                isError={isFirstTabError}
              />
              {!shouldNotDisplayCapitalisation ? (
                <PrejudiceTab
                  label={t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.capitalisation',
                    {
                      context: victime.dateDeces ? 'deces' : undefined,
                    },
                  )}
                  value={1}
                  isError={isSecondTabError}
                />
              ) : null}
              <PrejudiceTab
                label={t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.total',
                )}
                value={2}
              />
            </Tabs>
          </Box>
          <Stack
            direction="column"
            spacing={4}
            sx={{ display: activeTab === 0 ? '' : 'none' }}
          >
            <StepManager
              setAdditionalTitle={setAdditionalTitle}
              procedure={procedure}
              control={control as any}
              trigger={trigger as any}
              values={values}
              getValues={getValues as any}
              isPGPF
              monetaryErosions={monetaryErosions}
              dommage={props.dommages?.[0]}
              victime={victime}
              dateConsolidation={dateConsolidation}
              dateLiquidation={dateLiquidation}
              onPrevious={onPrevious ? onPrevious : undefined}
              tauxIPP={tauxIPP}
              allNumerosPieces={allNumerosPieces}
              user={props.user}
            />
          </Stack>
          {!shouldNotDisplayCapitalisation ? (
            <Stack
              direction="column"
              sx={{ display: activeTab === 1 ? '' : 'none' }}
              spacing={2}
            >
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TotalCapitalisation
                    control={control}
                    victimeName={{
                      sommeACapitaliser: 'victimeSommeACapitaliser',
                      bareme: 'baremeCapitalisation',
                      coefficient: 'victimeCoefficientCapitalisation',
                      montantCapitalise: 'victimeTotalCapitalise',
                      isLastArrerageViager: 'isLastArrerageViager',
                      ageDernierArrerage: 'ageDernierArrerage',
                      ageDateAttribution: 'ageDateAttribution',
                      enableCapitalisationDifferee:
                        'enableCapitalisationDifferee',
                    }}
                    procedure={procedure}
                    dateLiquidation={
                      dateLiquidation ? new Date(dateLiquidation) : null
                    }
                    baremes={capitalisationBaremes}
                    dateNaissance={
                      victime.dateNaissance
                        ? new Date(victime.dateNaissance)
                        : null
                    }
                    dateDeces={
                      victime.dateDeces ? new Date(victime.dateDeces) : null
                    }
                    sexe={victime.sexe}
                    tiersPayeursName={{
                      root: 'capitalisationTiersPayeurs.parTiersPayeur',
                      montantCapitalise: 'tiersPayeursTotalCapitalise',
                    }}
                    tiersPayeurSuffix={{
                      sommeACapitaliser: 'sommeACapitaliser',
                      montantCapitalise: 'montantCapitalise',
                      coefficient: 'coefficient',
                    }}
                    titles={{
                      total: {
                        title:
                          t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.total.title',
                          ) || '',
                      },
                      victime: {
                        sommeACapitaliser: t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.sommeACapitaliser.title',
                        ),
                      },
                    }}
                    editable={{
                      victime: {
                        ageDateAttribution: true,
                      },
                    }}
                    defaultValuesNames={{
                      victimeSommeACapitaliser: 'totalPerteDeGainAnnualise',
                    }}
                    editedFieldsName="editedFields"
                    totalName="totalCapitalisation"
                  />
                </Grid>
              </Grid>
              <SavePrejudiceButton
                sx={{ alignSelf: 'center' }}
                victime={victime}
              />
            </Stack>
          ) : null}
          <Stack sx={{ display: activeTab === 2 ? '' : 'none' }} spacing={4}>
            <TotalPerteGainsFuturs
              control={control as any}
              procedure={procedure}
              dateConsolidation={dateConsolidation}
              dateLiquidation={dateLiquidation}
              dateDeces={dateDeces}
            />
            <SavePrejudiceButton
              sx={{ alignSelf: 'center' }}
              victime={victime}
            />
          </Stack>
        </>
      )}
    />
  );
};
