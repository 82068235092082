import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  Stack,
} from '@mui/material';
import { Control, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { LayoutTable, NormalTable, SpanNoWrap } from 'src/components/styled';
import { fCurrency } from 'src/helpers/formatNumber';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { CalculsGlobal } from 'src/constants/calculs';
import { Victime } from 'src/types/victime.type';
import { CalculPerteRevenusProche } from 'src/constants/calculs/calculPerteRevenusProche';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { Bareme, BaremeCapitalisationValue } from 'src/types/bareme.type';
import { getCapitalisationCoefficient } from 'src/helpers/prejudices/capitalisation';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { PartialRecapitulatif } from '../PartialRecapitulatif';
import { ReadFieldForm } from 'src/components/forms/ReadFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { CoefficientUnavailableInBaremeTooltipText } from 'src/components/client/prejudiceFormComponents/Capitalisation/CoefficientUnavailableInBaremeTooltipText';
import { fAge, fYears } from 'src/helpers/formatTime';
interface Props {
  control: Control<PrejudiceFormPerteRevenusProche>;
  procedure: Procedure;
  victime: Victime;
  dateLiquidation: Date | undefined;
  baremes: Bareme[];
  capitalisationBaremes: Bareme[];
  victimesIndirectes: VictimeIndirecte[];
}

export const PrejudiceViagerFoyer: React.FC<Props> = ({
  control,
  procedure,
  victime,
  dateLiquidation,
  baremes,
  capitalisationBaremes,
  victimesIndirectes,
}) => {
  const { t } = useTranslation();
  const ageLiquidation = CalculsGlobal.getAgeOrZero(
    victime.dateNaissance || new Date(),
    procedure.dateLiquidation || new Date(),
  );
  const anneesEntreDecesEtLiquidation =
    victime.dateDeces && dateLiquidation
      ? CalculsGlobal.getYears(new Date(victime.dateDeces), dateLiquidation)
      : 0;
  const ageDateAttribution =
    victime.dateNaissance && dateLiquidation
      ? CalculsGlobal.getAge(new Date(victime.dateNaissance), dateLiquidation)
      : victime.dateNaissance
        ? CalculsGlobal.getAge(new Date(victime.dateNaissance), new Date())
        : undefined;
  const conjoint = victimesIndirectes.find(
    (victimeIndirecte) => victimeIndirecte.lienVictime === 'conjointConcubin',
  );
  const sexeConjoint = conjoint ? conjoint.sexe : null;
  const ageDateAttributionConjoint = conjoint
    ? CalculsGlobal.getAgeOrZero(
        conjoint?.dateNaissance,
        procedure.dateLiquidation || new Date(),
      )
    : null;
  const { setValue } = useFormContext<PrejudiceFormPerteRevenusProche>();
  return (
    <>
      <Stack
        sx={{ maxWidth: '700px' }}
        spacing={2}
        direction="row"
        marginTop={2}
      >
        <Typography>
          {t(
            'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.perteAnnuelle.tableRowHeader',
          )}
        </Typography>
        <ReadFieldForm
          control={control}
          name="foyer.perteAnnuelle"
          render={(value) => <SpanNoWrap>{fCurrency(value)}</SpanNoWrap>}
        />
      </Stack>
      <Typography variant="h6" marginTop={2}>
        {t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.prejudiceViagerFoyer.entreDecesEtLiquidation.title',
        )}
      </Typography>
      <TableContainer sx={{ maxWidth: '700px' }}>
        <LayoutTable>
          <TableBody>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.anneesEntreDecesEtLiquidation.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                {fYears(anneesEntreDecesEtLiquidation)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViagerEntreDecesEtLiquidation.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedReadFieldForm
                  control={control}
                  name="foyer.prejudiceViagerEntreDecesEtLiquidation"
                  watchFields={['foyer.perteAnnuelle']}
                  compute={([perteAnnuelle]) => {
                    return CalculPerteRevenusProche.getPrejudiceViagerFoyerEntreDecesEtLiquidation(
                      {
                        perteAnnuelleFoyer: perteAnnuelle,
                        anneesEntreDecesEtLiquidation,
                      },
                    );
                  }}
                  render={(value) => <>{fCurrency(value)}</>}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </LayoutTable>
      </TableContainer>
      <Typography variant="h6" marginTop={4}>
        {t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.prejudiceViagerFoyer.apresLiquidation.title',
        )}
      </Typography>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.agePaiement.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t('prejudice.capitalisation.form.fields.bareme.title')}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.capitalisation.form.fields.coefficient.otherTitle',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.victime.title',
                )}
              </TableCell>
              <TableCell align="center">
                {ageLiquidation || ageLiquidation === 0
                  ? fAge(ageLiquidation)
                  : ''}
              </TableCell>
              <TableCell align="right">
                <SelectFieldForm
                  control={control}
                  name="baremeCapitalisation"
                  sx={{ minWidth: 268 }}
                  allowNoSelection
                  noSelectionText={t(
                    'prejudice.capitalisation.form.fields.bareme.noSelectionText',
                  )}
                  fullWidth
                  options={capitalisationBaremes.map((bareme) => ({
                    label: getCapitalisationBaremeSourceLabel(bareme),
                    value: bareme._id,
                  }))}
                  afterOnChange={(value) => {
                    const bareme = capitalisationBaremes.find(
                      (bareme) => bareme._id === value,
                    );
                    if (bareme?.source === 'CJ') {
                      setValue('foyer.prejudiceViagerApresLiquidation', 0);
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <ComputedPropsForm
                  control={control}
                  watchFields={['baremeCapitalisation']}
                  compute={([baremeCapitalisation]) => {
                    const bareme = baremes.find(
                      (bareme) => bareme._id === baremeCapitalisation,
                    );
                    return {
                      hidden: bareme?.source === 'CJ',
                    };
                  }}
                  render={() => (
                    <ComputedTextFieldForm
                      control={control}
                      name="victime.coefficientCapitalisation"
                      watchFields={['baremeCapitalisation']}
                      compute={([baremeCapitalisation]) => {
                        const bareme: Bareme | undefined = baremes.find(
                          (bareme) => bareme._id === baremeCapitalisation,
                        );
                        if (bareme?.source === 'CJ') {
                          return undefined;
                        }
                        if (
                          bareme &&
                          victime.dateNaissance &&
                          dateLiquidation
                        ) {
                          return getCapitalisationCoefficient({
                            sexe: victime.sexe,
                            baremeValues:
                              bareme.values as BaremeCapitalisationValue[],
                            ageDateAttribution: ageDateAttribution || 0,
                            isDernierArrerageViager: true,
                            dateNaissance: victime.dateNaissance,
                            dateLiquidation,
                            dateAttribution: dateLiquidation,
                            ageDernierArrerage: null,
                          });
                        }
                        return null;
                      }}
                      computeTooltip={([baremeCapitalisation], fieldValue) => {
                        const bareme = baremes?.find(
                          (bareme) => bareme._id === baremeCapitalisation,
                        );
                        if (
                          !!bareme &&
                          bareme.source !== 'CJ' &&
                          !!victime.dateNaissance &&
                          !!(ageDateAttribution || ageDateAttribution === 0) &&
                          fieldValue === null
                        ) {
                          return <CoefficientUnavailableInBaremeTooltipText />;
                        }
                      }}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: { scale: 3 },
                          nullable: true,
                        } as IMaskNumberProps,
                      }}
                      fullWidth
                      editedFieldsName="editedFields"
                    />
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.title',
                )}
              </TableCell>
              <TableCell align="center">
                {ageDateAttributionConjoint || ageDateAttributionConjoint === 0
                  ? fAge(ageDateAttributionConjoint)
                  : null}
              </TableCell>
              <TableCell align="right">
                <SelectFieldForm
                  control={control}
                  name="baremeCapitalisation"
                  sx={{ minWidth: 268 }}
                  fullWidth
                  allowNoSelection
                  noSelectionText={t(
                    'prejudice.capitalisation.form.fields.bareme.noSelectionText',
                  )}
                  options={capitalisationBaremes.map((bareme) => ({
                    label: getCapitalisationBaremeSourceLabel(bareme),
                    value: bareme._id,
                  }))}
                />
              </TableCell>
              <TableCell align="right">
                <ComputedPropsForm
                  control={control}
                  watchFields={['baremeCapitalisation']}
                  compute={([baremeCapitalisation]) => {
                    const bareme = baremes.find(
                      (bareme) => bareme._id === baremeCapitalisation,
                    );
                    return {
                      hidden: bareme?.source === 'CJ',
                    };
                  }}
                  render={() => (
                    <ComputedTextFieldForm
                      control={control}
                      name="conjoint.coefficientCapitalisation"
                      watchFields={['baremeCapitalisation']}
                      compute={([baremeCapitalisation]) => {
                        const bareme = baremes.find(
                          (bareme) => bareme._id === baremeCapitalisation,
                        );
                        if (bareme?.source === 'CJ') {
                          return undefined;
                        }
                        if (
                          bareme &&
                          conjoint?.dateNaissance &&
                          dateLiquidation &&
                          sexeConjoint &&
                          ageDateAttributionConjoint
                        ) {
                          return (
                            getCapitalisationCoefficient({
                              sexe: sexeConjoint,
                              baremeValues:
                                bareme.values as BaremeCapitalisationValue[],
                              ageDateAttribution:
                                ageDateAttributionConjoint.age,
                              isDernierArrerageViager: true,
                              ageDernierArrerage: null,
                              dateNaissance: conjoint?.dateNaissance,
                              dateLiquidation,
                              dateAttribution: dateLiquidation,
                            }) || 0
                          );
                        }
                        return 0;
                      }}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: { scale: 3 },
                        } as IMaskNumberProps,
                      }}
                      fullWidth
                      editedFieldsName="editedFields"
                    />
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </NormalTable>
      </TableContainer>
      <TableContainer sx={{ maxWidth: '700px' }}>
        <LayoutTable>
          <TableBody>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViagerApresLiquidation.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedPropsForm
                  control={control}
                  watchFields={['baremeCapitalisation']}
                  compute={([baremeCapitalisation]) => {
                    const bareme = baremes.find(
                      (bareme) => bareme._id === baremeCapitalisation,
                    );
                    return {
                      props: {
                        isTextField: bareme?.source === 'CJ',
                      },
                    };
                  }}
                  render={({ isTextField }) =>
                    isTextField ? (
                      <TextFieldForm
                        control={control}
                        name="foyer.prejudiceViagerApresLiquidation"
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                        fullWidth
                      />
                    ) : (
                      <ComputedTextFieldForm
                        control={control}
                        name="foyer.prejudiceViagerApresLiquidation"
                        watchFields={[
                          'foyer.perteAnnuelle',
                          'victime.coefficientCapitalisation',
                          'conjoint.coefficientCapitalisation',
                        ]}
                        compute={([
                          perteAnnuelle,
                          coefficientCapitalisationVictime,
                          coefficientCapitalisationConjoint,
                        ]) => {
                          return CalculPerteRevenusProche.getPrejudiceViagerFoyerApresLiquidation(
                            {
                              perteAnnuelleFoyer: perteAnnuelle,
                              coefficientCapitalisationVictime,
                              coefficientCapitalisationConjoint,
                            },
                          );
                        }}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                        editedFieldsName="editedFields"
                      />
                    )
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViager.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedReadFieldForm
                  control={control}
                  name="foyer.prejudiceViager"
                  watchFields={[
                    'foyer.prejudiceViagerEntreDecesEtLiquidation',
                    'foyer.prejudiceViagerApresLiquidation',
                  ]}
                  compute={([
                    prejudiceViagerEntreDecesEtLiquidation,
                    prejudiceViagerApresLiquidation,
                  ]) => {
                    return CalculPerteRevenusProche.getPrejudiceViagerFoyer({
                      prejudiceViagerEntreDecesEtLiquidation,
                      prejudiceViagerApresLiquidation,
                    });
                  }}
                  render={(value) => <>{fCurrency(value)}</>}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </LayoutTable>
      </TableContainer>
      <ComputedPropsForm
        control={control}
        watchFields={[
          'foyer.prejudiceViagerEntreDecesEtLiquidation',
          'foyer.prejudiceViagerApresLiquidation',
        ]}
        compute={([
          prejudiceViagerEntreDecesEtLiquidation,
          prejudiceViagerApresLiquidation,
        ]) => {
          return {
            props: {
              echus: prejudiceViagerEntreDecesEtLiquidation,
              aEchoir: prejudiceViagerApresLiquidation,
              total:
                (prejudiceViagerEntreDecesEtLiquidation || 0) +
                (prejudiceViagerApresLiquidation || 0),
            },
          };
        }}
        render={({ echus, aEchoir, total }) => (
          <PartialRecapitulatif
            title={t(
              'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.foyer.title',
            )}
            echus={echus}
            aEchoir={aEchoir}
            total={total}
          />
        )}
      />
    </>
  );
};
