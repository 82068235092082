import React from 'react';
import { Control, useFieldArray, UseFormSetValue } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Stack,
  TableBody,
  TableContainer,
} from '@mui/material';
import {
  Procedure,
  ProcedureHospitalisation,
} from '../../../../types/procedure.type';
import {
  NumeroPieceValuesRow,
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormCalendrierAssistanceRow,
  PrejudiceType,
} from '../../../../types/prejudice.type';
import { NormalTable } from '../../../styled';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Victime } from 'src/types/victime.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { TableAssistanceRow } from './TableAssistanceRow';
import { TableAssistanceHeader } from './TableAssistanceHeader';
import { TableAssistanceFooter } from './TableAssistanceFooter';

export const defaultTableAssistanceRow = ({
  tiersPayeurs,
  dateLiquidation,
  isCalculCapitalisation,
}: {
  tiersPayeurs: string[];
  dateLiquidation: Date | undefined;
  isCalculCapitalisation: boolean;
}): Omit<PrejudiceFormCalendrierAssistanceRow, 'type'> => ({
  dateDebut: null,
  dateFin: null,
  joursPeriode: 1,
  joursHospitalisationADeduire: 0,
  deduireJoursHospitalisation: false,
  joursRetenus: 1,
  joursConges: 365,
  totalJours: 1,
  typeAssistance: '',
  forfaitHoraire: 0,
  nombreHeureParUniteChoisie: 0,
  uniteNombreHeure: 'jour',
  dateJustificatif: !isCalculCapitalisation
    ? dateLiquidation?.toISOString() ?? null
    : null,
  montantsDejaRevalorises: true,
  montantTotal: 0,
  quantite: 0,
  priseEnChargeTiersPayeurs: tiersPayeurs.map((tiersPayeur) => ({
    tiersPayeur,
    montant: 0,
  })),
  deductionFiscale: 0,
  autresDeductions: 0,
  resteACharge: 0,

  commentaires: '',
  notes: '',
  numerosPieces: { rows: [] },
});

export const defaultTableAssistanceRowEchu = ({
  tiersPayeurs,
  dateLiquidation,
  isCalculCapitalisation,
}: {
  tiersPayeurs: string[];
  dateLiquidation: Date | undefined;
  isCalculCapitalisation: boolean;
}): PrejudiceFormCalendrierAssistanceRow => ({
  ...defaultTableAssistanceRow({
    tiersPayeurs,
    dateLiquidation,
    isCalculCapitalisation,
  }),
  type: 'echu',
});

export const defaultTableAssistanceRowAEchoir = ({
  tiersPayeurs,
  dateLiquidation,
  isCalculCapitalisation,
}: {
  tiersPayeurs: string[];
  dateLiquidation: Date | undefined;
  isCalculCapitalisation: boolean;
}): PrejudiceFormCalendrierAssistanceRow => ({
  ...defaultTableAssistanceRow({
    tiersPayeurs,
    dateLiquidation,
    isCalculCapitalisation,
  }),
  dateDebut: dateLiquidation ? addDays(dateLiquidation, 1).toISOString() : null,
  type: 'aEchoir',
});
interface Props {
  control:
    | Control<PrejudiceFormCalendrierAssistance>
    | Control<PrejudiceFormCalendrierAssistanceCapitalisation>;
  tiersPayeurs: string[];
  hospitalisations: ProcedureHospitalisation[];
  monetaryErosions: MonetaryErosion[];
  dateConsolidation: Date | undefined | null;
  dateLiquidation?: Date | undefined;
  isCalculCapitalisation?: boolean;
  prejudiceType: PrejudiceType;
  procedure: Procedure;
  victime: Victime;
  DFTPRows?: PrejudiceFormCalendrierAssistanceRow[] | null;
  allNumerosPieces: NumeroPieceValuesRow[];
  shouldNotDisplayCapitalisation: boolean;
  setValue?: UseFormSetValue<PrejudiceFormCalendrierAssistance>;
  dateDeces: Date | undefined;
}

export const TableAssistance: React.FC<Props> = ({
  control,
  tiersPayeurs,
  dateLiquidation,
  isCalculCapitalisation,
  DFTPRows,
  setValue,
  shouldNotDisplayCapitalisation,
  dateDeces,
  ...props
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    name: 'rows',
    control: control as Control<PrejudiceFormCalendrierAssistance>,
  });
  const handleAppendDFTPRows = () => {
    if (DFTPRows) {
      append(DFTPRows);
      setValue?.('isDFTPRowsAdded', true);
    }
  };
  return (
    <>
      <Stack alignItems="start" width="100%" direction="row" spacing={2}>
        <CalculsBox padding={2}>
          <FormControl>
            <FormLabel
              sx={{
                textAlign: 'center',
              }}
            >
              {t('prejudice.revalorisation.coefficientsType.choice.label')}
            </FormLabel>
            <RadioFormField
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name="revalorisationCoefficientsType"
              options={[
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.smic',
                  ),
                  value: 'smic',
                },
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                  ),
                  value: 'annuel',
                },
              ]}
              row={true}
            />
          </FormControl>
        </CalculsBox>
      </Stack>
      <TableContainer component={Paper}>
        <NormalTable size="medium" stickyHeader>
          <TableAssistanceHeader
            tiersPayeurs={tiersPayeurs}
            isCalculCapitalisation={isCalculCapitalisation}
            shouldNotDisplayCapitalisation={shouldNotDisplayCapitalisation}
          />
          <TableBody>
            {fields.map((field, index) => (
              <TableAssistanceRow
                key={field.id}
                field={field}
                index={index}
                control={control}
                tiersPayeurs={tiersPayeurs}
                dateLiquidation={dateLiquidation}
                isCalculCapitalisation={isCalculCapitalisation}
                onClickRemove={remove}
                shouldNotDisplayCapitalisation={shouldNotDisplayCapitalisation}
                dateDeces={dateDeces}
                {...props}
              />
            ))}
          </TableBody>
          <TableAssistanceFooter
            control={control}
            tiersPayeurs={tiersPayeurs}
            partResponsabilite={props.procedure.partResponsabilite}
            isCalculCapitalisation={isCalculCapitalisation}
            monetaryErosions={props.monetaryErosions}
            dateLiquidation={dateLiquidation}
            shouldNotDisplayCapitalisation={shouldNotDisplayCapitalisation}
          />
        </NormalTable>
      </TableContainer>
      <Grid container spacing={2}>
        {!isCalculCapitalisation && <Grid item xs={3} />}
        <Grid
          item
          xs={isCalculCapitalisation ? 12 : 6}
          display="flex"
          justifyContent="center"
          gap={2}
        >
          <Button
            onClick={() => {
              append(
                defaultTableAssistanceRowEchu({
                  tiersPayeurs,
                  dateLiquidation,
                  isCalculCapitalisation: Boolean(isCalculCapitalisation),
                }),
              );
            }}
          >
            {isCalculCapitalisation && !shouldNotDisplayCapitalisation
              ? t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.type.options.echu',
                )
              : t('forms.table.addRow')}
          </Button>
          {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
            <Button
              onClick={() => {
                append(
                  defaultTableAssistanceRowAEchoir({
                    tiersPayeurs,
                    dateLiquidation,
                    isCalculCapitalisation: Boolean(isCalculCapitalisation),
                  }),
                );
              }}
            >
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.type.options.aEchoir',
              )}
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          {!isCalculCapitalisation && (
            <ComputedPropsForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              watchFields={['isDFTPRowsAdded']}
              compute={([isDFTPRowsAdded]) => {
                return {
                  hidden: isDFTPRowsAdded || !DFTPRows || DFTPRows.length === 0,
                  props: {},
                };
              }}
              render={() => (
                <Button onClick={handleAppendDFTPRows}>
                  {t(
                    'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.addDFTPRows.buttonText',
                  )}
                </Button>
              )}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
