import {
  Bareme,
  BaremeCapitalisationValue,
  BaremeEsperanceDeVieValue,
} from 'src/types/bareme.type';
import i18n from 'i18next';
import { fPercent } from '../formatNumber';

export const getAgesLastArrerage = (
  baremeValues: BaremeCapitalisationValue[],
  sexe: 'm' | 'f' | 'u',
  ageDateAttribution: number,
): {
  ages: number[];
  viager: boolean;
} => {
  const agesLastArrerages = (
    baremeValues
      .find(
        (value) =>
          (value.gender === 'man' && sexe === 'm') ||
          (value.gender === 'woman' && sexe === 'f') ||
          (value.gender === 'undetermined' && sexe === 'u') ||
          value.gender === 'all',
      )
      ?.rows?.find(
        ({ ageDateAttribution: baremeAgeDateAttribution }) =>
          baremeAgeDateAttribution === ageDateAttribution,
      )?.columns ?? []
  )
    .filter(({ percentage }) => percentage !== null)
    .reduce(
      (
        accumulator: { ages: number[]; viager: boolean },
        { ageLastArrerage, isLastArrerageViagere },
      ) => ({
        ...accumulator,
        ages: [
          ...accumulator.ages,
          ...(ageLastArrerage ? [ageLastArrerage] : []),
        ],
        viager: isLastArrerageViagere || accumulator.viager,
      }),
      { ages: [], viager: false },
    );
  return {
    ages: agesLastArrerages.ages.filter((age) => !!age),
    viager: agesLastArrerages.viager,
  };
};

const getOtherYearsLabel = (year: number, otherYears: number[]): string => {
  const years = [year, ...otherYears].sort((a, b) => a - b);
  if (years.length > 2 && years[0]) {
    const isConsecutive = years.slice(1).reduce(
      (
        accumulator: { latest: number; areYearsConsecutive: boolean },
        currentYear,
      ) => {
        const isCurrentYearConsecutive = currentYear === accumulator.latest + 1;
        return {
          latest: currentYear,
          areYearsConsecutive: Boolean(
            accumulator.areYearsConsecutive && isCurrentYearConsecutive,
          ),
        };
      },
      { latest: years[0], areYearsConsecutive: true },
    );
    if (isConsecutive.areYearsConsecutive) {
      return `${years[0]} to ${years[years.length - 1]}`;
    }
  }
  return years.join(' - ');
};

const getYearLabel = (bareme: Bareme) =>
  bareme.otherYears && bareme.year
    ? getOtherYearsLabel(bareme.year, bareme.otherYears)
    : bareme.year;

export const getIndemnisationBaremeSourceLabel = (bareme: Bareme) =>
  i18n.t(`bareme.type.Indemnisation.sourceLabel.${bareme.source}`, {
    year: getYearLabel(bareme),
    ...(bareme.source === 'Quezel' && bareme.additionalProperties?.prejudice
      ? { prejudice: bareme.additionalProperties?.prejudice }
      : {}),
  });

export const getRIIBaremeSourceNameLabel = (bareme: Bareme) =>
  i18n.t(`bareme.type.RII.sourceLabel.${bareme.source}`, {
    year: getYearLabel(bareme),
  });

export const getCJCapitalisationBaremeLabel = (bareme: Bareme) =>
  i18n.t(`bareme.source.${bareme.source}`);

export const getCapitalisationBaremeSourceLabel = (bareme: Bareme) =>
  bareme.source === 'CJ'
    ? getCJCapitalisationBaremeLabel(bareme)
    : i18n.t(
        `bareme.type.Capitalisation.sourceLabel.${
          !!bareme?.additionalProperties?.taux ||
          bareme?.additionalProperties?.taux === 0
            ? 'withTaux'
            : 'withoutTaux'
        }`,
        {
          source: bareme.source,
          year: getYearLabel(bareme),
          taux:
            !!bareme?.additionalProperties?.taux ||
            bareme?.additionalProperties?.taux === 0
              ? fPercent(bareme?.additionalProperties?.taux)
              : undefined,
          context: bareme.isExtrapolated ? 'extrapolated' : undefined,
        },
      );

export const getProrataTemporisBaremeSourceLabel = (bareme: Bareme) => {
  return i18n.t(`bareme.type.EsperanceDeVie.sourceLabel.${bareme.source}`, {
    year: getYearLabel(bareme),
  });
};

export const getDefaultProrataTemporisCapitalisationBareme = (
  baremes: Bareme[],
) => baremes[0];

export const getBaremeSourceComment = (bareme: Bareme) => bareme.sourceComment;

export const getProrataTemporisBaremeSourceComment = (bareme: Bareme) =>
  bareme.source === 'GDP'
    ? bareme.sourceComment
    : bareme.otherSourceComments?.esperanceDeVie;

export const getDefaultListeProjectionBareme = (baremes: Bareme[]) =>
  baremes.find(
    (bareme) =>
      bareme.source === 'GDP' &&
      bareme.year === 2020 &&
      bareme.additionalProperties?.taux === 0,
  ) || baremes[0];

export const getBaremeEsperanceDeVieAnnees = (
  baremeValues: BaremeEsperanceDeVieValue[],
  victimeSexe: 'm' | 'f' | 'u',
  dateConsolidation: Date,
  dateLiquidation: Date,
): {
  anneeConsolidation: number | undefined;
  anneeLiquidation: number | undefined;
  anneeProcheConsolidation: number | undefined;
  anneeProcheLiquidation: number | undefined;
} | null => {
  const values = baremeValues.find(
    (value) =>
      (value.gender === 'man' && victimeSexe === 'm') ||
      (value.gender === 'woman' && victimeSexe === 'f') ||
      (value.gender === 'undetermined' && victimeSexe === 'u') ||
      value.gender === 'all',
  )?.values;
  if (values) {
    const annees = Object.keys(values)
      .map(Number)
      .sort((a, b) => a - b);
    const anneeConsolidation = annees.find(
      (annee) => annee === dateConsolidation.getFullYear(),
    );
    const anneeLiquidation = annees.find(
      (annee) => annee === dateLiquidation.getFullYear(),
    );
    const anneeProcheConsolidation =
      annees.find((annee) => annee > dateConsolidation.getFullYear()) ||
      annees[annees.length - 1];
    const anneeProcheLiquidation =
      annees.find((annee) => annee > dateLiquidation.getFullYear()) ||
      annees[annees.length - 1];
    return {
      anneeConsolidation,
      anneeLiquidation,
      anneeProcheConsolidation,
      anneeProcheLiquidation,
    };
  } else {
    return null;
  }
};
