import i18next from 'i18next';
import { isDate, omit } from 'lodash';
import { CalculsGlobal } from 'src/constants/calculs';
import {
  MonetaryErosion,
  MonetaryErosionsCoefficientsType,
} from 'src/types/monetaryErosion.type';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  Prejudice,
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierValeur,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormMateriel,
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
} from 'src/types/prejudice.type';
import { fCurrency, fDecimalNumber } from '../formatNumber';
import { parseYear, parseDate } from '../time';
import { fMonthAndYear, fYear } from '../formatTime';

type GetMontantRevaloriseAnnuelParameters = {
  anneeOrDateMontant: number | Date | string | undefined;
  anneeOrDateLiquidation: number | Date | string | undefined;
  coefficientsType?: Extract<MonetaryErosionsCoefficientsType, 'annuel'>;
};

type GetMontantRevaloriseMensuelParameters = {
  anneeOrDateMontant: Date | string | undefined;
  anneeOrDateLiquidation: Date | string | undefined;
  coefficientsType: Extract<MonetaryErosionsCoefficientsType, 'mensuel'>;
};

type GetMontantRevaloriseSmicParameters = {
  anneeOrDateMontant: number | Date | string | undefined;
  anneeOrDateLiquidation: number | Date | string | undefined;
  coefficientsType: Extract<MonetaryErosionsCoefficientsType, 'smic'>;
};

type GetMontantRevaloriseSharedParameters = {
  montant: number;
  monetaryErosions: MonetaryErosion[];
};

export const isPrejudiceRevalorise = (
  prejudice: Pick<Prejudice, 'formData' | 'formType'>,
): boolean => {
  switch (prejudice.formType) {
    case 'CALENDRIER_VALEURS':
    case 'MATERIEL':
      return (
        prejudice.formData as
          | PrejudiceFormCalendrierValeur
          | PrejudiceFormMateriel
      ).rows.some(
        (row) =>
          row.montantsDejaRevalorises === false && !!row.montant && !!row.date,
      );
    case 'CALENDRIER_ASSISTANCE':
    case 'CALENDRIER_ASSISTANCE_CAPITALISATION':
      return (
        prejudice.formData as PrejudiceFormCalendrierAssistance
      ).rows.some(
        (row) =>
          row.montantsDejaRevalorises === false &&
          (!!row.resteACharge || !!row.annualiseMontantTotal) &&
          !!row.dateJustificatif,
      );
    case 'CALENDRIER_DEPENSE':
      return (prejudice.formData as PrejudiceFormCalendrierDepense).rows.some(
        (row) =>
          row.montantsDejaRevalorises === false &&
          (!!row.resteACharge || !!row.montantUnitaire) &&
          !!row.dateJustificatif,
      );
    case 'CALENDRIER_DEPENSE_CAPITALISATION': {
      const formData =
        prejudice.formData as PrejudiceFormCalendrierDepenseCapitalisation;
      return formData.rows.some(
        (row) =>
          row.montantsDejaRevalorises === false &&
          (!!row.resteACharge || !!row.montantUnitaire) &&
          !!row.dateJustificatif,
      );
    }
    case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL':
    case 'PERTE_GAINS_PROFESSIONNELS_FUTURS': {
      let formData = prejudice.formData as
        | OldPrejudiceFormPerteGainProfessionnelsActuel
        | OldPrejudiceFormPerteGainProfessionnelsFuturs
        | NewPrejudiceFormPerteGainProfessionnelsActuel
        | NewPrejudiceFormPerteGainProfessionnelsFuturs
        | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
        | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;

      switch (formData.formType) {
        case 'DECLARATION_FISCALES':
          return (
            prejudice.formData as
              | OldPrejudiceFormPerteGainProfessionnelsActuel
              | OldPrejudiceFormPerteGainProfessionnelsFuturs
          ).situations.some(
            (situation) =>
              situation.anneesRevenusReels.some(
                (annee) =>
                  !annee.montantsDejaRevalorises &&
                  !!annee.netPaye &&
                  !!annee.annee,
              ) ||
              situation.anneesRevenusTheoriques.some(
                (annee) =>
                  !annee.montantsDejaRevalorises &&
                  !!annee.netPaye &&
                  !!annee.annee,
              ),
          );
        case 'REVENUS': {
          formData = formData as
            | NewPrejudiceFormPerteGainProfessionnelsActuel
            | NewPrejudiceFormPerteGainProfessionnelsFuturs;
          return (
            formData.perteDeGainsProfessionnels.rows.some(
              (row) =>
                !row.montantsDejaRevalorises &&
                !!row.revenuPercuParPeriode &&
                !!row.dateDebut,
            ) ||
            formData.revenuActiviteAnnuelDeReference.rows.some(
              (row) =>
                !row.montantsDejaRevalorises && !!row.revenuNet && !!row.annee,
            )
          );
        }
        case 'SAISIE_DIRECTE':
          formData = formData as
            | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
            | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
          return formData.perteDeGainsProfessionnels.rows.some(
            (row) =>
              !row.montantsDejaRevalorises &&
              !!row.revenuPercuParPeriode &&
              !!row.dateDebut,
          );
        default:
          return false;
      }
    }
    case 'PERTES_REVENUS_PROCHE': {
      const formData = prejudice.formData as
        | PrejudiceFormPerteRevenusProche
        | PrejudiceFormPerteRevenusProcheVictimeNonDecedee;
      // We check which form is it
      if (!('baremeCapitalisation' in formData)) {
        return false;
      }
      return (
        (formData.victime.revenu.dejaRevalorise === false &&
          !!formData.victime.revenu.montant &&
          !!formData.victime.revenu.annee) ||
        (formData.conjoint.revenuAvantDeces.dejaRevalorise === false &&
          !!formData.conjoint.revenuAvantDeces.montant &&
          !!formData.conjoint.revenuAvantDeces.annee) ||
        (formData.conjoint.revenuApresDeces.dejaRevalorise === false &&
          !!formData.conjoint.revenuApresDeces.montant &&
          !!formData.conjoint.revenuApresDeces.annee)
      );
    }
    case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL':
    case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL':
    case 'CALENDRIER_HOSPITALISATION':
    case 'LISTE_PROJECTION':
    case 'RETRAITE':
    case 'VALEUR':
    case 'VALEUR_VICTIMES_INDIRECTES':
    default:
      return false;
  }
};

const getMontantRevaloriseAnnuel = ({
  anneeOrDateMontant,
  monetaryErosions,
  anneeOrDateLiquidation,
  montant,
}: Omit<GetMontantRevaloriseAnnuelParameters, 'coefficientsType'> &
  GetMontantRevaloriseSharedParameters) => {
  const anneeMontant = parseYear(anneeOrDateMontant);
  const anneeLiquidation = parseYear(anneeOrDateLiquidation);
  const coefficients = monetaryErosions.find(
    (monetaryErosion) => monetaryErosion.coefficientsType === 'annuel',
  )?.coefficients;
  if (!anneeMontant || !anneeLiquidation || !coefficients) {
    return null;
  }
  return CalculsGlobal.getMontantRevaloriseAnnuel(
    montant,
    coefficients,
    anneeMontant,
    anneeLiquidation,
  );
};

const getMontantRevaloriseMensuel = ({
  montant,
  monetaryErosions,
  ...dates
}: {
  dateMontant: Date | string | undefined;
  dateLiquidation: Date | string | undefined;
} & GetMontantRevaloriseSharedParameters) => {
  const coefficients = monetaryErosions.find(
    (monetaryErosion) => monetaryErosion.coefficientsType === 'mensuel',
  )?.coefficientsByMonth;
  const dateMontant = parseDate(dates.dateMontant);
  const dateLiquidation = parseDate(dates.dateLiquidation);
  if (!isDate(dateMontant) || !isDate(dateLiquidation) || !coefficients) {
    return null;
  }
  return CalculsGlobal.getMontantRevaloriseMensuel(
    montant,
    coefficients,
    dateMontant,
    dateLiquidation,
  );
};

export const getMontantRevaloriseSmic = ({
  anneeOrDateMontant,
  monetaryErosions,
  anneeOrDateLiquidation,
  montant,
}: Omit<GetMontantRevaloriseSmicParameters, 'coefficientsType'> &
  GetMontantRevaloriseSharedParameters) => {
  const anneeMontant = parseYear(anneeOrDateMontant);
  const anneeLiquidation = parseYear(anneeOrDateLiquidation);
  const coefficients = monetaryErosions.find(
    (monetaryErosion) => monetaryErosion.coefficientsType === 'smic',
  )?.coefficients;
  if (!anneeMontant || !anneeLiquidation || !coefficients) {
    return null;
  }
  return CalculsGlobal.getMontantRevaloriseSmic(
    montant,
    coefficients,
    anneeMontant,
    anneeLiquidation,
  );
};

export const getQuotientRevalorisationTooltip = (
  parameters: Parameters<typeof getDisplayedQuotientRevalorisations>[0],
): string => {
  switch (parameters.coefficientsType) {
    default:
    case 'annuel': {
      const indices = getIndicesRevalorisationsAnnuel(parameters);
      if (!indices) {
        return '';
      }
      return (
        i18next.t('prejudice.revalorisation.quotient.indicesWithYears', {
          indiceAnneeLiquidation: indices.indiceLiquidation,
          indiceAnneeMontant: indices.indiceMontant,
          anneeLiquidation:
            indices.anneeLiquidation || indices.anneeLiquidation === 0
              ? fYear(indices.anneeLiquidation)
              : '',
          anneeMontant:
            indices.anneeMontant || indices.anneeMontant === 0
              ? fYear(indices.anneeMontant)
              : '',
        }) || ''
      );
    }
    case 'mensuel': {
      const indices = getIndicesRevalorisationsMensuel({
        ...omit(parameters, ['coefficientsType']),
        dateMontant: parameters.anneeOrDateMontant,
        dateLiquidation: parameters.anneeOrDateLiquidation,
      });
      if (!indices) {
        return '';
      }
      return (
        i18next.t('prejudice.revalorisation.quotient.indicesWithDates', {
          indiceDateLiquidation: indices.indiceLiquidation,
          indiceDateMontant: indices.indiceMontant,
          dateLiquidation:
            indices.anneeLiquidation !== null &&
            indices.moisLiquidation !== null
              ? fMonthAndYear({
                  year: indices.anneeLiquidation,
                  month: indices.moisLiquidation,
                })
              : '',
          dateMontant:
            indices.anneeMontant !== null && indices.moisMontant !== null
              ? fMonthAndYear({
                  year: indices.anneeMontant,
                  month: indices.moisMontant,
                })
              : '',
        }) || ''
      );
    }
    case 'smic': {
      const smics = getRevalorisationsSmics(parameters);
      if (smics?.smicAnneeLiquidation && smics?.smicAnneeMontant) {
        return (
          i18next.t('prejudice.revalorisation.quotient.smics.value', {
            smicLiquidation: fCurrency(smics.smicAnneeLiquidation.value),
            smicMontant: fCurrency(smics.smicAnneeMontant.value),
            anneeLiquidation: smics.smicAnneeLiquidation.annee,
            anneeMontant: smics.smicAnneeMontant.annee,
          }) || ''
        );
      }
      return '';
    }
  }
};

export const getMontantRevalorise = (
  parameters: GetMontantRevaloriseSharedParameters &
    (
      | GetMontantRevaloriseAnnuelParameters
      | GetMontantRevaloriseMensuelParameters
      | GetMontantRevaloriseSmicParameters
    ),
): number | null => {
  switch (parameters.coefficientsType) {
    case 'mensuel':
      return getMontantRevaloriseMensuel({
        ...omit(parameters, [
          'coefficientsType',
          'anneeOrDateMontant',
          'anneeOrDateLiquidation',
        ]),
        dateMontant: parameters.anneeOrDateMontant,
        dateLiquidation: parameters.anneeOrDateLiquidation,
      });
    case 'smic':
      return getMontantRevaloriseSmic(omit(parameters, 'coefficientsType'));
    case 'annuel':
    default:
      return getMontantRevaloriseAnnuel(omit(parameters, 'coefficientsType'));
  }
};

export const getRevalorisationTooltipContent = ({
  montantRevalorise,
  montantsDejaRevalorises,
  dejaRevaloriseText = i18next.t(
    'prejudice.revalorisation.tooltip.dejaRevaloriseText',
  ) || '',
  revaloriseText = i18next.t(
    'prejudice.revalorisation.tooltip.revaloriseText',
  ) || '',
}: {
  montantRevalorise?: number | null;
  montantsDejaRevalorises: boolean;
  dejaRevaloriseText?: string;
  revaloriseText?: string;
}) => {
  if (montantsDejaRevalorises) {
    return dejaRevaloriseText;
  } else if (montantRevalorise || montantRevalorise === 0) {
    return `${revaloriseText} ${fCurrency(montantRevalorise)}`;
  } else {
    return '';
  }
};

export const getRevalorisationTooltip = ({
  montantsDejaRevalorises,
  dejaRevaloriseText,
  revaloriseText,
  ...otherParameters
}: {
  montantsDejaRevalorises: boolean;
  dejaRevaloriseText?: string;
  revaloriseText?: string;
} & Parameters<typeof getMontantRevalorise>[0]): string => {
  let montantRevalorise: number | null = null;
  if (!montantsDejaRevalorises) {
    montantRevalorise = getMontantRevalorise(otherParameters);
  }
  return getRevalorisationTooltipContent({
    montantsDejaRevalorises,
    montantRevalorise,
    revaloriseText,
    dejaRevaloriseText,
  });
};

const getIndicesRevalorisationsMensuel = ({
  monetaryErosions,
  ...otherParameters
}: {
  monetaryErosions: MonetaryErosion[];
  dateMontant: Date | string | undefined;
  dateLiquidation: Date | string | undefined;
}) => {
  const coefficients = monetaryErosions.find(
    (monetaryErosion) => monetaryErosion.coefficientsType === 'mensuel',
  )?.coefficientsByMonth;
  const dateMontant = parseDate(otherParameters.dateMontant);
  const dateLiquidation = parseDate(otherParameters.dateLiquidation);
  if (!isDate(dateMontant) || !isDate(dateLiquidation) || !coefficients) {
    return null;
  }
  return CalculsGlobal.getIndicesRevalorisationsMensuel(
    coefficients,
    dateMontant,
    dateLiquidation,
  );
};

const getIndicesRevalorisationsAnnuel = ({
  monetaryErosions,
  anneeOrDateMontant,
  anneeOrDateLiquidation,
}: {
  monetaryErosions: MonetaryErosion[];
  anneeOrDateMontant: number | Date | string | undefined;
  anneeOrDateLiquidation: number | Date | string | undefined;
}) => {
  const anneeMontant = parseYear(anneeOrDateMontant);
  const anneeLiquidation = parseYear(anneeOrDateLiquidation);
  const coefficients = monetaryErosions.find(
    (monetaryErosion) => monetaryErosion.coefficientsType === 'annuel',
  )?.coefficients;
  if (!anneeMontant || !anneeLiquidation || !coefficients) {
    return null;
  }
  return CalculsGlobal.getIndicesRevalorisationsAnnuel(
    coefficients,
    anneeMontant,
    anneeLiquidation,
  );
};

export const getRevalorisationsSmics = ({
  monetaryErosions,
  anneeOrDateMontant,
  anneeOrDateLiquidation,
}: {
  monetaryErosions: MonetaryErosion[];
  anneeOrDateMontant: number | Date | string | undefined;
  anneeOrDateLiquidation: number | Date | string | undefined;
}) => {
  const anneeMontant = parseYear(anneeOrDateMontant);
  const anneeLiquidation = parseYear(anneeOrDateLiquidation);
  const coefficients = monetaryErosions.find(
    (monetaryErosion) => monetaryErosion.coefficientsType === 'smic',
  )?.coefficients;
  if (!anneeMontant || !anneeLiquidation || !coefficients) {
    return null;
  }
  return CalculsGlobal.getRevalorisationsSmics(
    coefficients,
    anneeMontant,
    anneeLiquidation,
  );
};

export const getIndicesRevalorisation = (
  parameters: { monetaryErosions: MonetaryErosion[] } & (
    | {
        anneeOrDateMontant: number | Date | string | undefined;
        anneeOrDateLiquidation: number | Date | string | undefined;
        coefficientsType?: Extract<MonetaryErosionsCoefficientsType, 'annuel'>;
      }
    | {
        anneeOrDateMontant: Date | string | undefined;
        anneeOrDateLiquidation: Date | string | undefined;
        coefficientsType: Extract<MonetaryErosionsCoefficientsType, 'mensuel'>;
      }
  ),
) => {
  switch (parameters.coefficientsType) {
    case 'mensuel':
      return getIndicesRevalorisationsMensuel({
        ...omit(parameters, [
          'coefficientsType',
          'anneeOrDateMontant',
          'anneeOrDateLiquidation',
        ]),
        dateMontant: parameters.anneeOrDateMontant,
        dateLiquidation: parameters.anneeOrDateLiquidation,
      });
    default:
    case 'annuel':
      return getIndicesRevalorisationsAnnuel(
        omit(parameters, 'coefficientsType'),
      );
  }
};

export const getDisplayedQuotientRevalorisations = (
  parameters: { monetaryErosions: MonetaryErosion[] } & (
    | {
        anneeOrDateMontant: number | Date | string | undefined;
        anneeOrDateLiquidation: number | Date | string | undefined;
        coefficientsType?: Extract<MonetaryErosionsCoefficientsType, 'annuel'>;
      }
    | {
        anneeOrDateMontant: number | Date | string | undefined;
        anneeOrDateLiquidation: number | Date | string | undefined;
        coefficientsType: Extract<MonetaryErosionsCoefficientsType, 'smic'>;
      }
    | {
        anneeOrDateMontant: Date | string | undefined;
        anneeOrDateLiquidation: Date | string | undefined;
        coefficientsType: Extract<MonetaryErosionsCoefficientsType, 'mensuel'>;
      }
  ),
): string => {
  switch (parameters.coefficientsType) {
    default:
    case 'annuel':
    case 'mensuel': {
      const indices = getIndicesRevalorisation(parameters);
      if (indices) {
        return (
          i18next.t('prejudice.revalorisation.quotient.indices', {
            indiceLiquidation: indices.indiceLiquidation,
            indiceMontant: indices.indiceMontant,
          }) || ''
        );
      }
      return '';
    }
    case 'smic':
      const smics = getRevalorisationsSmics(parameters);
      if (smics?.smicAnneeLiquidation && smics?.smicAnneeMontant) {
        return (
          i18next.t('prejudice.revalorisation.quotient.smics.value', {
            smicLiquidation: fCurrency(smics.smicAnneeLiquidation.value),
            smicMontant: fCurrency(smics.smicAnneeMontant.value),
            anneeLiquidation: smics.smicAnneeLiquidation.annee,
            anneeMontant: smics.smicAnneeMontant.annee,
          }) || ''
        );
      }
      return '';
  }
};

export const getRevalorisationFormula = (
  parameters: Parameters<typeof getMontantRevalorise>[0],
): string => {
  switch (parameters.coefficientsType) {
    default:
    case 'annuel':
    case 'mensuel': {
      const indices = getIndicesRevalorisation(parameters);
      const anneeLiquidation = parseYear(parameters.anneeOrDateLiquidation);
      const anneeMontant = parseYear(parameters.anneeOrDateMontant);
      if (
        indices &&
        parameters.coefficientsType === 'annuel' &&
        (indices.indiceLiquidation || indices.indiceLiquidation === 0) &&
        (indices.indiceMontant || indices.indiceMontant === 0) &&
        anneeLiquidation &&
        anneeMontant
      ) {
        return (
          i18next.t(
            'prejudice.revalorisation.coefficientsType.annuel.formula',
            {
              montant: fCurrency(parameters.montant),
              indiceAnneeLiquidation: fDecimalNumber(
                indices.indiceLiquidation,
                2,
              ),
              indiceAnneeMontant: fDecimalNumber(indices.indiceMontant, 2),
              anneeLiquidation,
              anneeMontant,
            },
          ) || ''
        );
      } else if (
        indices &&
        parameters.coefficientsType === 'mensuel' &&
        (indices.indiceLiquidation || indices.indiceLiquidation === 0) &&
        (indices.indiceMontant || indices.indiceMontant === 0) &&
        anneeLiquidation &&
        anneeMontant
      ) {
        const dateLiquidation = parseDate(parameters.anneeOrDateLiquidation);
        const dateMontant = parseDate(parameters.anneeOrDateMontant);
        if (!dateLiquidation || !dateMontant) {
          return '';
        }
        return (
          i18next.t(
            'prejudice.revalorisation.coefficientsType.mensuel.formula',
            {
              montant: fCurrency(parameters.montant),
              indiceDateLiquidation: fDecimalNumber(
                indices.indiceLiquidation,
                2,
              ),
              indiceDateMontant: fDecimalNumber(indices.indiceMontant, 2),
              dateLiquidation: fMonthAndYear(dateLiquidation),
              dateMontant: fMonthAndYear(dateMontant),
            },
          ) || ''
        );
      }
      return '';
    }
    case 'smic':
      const smics = getRevalorisationsSmics(parameters);
      if (smics?.smicAnneeLiquidation && smics?.smicAnneeMontant) {
        return (
          i18next.t('prejudice.revalorisation.coefficientsType.smic.formula', {
            montant: fCurrency(parameters.montant),
            smicAnneeLiquidation: fCurrency(smics.smicAnneeLiquidation.value),
            smicAnneeMontant: fCurrency(smics.smicAnneeMontant.value),
            anneeLiquidation: smics.smicAnneeLiquidation.annee,
            anneeMontant: smics.smicAnneeMontant.annee,
          }) || ''
        );
      }
      return '';
  }
};
