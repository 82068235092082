import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import {
  getPrintPrejudiceFileName,
  saveDocument,
} from 'src/helpers/print/saveDocument';
import { Close } from '@mui/icons-material';
import {
  Prejudice,
  PrejudiceFormType,
  PrejudiceFormValues,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Bareme } from 'src/types/bareme.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { useTranslation } from 'react-i18next';
import { makeSinglePrejudicePrint } from 'src/helpers/print/makeSinglePrejudicePrint';
import { PrintFormat, PrintType } from 'src/types/print.type';
import { makeSinglePrejudiceWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/singlePrejudice.written.print';
import { getAvailablePrintTypesForPrejudiceType } from 'src/constants/print';
interface Props<T extends PrejudiceFormValues> {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudices: Prejudice[];
  prejudiceId: string;
  prejudiceType: PrejudiceType;
  prejudiceFormType: PrejudiceFormType;
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
  open: boolean;
  values: T | undefined;
  onClose: () => void;
}

export const PrintPrejudiceDialog = <T extends PrejudiceFormValues>({
  victime,
  victimesIndirectes,
  procedure,
  prejudices,
  monetaryErosions,
  baremes,
  open,
  prejudiceId,
  prejudiceType,
  prejudiceFormType,
  values,
  onClose,
}: Props<T>) => {
  const { t } = useTranslation();
  const availablePrintTypes = getAvailablePrintTypesForPrejudiceType({
    prejudiceType,
    formData: values,
    victime,
  });

  const [printType, setPrintType] = React.useState<PrintType>('print');

  const onClickPrint = (printFormat: PrintFormat) => {
    if (!procedure || !prejudices || !monetaryErosions || !values) {
      return;
    }
    const document =
      printType === 'print'
        ? makeSinglePrejudicePrint({
            victime,
            procedure,
            monetaryErosions,
            prejudices,
            prejudice: {
              formData: values,
              type: prejudiceType,
              formType: prejudiceFormType,
            },
            baremes,
            victimesIndirectes,
          })
        : makeSinglePrejudiceWrittenPrint({
            victime,
            procedure,
            monetaryErosions,
            prejudices,
            prejudice: {
              _id: prejudiceId,
              formData: values,
              type: prejudiceType,
              formType: prejudiceFormType,
            },
            baremes,
            victimesIndirectes,
          });
    const fileName = getPrintPrejudiceFileName(procedure, victime, {
      type: prejudiceType,
      formType: prejudiceFormType,
    });
    saveDocument(document, fileName, printFormat);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Card sx={{ minWidth: '500px' }}>
        <CardHeader
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">
                {t('print.printPrejudiceDialog.title', {
                  prejudiceType: t(
                    `prejudice.prejudicesTypes.${prejudiceType}.title`,
                  ),
                })}
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          }
        />
        <CardContent>
          {availablePrintTypes.length > 1 ? (
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" paddingRight={2}>
                {t('print.dialog.printType.title')}
              </Typography>
              <ToggleButtonGroup
                value={printType}
                exclusive
                onChange={(_, value) => {
                  if (!value) {
                    return;
                  }
                  setPrintType(value);
                }}
                aria-label="text alignment"
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {availablePrintTypes.map((type, index) => (
                  <ToggleButton key={index} value={type}>
                    {t(`print.dialog.printType.options.${type}`)}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
          ) : null}
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="contained"
            onClick={() => onClickPrint('docx')}
            disabled={!procedure || !prejudices || !monetaryErosions}
          >
            {t('print.dialog.confirm')}
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};
