import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { prejudicesData } from 'src/constants/prejudices';
import { fCurrency } from 'src/helpers/formatNumber';
import { fDate } from 'src/helpers/formatTime';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  Prejudice,
  PrejudiceFormCalendrierValeur,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { getMontantRevalorisePrint } from '../revalorisationPrint';
import { prejudiceCalculsPrint } from '../prejudiceCalculsPrint';
import { prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const calendrierValeursPrint = ({
  formData,
  monetaryErosions,
  partResponsabilite,
  prejudice,
  procedure,
}: {
  formData: PrejudiceFormCalendrierValeur;
  monetaryErosions: MonetaryErosion[];
  partResponsabilite: number;
  prejudice: Pick<Prejudice, 'formData' | 'formType' | 'type'>;
  procedure: Procedure;
}): (Paragraph | Table | TableOfContents)[] => {
  const table = prejudiceFormTable(formData.rows, [
    {
      name: 'numerosPieces',
      headerLabel: i18next.t('numeroPiece.form.columnHeader'),
      render: (row) =>
        row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
    },
    {
      name: 'intitule',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.intitule.columnHeader',
      ),
    },
    {
      name: 'date',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.date.columnHeader',
      ),
      render: (row) => (row.date ? fDate(row.date) : ''),
    },
    {
      name: 'montant',
      headerLabel: 'Montant',
      render: (row) =>
        !prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
          .noRevalorisation
          ? getMontantRevalorisePrint({
              montant: row.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation || undefined,
              monetaryErosions,
              anneeOrDateMontant: row.date || undefined,
              montantsDejaRevalorises: row.montantsDejaRevalorises || true,
            })
          : fCurrency(row.montant),
    },
  ]);
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const displayTotalPartResponsabilite =
    prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
      .displayTotalPartResponsabilite;
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...(table ? table : []),
    ...prejudiceCalculsPrint(prejudice),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: displayTotalPartResponsabilite
          ? i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.total.print.partResponsabiliteValue',
              {
                partResponsabilite: fPartResponsabilite(
                  partResponsabilite * 100,
                ),
                value: fCurrency(formData.total),
              },
            )
          : i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.total.print.value',
              {
                value: fCurrency(formData.total),
              },
            ),
      }),
    }),
  ];
};
