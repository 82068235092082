import React from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import { LayoutTable, NormalTable, SpanNoWrap } from '../../../styled';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { useTranslation } from 'react-i18next';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { fCurrency } from '../../../../helpers/formatNumber';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import {
  NumeroPieceValuesRow,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  unites,
} from '../../../../types/prejudice.type';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { getDefaultRevenuEspereRow } from '../../prejudiceFormTypes/FormPerteGainsProfessionnelsActuelSaisieDirecte';
import { CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte } from 'src/constants/calculs/calculsFormPerteGainsProfessionnelsActuelSaisieDirecte';
import { TooltipTableHeader } from '../../TooltipTableHeader';

interface Props {
  control: Control<
    | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
    | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
  >;
  isError?: boolean;
  allNumerosPieces: NumeroPieceValuesRow[];
}

export const TableRevenuEspere: React.FC<Props> = ({
  control,
  isError = false,
  allNumerosPieces,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    name: 'revenuEspere.rows',
    control,
  });
  const onAppendRow = () => {
    append(getDefaultRevenuEspereRow());
  };
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell align="center" sx={{ minWidth: '204px' }}>
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.rows.revenuEspere.columnHeader',
                )}
              </TableCell>
              <TableCell align="center" sx={{ minWidth: '204px' }}>
                <TooltipTableHeader
                  tooltipTitle={t(
                    'prejudice.perteDeChance.fields.coefficientPerteDeChance.headerTooltip',
                  )}
                  headerLabel={t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.rows.coefficientPerteDeChance.columnHeader',
                  )}
                />
              </TableCell>
              <TableCell align="center" sx={{ minWidth: '204px' }}>
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.rows.perteDeChance.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell align="center">
                  <FormNumeroPieceDialog
                    control={control}
                    name={`revenuEspere.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <TextFieldForm
                      control={control}
                      name={`revenuEspere.rows.${index}.revenuEspere`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: { scale: 2 },
                          suffix: '€',
                        } as IMaskNumberProps,
                      }}
                    />
                    <SelectFieldForm
                      control={control}
                      name={`revenuEspere.rows.${index}.unite`}
                      options={unites.map((unite) => ({
                        label: t(`prejudice.fields.unite.optionsPar.${unite}`),
                        value: unite,
                      }))}
                      sx={{ minWidth: '115px' }}
                    />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <TextFieldForm
                    control={control}
                    name={`revenuEspere.rows.${index}.coefficientPerteDeChance`}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, min: 0, max: 100 },
                        suffix: '%',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <ComputedReadFieldForm
                    control={control}
                    name={`revenuEspere.rows.${index}.perteDeChance`}
                    watchFields={[
                      `revenuEspere.rows.${index}.revenuEspere`,
                      `revenuEspere.rows.${index}.unite`,
                      `revenuEspere.rows.${index}.coefficientPerteDeChance`,
                    ]}
                    compute={([
                      revenuEspere,
                      unite,
                      coefficientPerteDeChance,
                    ]) => {
                      const perteDeChance =
                        CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte.getPerteDeChance(
                          {
                            revenuEspere,
                            unite,
                            coefficientPerteDeChance,
                          },
                        );
                      return perteDeChance;
                    }}
                    render={(value) => (
                      <SpanNoWrap>{fCurrency(value as number)}</SpanNoWrap>
                    )}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label={`${t('forms.table.deleteRow', {
                      index: index + 1,
                    })}`}
                    onClick={() => remove(index)}
                  >
                    <DeleteRounded />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={10}></TableCell>
            </TableRow>
          </TableFooter>
        </NormalTable>
      </TableContainer>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <Button sx={{ alignSelf: 'center' }} onClick={onAppendRow}>
          {t('forms.table.addRow')}
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <CalculsBox>
          <LayoutTable>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  {t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.title',
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <ComputedPropsForm
                control={control}
                watchFields={['revenuEspere.total']}
                compute={([totalRevenuEspere]) => {
                  const total =
                    CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte.getTotalPerteDeChanceByUnits(
                      totalRevenuEspere,
                    );
                  return {
                    props: total,
                  };
                }}
                render={(value) => {
                  const { mois, semaine, jour } = value as ReturnType<
                    typeof CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte.getTotalPerteDeChanceByUnits
                  >;
                  return (
                    <>
                      <TableRow>
                        <TableCell align="left">
                          {t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.jour.rowHeader',
                          )}
                        </TableCell>
                        <TableCell align="right">{fCurrency(jour)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          {t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.semaine.rowHeader',
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {fCurrency(semaine)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          {t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.mois.rowHeader',
                          )}
                        </TableCell>
                        <TableCell align="right">{fCurrency(mois)}</TableCell>
                      </TableRow>
                    </>
                  );
                }}
              />
              <TableRow>
                <TableCell align="left">
                  {t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.annee.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <ComputedTextFieldForm
                    control={control}
                    watchFields={['revenuEspere.rows']}
                    compute={([rows]) =>
                      CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte.getTotalPerteDeChance(
                        rows,
                      )
                    }
                    name={'revenuEspere.total'}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </LayoutTable>
        </CalculsBox>
      </Stack>
      {isError && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={'center'}
        >
          <Typography color="error" align={'center'} fontWeight={'bold'}>
            {t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.stepper.error.requiredStep',
            )}
          </Typography>
        </Stack>
      )}
    </>
  );
};
