import React, { useState } from 'react';
import { Control, FieldPath, useFieldArray } from 'react-hook-form';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import { NormalTable } from '../../../styled';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';

import {
  IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NumeroPieceValuesRow,
  PrejudiceFormPerteGainProfessionelsFormType,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceType,
  unites,
} from '../../../../types/prejudice.type';
import { DatePickerForm } from '../../../forms/DatePickerForm';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import { Procedure } from '../../../../types/procedure.type';
import { useTranslation } from 'react-i18next';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { fCurrency } from '../../../../helpers/formatNumber';
import { RenteAccidentDuTravailDialog } from './RenteAccidentDuTravailDialog';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { Victime } from 'src/types/victime.type';
import { getDefaultMinAndMaxDate } from 'src/constants/prejudice/validation';

interface Props {
  control: Control<
    | NewPrejudiceFormPerteGainProfessionnelsActuel
    | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
  >;
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
  isPGPF?: boolean;
  tauxIPP: number | null;
  allNumerosPieces: NumeroPieceValuesRow[];
  dateConsolidation?: Date | undefined;
  dateLiquidation: Date | undefined;
  revenuName:
    | FieldPath<NewPrejudiceFormPerteGainProfessionnelsActuel>
    | FieldPath<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  formType: Exclude<
    PrejudiceFormPerteGainProfessionelsFormType,
    'DECLARATION_FISCALES'
  >;
}

export const TableIndemnitesJournalieresPercuesPendantLaPeriodeDArret: React.FC<
  Props
> = ({
  control,
  procedure,
  victime,
  prejudiceType,
  tauxIPP,
  isPGPF = false,
  allNumerosPieces,
  dateConsolidation,
  dateLiquidation,
  revenuName,
  formType,
}) => {
  const { t } = useTranslation();
  const [
    isRenteAccidentDuTravailModalOpen,
    setIsRenteAccidentDuTravailModalOpen,
  ] = useState(false);

  const { fields, append, remove } = useFieldArray({
    name: 'indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows',
    control: control as any,
  });
  const initialValuesRow: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow =
    {
      dateDebut: isPGPF ? dateConsolidation?.toISOString() ?? null : null,
      dateFin: isPGPF ? dateLiquidation?.toISOString() ?? null : null,
      duree:
        isPGPF && dateConsolidation && dateLiquidation
          ? CalculsFormNewPerteGainsProfessionnelsActuels.getDuree({
              dateDebut: dateConsolidation.toISOString(),
              dateFin: dateLiquidation.toISOString(),
            })
          : 0,
      tiersPayeur: '',
      indemniteJournalieresPercuesNet: 0,
      uniteIndemniteJournalieresPercuesNet: '',
      csgRds: 0,
      uniteCsgRds: '',
      indemnitesPercuesNetParPeriode: 0,
      csgRDSParPeriode: 0,
      numerosPieces: { rows: [] },
      commentaires: '',
    };
  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });

  const onClickRemoveRow = (index: number) => {
    remove(index);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.commentaires.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.tiersPayeur.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {isPGPF
                  ? 'Période entre la consolidation et la liquidation'
                  : t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.dateDebut.periodeColumnHeader',
                    )}
                <Stack direction="row" justifyContent="space-around">
                  <div>
                    {t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.dateDebut.columnHeader',
                    )}
                  </div>
                  <div>
                    {t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.dateFin.columnHeader',
                    )}
                  </div>
                </Stack>
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.duree.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.indemniteJournalieresPercuesNet.columnHeader',
                )}
              </TableCell>
              {!isPGPF && (
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.csgRds.columnHeader',
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.indemnitesPercuesNetParPeriode.columnHeader',
                )}
              </TableCell>
              {!isPGPF && (
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.csgRDSParPeriode.columnHeader',
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map(({ id }, index) => (
              <TableRow key={id}>
                <TableCell align="center">
                  <FormNumeroPieceDialog
                    control={control}
                    name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextFieldForm
                    control={control}
                    name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.commentaires`}
                  />
                </TableCell>
                <TableCell align="center">
                  <SelectFieldForm
                    control={control}
                    name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.tiersPayeur`}
                    options={procedure.tiersPayeurs.map((tiersPayeur) => ({
                      label: tiersPayeur,
                      value: tiersPayeur,
                    }))}
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack direction="row" justifyContent="space-around">
                    <DatePickerForm
                      control={control}
                      name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.dateDebut`}
                      label={t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.dateDebut.label',
                      )}
                      minDate={minDate}
                      maxDate={maxDate}
                      TextFieldProps={{
                        InputLabelProps: { shrink: true },
                        sx: {
                          minWidth: 155,
                          width: 155,
                        },
                      }}
                    />
                    <DatePickerForm
                      control={control}
                      name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.dateFin`}
                      label={t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.dateFin.label',
                      )}
                      minDate={minDate}
                      maxDate={maxDate}
                      TextFieldProps={{
                        InputLabelProps: { shrink: true },
                        sx: {
                          minWidth: 155,
                          width: 155,
                        },
                      }}
                    />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <ComputedTextFieldForm
                    name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.duree`}
                    watchFields={[
                      `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.dateDebut`,
                      `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.dateFin`,
                    ]}
                    control={control}
                    compute={([dateDebut, dateFin]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getDuree({
                        dateDebut,
                        dateFin,
                      })
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <TextFieldForm
                      control={control}
                      name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.indemniteJournalieresPercuesNet`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          suffix: '€',
                          numberMask: { scale: 2, signed: true },
                        } as IMaskNumberProps,
                      }}
                    />
                    <SelectFieldForm
                      control={control}
                      name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.uniteIndemniteJournalieresPercuesNet`}
                      style={{
                        minWidth: 130,
                        width: 130,
                      }}
                      options={unites.map((unite) => ({
                        label: t(`prejudice.fields.unite.optionsPar.${unite}`),
                        value: unite,
                      }))}
                    />
                  </Stack>
                </TableCell>
                {!isPGPF && (
                  <TableCell align="center">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <TextFieldForm
                        control={control}
                        name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.csgRds`}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            suffix: '€',
                            nullable: true,
                            numberMask: {
                              scale: 2,
                              signed: true,
                            },
                          } as IMaskNumberProps,
                        }}
                      />
                      <SelectFieldForm
                        control={control}
                        name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.uniteCsgRds`}
                        style={{
                          minWidth: 130,
                          width: 130,
                        }}
                        options={unites.map((unite) => ({
                          label: t(
                            `prejudice.fields.unite.optionsPar.${unite}`,
                          ),
                          value: unite,
                        }))}
                      />
                    </Stack>
                  </TableCell>
                )}
                <TableCell align="center">
                  <ComputedTextFieldForm
                    name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.indemnitesPercuesNetParPeriode`}
                    watchFields={[
                      `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.uniteIndemniteJournalieresPercuesNet`,
                      `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.duree`,
                      `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.indemniteJournalieresPercuesNet`,
                    ]}
                    control={control}
                    compute={([
                      uniteIndemniteJournalieresPercuesNet,
                      duree,
                      indemniteJournalieresPercuesNet,
                    ]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesPercuesNetParPeriode(
                        {
                          uniteIndemniteJournalieresPercuesNet,
                          duree,
                          indemniteJournalieresPercuesNet,
                        },
                      ) || 0
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        suffix: '€',
                        numberMask: { scale: 2, signed: true },
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                {!isPGPF && (
                  <TableCell align="center">
                    <ComputedTextFieldForm
                      name={`indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.csgRDSParPeriode`}
                      watchFields={[
                        `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.uniteCsgRds`,
                        `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.duree`,
                        `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.csgRds`,
                      ]}
                      control={control}
                      compute={([uniteCsgRds, duree, csgRds]) =>
                        CalculsFormNewPerteGainsProfessionnelsActuels.getCsgRDSParPeriode(
                          { uniteCsgRds, duree, csgRds },
                        ) || 0
                      }
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          suffix: '€',
                          nullable: true,
                          numberMask: { scale: 2, signed: true },
                        } as IMaskNumberProps,
                      }}
                      editedFieldsName="editedFields"
                    />
                  </TableCell>
                )}
                <TableCell align="center">
                  <IconButton
                    aria-label={
                      t('forms.table.deleteRow', {
                        index: index + 1,
                      }) || ''
                    }
                    onClick={() => onClickRemoveRow(index)}
                  >
                    <DeleteRounded />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5 + (isPGPF ? 0 : 1)} />
              <ComputedPropsForm
                control={control}
                watchFields={[
                  'indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows',
                ]}
                compute={([rows]: [
                  IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow[],
                ]) => ({
                  props:
                    CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesJournalieresPercuesNetTotal(
                      {
                        rows,
                      },
                    ),
                })}
                render={(props) => {
                  const { indemnitésJournalieresPercuesNet, csgRdsTotal } =
                    props as ReturnType<
                      typeof CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesJournalieresPercuesNetTotal
                    >;
                  return (
                    <>
                      <TableCell align="right">
                        <ComputedReadFieldForm
                          control={control}
                          watchFields={[]}
                          customValues={[indemnitésJournalieresPercuesNet]}
                          name={
                            'indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet'
                          }
                          compute={(_, customValues) =>
                            customValues?.[0] || null
                          }
                          render={(value) => (
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                              }}
                            >
                              {fCurrency(value || 0)}
                            </Typography>
                          )}
                        />
                      </TableCell>
                      {!isPGPF && (
                        <TableCell align="right">
                          <ComputedReadFieldForm
                            control={control}
                            watchFields={[]}
                            customValues={[csgRdsTotal]}
                            name={
                              'indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal'
                            }
                            compute={(_, customValues) =>
                              customValues?.[0] || null
                            }
                            render={(value) => (
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                }}
                              >
                                {fCurrency(value || 0)}
                              </Typography>
                            )}
                          />
                        </TableCell>
                      )}
                    </>
                  );
                }}
              />
            </TableRow>
          </TableFooter>
        </NormalTable>
      </TableContainer>
      <Button
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          append(initialValuesRow);
        }}
        disabled={procedure.tiersPayeurs.length === 0}
      >
        {t('forms.table.addRow')}
      </Button>
      {isPGPF && (
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.light,
            borderRadius: theme.shape.borderRadius / 8,
            padding: theme.spacing(1),
          })}
          maxWidth={400}
        >
          <Typography textAlign="center" variant="subtitle1">
            {t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.modalButtonTitle',
            )}
          </Typography>
          {isPGPF && (
            <Button
              onClick={() => {
                setIsRenteAccidentDuTravailModalOpen(true);
              }}
              sx={{ textTransform: 'none' }}
            >
              {t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.modalButtonText',
              )}
            </Button>
          )}
        </Stack>
      )}
      {isPGPF && (
        <ComputedPropsForm
          control={control}
          watchFields={[revenuName]}
          compute={([totalRevenuDeReference]) => ({
            props: {
              totalRevenuDeReference,
            },
          })}
          render={({ totalRevenuDeReference }) => (
            <RenteAccidentDuTravailDialog
              open={isRenteAccidentDuTravailModalOpen}
              tauxIPP={tauxIPP}
              salaireAnnuelDeReference={(totalRevenuDeReference || 0) as number}
              onClose={() => setIsRenteAccidentDuTravailModalOpen(false)}
              formType={formType}
            />
          )}
        />
      )}
    </>
  );
};
