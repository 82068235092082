import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  RevenuProche,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { getQuotientRevalorisationPrint } from '../revalorisationPrint';
const getRevenuProcheTable = (
  revenusProches: (RevenuProche & {
    label: string;
  })[],
  monetaryErosions: MonetaryErosion[],
  dateLiquidation: Date | undefined,
) =>
  simpleHorizontalTablePrint(
    [
      '',
      i18next.t('numeroPiece.form.columnHeader'),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.montant.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.annee.columnHeader',
      ),
      i18next.t(
        'prejudice.revalorisation.coefficientsType.smic.print.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.montant.print.revaloriseColumnHeader',
      ),
    ],
    revenusProches.map((revenusProche) => ({
      rowLabel: revenusProche.label,
      columns: [
        revenusProche.numerosPieces
          ? displayNumeroPiece(revenusProche.numerosPieces)
          : '',
        revenusProche.montant || revenusProche.montant === 0
          ? fCurrency(revenusProche.montant)
          : '',
        revenusProche.annee || revenusProche.annee === 0
          ? revenusProche.annee.toString()
          : '',
        revenusProche.dejaRevalorise
          ? ''
          : getQuotientRevalorisationPrint({
              monetaryErosions,
              anneeOrDateLiquidation: dateLiquidation,
              anneeOrDateMontant: revenusProche.annee ?? undefined,
              coefficientsType: 'smic',
            }),
        revenusProche.dejaRevalorise
          ? ''
          : fCurrency(revenusProche.revaloriseMontant),
      ],
    })),
  );
export const perteRevenusProcheVictimeNonDecedeePrint = ({
  formData,
  procedure,
  monetaryErosions,
  dateLiquidation,
}: {
  formData: PrejudiceFormPerteRevenusProcheVictimeNonDecedee;
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation: Date | undefined;
}): (Paragraph | Table | TableOfContents)[] => {
  const partResponsabilite =
    formData.partResponsabilite || formData.partResponsabilite === 0
      ? formData.partResponsabilite
      : procedure.partResponsabilite;
  const table = getRevenuProcheTable(
    [
      {
        ...formData.perteRevenuPersonnel,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.perteRevenuPersonnel.label',
        ),
      },
      {
        ...formData.montantATPADeduire,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.montantATPADeduire.label',
        ),
      },
      {
        ...formData.perteDroitRetraite,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.perteDroitRetraite.label',
        ),
      },
      {
        ...formData.surcroitActivite,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.surcroitActivite.label',
        ),
      },
      {
        ...formData.perteChanceAssistanceConjoint,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.perteChanceAssistanceConjoint.label',
        ),
      },
    ],
    monetaryErosions,
    dateLiquidation,
  );

  const notesPrint = prejudiceNotesPrint(formData.notes);

  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    table,
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.total.print.value',
          {
            value: fCurrency(formData.total),
          },
        ),
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.partResponsableTotal.print.value',
          {
            value: fCurrency(formData.total * partResponsabilite),
          },
        ),
        break: 1,
      }),
    }),
  ];
};
