import { Paragraph, Table, TableOfContents } from 'docx';
import { PrejudiceFormCalendrierAssistanceCapitalisation } from 'src/types/prejudice.type';
import { prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { Procedure } from 'src/types/procedure.type';
import { CalculsFormCalendrierAssistance } from 'src/constants/calculs';
import { Victime } from 'src/types/victime.type';
import { Bareme } from 'src/types/bareme.type';
import {
  calendrierAssistanceRecapitulatif,
  calendrierAssistanceTableRows,
} from './calendrierAssistancePrint';
import { getEmptyLine, getParagraph } from '../../docxFunctions';
import { totalCapitalisationPrint } from '../totalCapitalisationPrint';
import { debitSoldeAEchoirPrint } from '../debitSoldeAEchoirPrint';
import i18next from 'i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { rentesPrint } from '../rentesPrint';
import { fCurrency } from 'src/helpers/formatNumber';
import { getMontantRevalorisePrint } from '../revalorisationPrint';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';

export const calendrierAssistanceCapitalisationPrint = ({
  victime,
  formData,
  tiersPayeurs,
  procedure,
  monetaryErosions,
  baremes,
}: {
  victime: Victime;
  formData: PrejudiceFormCalendrierAssistanceCapitalisation;
  tiersPayeurs: string[];
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
}): (Paragraph | Table | TableOfContents)[] => {
  const rowsPrint = calendrierAssistanceTableRows({
    dateLiquidation: procedure.dateLiquidation,
    monetaryErosions,
    tiersPayeurs,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  }).filter((row) => row.name !== 'joursHospitalisationADeduire');
  const lastRow = rowsPrint[rowsPrint.length - 1];
  const table = prejudiceFormTable(formData.rows, [
    ...rowsPrint
      .slice(0, rowsPrint.length - 1)
      .filter(
        (row) =>
          ![
            'dateJustificatif',
            'montantsDejaRevalorises',
            'montantTotal',
            'priseEnChargeTiersPayeurs',
            'deductionFiscale',
            'resteACharge',
          ].includes(row.name),
      ),
    {
      name: 'annualiseMontantTotal',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.annualiseMontantTotal.columnHeader',
      ),
      render: (row) =>
        row.annualiseMontantTotal || row.annualiseMontantTotal === 0
          ? fCurrency(row.annualiseMontantTotal)
          : '',
    },
    {
      name: 'annualiseMontantTotal',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.annualiseMontantTotal.print.revalorise.columnHeader',
      ),
      render: (row) =>
        row.montantsDejaRevalorises
          ? ''
          : getMontantRevalorisePrint({
              montant: row.resteACharge,
              montantsDejaRevalorises: row.montantsDejaRevalorises,
              anneeOrDateMontant: row.dateJustificatif || undefined,
              monetaryErosions,
              anneeOrDateLiquidation: procedure.dateLiquidation || undefined,
              displayStar: false,
              coefficientsType: formData.revalorisationCoefficientsType,
            }),
    },
    ...rowsPrint.filter((row) =>
      [
        'dateJustificatif',
        'montantsDejaRevalorises',
        'montantTotal',
        'priseEnChargeTiersPayeurs',
        'deductionFiscale',
        'autresDeductions',
        'resteACharge',
      ].includes(row.name),
    ),
    ...(lastRow ? [lastRow] : []),
  ]);
  const indemniteGlobaleARepartir =
    CalculsFormCalendrierAssistance.getAssistanceIndemnitesRepartie({
      ...formData,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      monetaryErosions,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
  const debitSoldeTable = debitSoldeAEchoirPrint({
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
  });
  const victimeTotalCapitalise =
    (formData.victimeCoefficientCapitalisation || 0) *
    formData.victimeSommeACapitaliser;
  const totaux = CalculsFormCalendrierAssistance.totaux({
    rows: formData.rows,
    partResponsabilite: procedure.partResponsabilite,
    tiersPayeurs,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    monetaryErosions,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });
  const bareme = baremes.find(
    (bareme) => bareme._id === formData.baremeCapitalisation,
  );
  const totalCapitalisation = totalCapitalisationPrint({
    title: i18next.t('prejudice.capitalisation.print.title'),
    victime,
    procedure,
    titleVictime: i18next.t(
      'prejudice.capitalisation.form.victime.print.title',
    ),
    titleTiersPayeurs: i18next.t(
      'prejudice.capitalisation.form.tiersPayeurs.print.title',
    ),
    victimeSommeACapitaliser: {
      value: formData.victimeSommeACapitaliser,
    },
    victimeCoefficientCapitalisation: {
      value: formData.victimeCoefficientCapitalisation,
    },
    bareme: bareme && {
      value: bareme,
    },
    tiersPayeursTotalCapitalise: {
      value: formData.tiersPayeursTotalCapitalise,
    },
    victimeTotalCapitalise: {
      value: victimeTotalCapitalise,
    },
    ageDernierArrerage: {
      value: formData.ageDernierArrerage,
    },
    isLastArrerageViager: {
      value: Boolean(formData.isLastArrerageViager),
    },
    tiersPayeurs: formData.capitalisationTiersPayeurs.parTiersPayeur,
    allTiersPayeurs: procedure.tiersPayeurs,
  });
  const rentes =
    formData.rentes?.montant || formData.rentes?.montant === 0
      ? rentesPrint({ montant: formData.rentes.montant })
      : [];
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
  });
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...(table ? table : []),
    getParagraph(i18next.t('prejudice.revalorisation.print.footnote') || ''),
    getEmptyLine(),
    ...calendrierAssistanceRecapitulatif({
      totaux,
      tiersPayeurs,
      isCapitalisation: true,
    }),
    getEmptyLine(),
    ...(!formData.isRentesOption && !shouldNotDisplayCapitalisation
      ? [...totalCapitalisation, getEmptyLine()]
      : []),
    ...debitSoldeTable,
    ...(formData.isRentesOption === true && !shouldNotDisplayCapitalisation
      ? [getEmptyLine(), ...rentes]
      : []),
  ];
};
