import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import i18n from 'i18next';
import {
  CalculsFormCalendrierAssistance,
  CalculsGlobal,
} from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { fDate } from 'src/helpers/formatTime';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceRow,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';
import { debitSoldeEchusPrint } from '../debitSoldeEchusPrint';
import {
  getMontantRevalorisePrint,
  getQuotientRevalorisationPrint,
} from '../revalorisationPrint';
import { getPeriodePrint } from '../periodePrint';
import { FormTableRow, prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';

export const calendrierAssistanceRecapitulatif = ({
  tiersPayeurs,
  totaux,
  isCapitalisation = false,
}: {
  tiersPayeurs: string[];
  totaux: ReturnType<typeof CalculsFormCalendrierAssistance.totaux>;
  isCapitalisation?: boolean;
}) => [
  getParagraph({
    children: getTextRun({
      text: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.total.print.title',
      ),
      bold: true,
    }),
  }),
  getEmptyLine(),
  simpleHorizontalTablePrint(
    [
      '',
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.montantTotal.columnHeader',
      ) || '',
      ...tiersPayeurs.map((tiersPayeur) => tiersPayeur),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.resteACharge.columnHeader',
      ) || '',
    ],
    [
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.total.totalRow.rowHeader',
        ),
        columns: [
          fCurrency(totaux.montantTotal.total.value),
          ...tiersPayeurs.map((tiersPayeur) => {
            const priseEnCharge = totaux.priseEnChargeTiersPayeurs[tiersPayeur];
            if (priseEnCharge) {
              return fCurrency(priseEnCharge.total.value);
            }
            return '';
          }),
          fCurrency(totaux.resteACharge.total.value),
          ...(isCapitalisation
            ? [fCurrency(totaux.montantTotalAnnualise.value)]
            : []),
        ],
      },
    ],
  ),
];
export const calendrierAssistanceTableRows = ({
  dateLiquidation,
  monetaryErosions,
  tiersPayeurs,
  revalorisationCoefficientsType,
}: {
  dateLiquidation?: Date | string | null;
  monetaryErosions: MonetaryErosion[];
  tiersPayeurs: string[];
} & Pick<
  PrejudiceFormCalendrierAssistance,
  'revalorisationCoefficientsType'
>): FormTableRow<
  PrejudiceFormCalendrierAssistanceRow,
  keyof PrejudiceFormCalendrierAssistanceRow,
  PrejudiceFormCalendrierAssistanceRow[keyof PrejudiceFormCalendrierAssistanceRow]
>[] => [
  {
    name: 'numerosPieces',
    headerLabel: i18next.t('numeroPiece.form.columnHeader'),
    render: (row) =>
      row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
  },
  {
    name: 'dateDebut',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.dateDebut.periodeColumnHeader',
    ),

    render: (row) =>
      getPeriodePrint({
        dateDebut: row.dateDebut,
        dateFin: row.dateFin,
        duree: row.joursPeriode,
      }),
  },
  {
    name: 'joursHospitalisationADeduire',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursHospitalisationADeduire.columnHeader',
    ),
    render: (row) =>
      row.deduireJoursHospitalisation
        ? row.joursHospitalisationADeduire.toString()
        : '',
  },
  {
    name: 'joursConges',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursConges.columnHeader',
    ),
  },
  {
    name: 'totalJours',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.totalJours.print.columnHeader',
    ),
  },
  {
    name: 'typeAssistance',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.typeAssistance.columnHeader',
    ),
    render: (row) =>
      i18n.t(
        `prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.typeAssistance.options.${row.typeAssistance}`,
        row.typeAssistance || '',
      ),
  },
  {
    name: 'forfaitHoraire',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.forfaitHoraire.columnHeader',
    ),
    render: (row) => fCurrency(row.forfaitHoraire),
  },
  {
    name: 'nombreHeureParUniteChoisie',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.nombreHeureParUniteChoisie.columnHeader',
    ),
    render: (row) =>
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.nombreHeureParUniteChoisie.print.value',
        {
          nombreHeureParUniteChoisie: row.nombreHeureParUniteChoisie,
          uniteNombreHeure: row.uniteNombreHeure,
        },
      ),
  },
  {
    name: 'dateJustificatif',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.dateJustificatif.print.columnHeader',
    ),
    render: (row) => (row.dateJustificatif ? fDate(row.dateJustificatif) : ''),
  },
  {
    name: 'montantTotal',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.montantTotal.columnHeader',
    ),
    render: (row) => fCurrency(row.montantTotal),
  },
  {
    name: 'priseEnChargeTiersPayeurs',
    children: tiersPayeurs.map((tiersPayeur) => ({
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.priseEnChargeTiersPayeurs.columnHeader',
        { tiersPayeur },
      ),
      render: (row) =>
        fCurrency(
          row.priseEnChargeTiersPayeurs?.find(
            (priseEnChargeTiersPayeur) =>
              priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
          )?.montant || 0,
        ),
    })),
  },
  {
    name: 'deductionFiscale',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.deductionFiscale.columnHeader',
    ),
    render: (row) => fCurrency(row.deductionFiscale),
  },
  {
    name: 'autresDeductions',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.autresDeductions.columnHeader',
    ),
    render: (row) => fCurrency(row.autresDeductions),
  },
  {
    name: 'resteACharge',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.resteACharge.columnHeader',
    ),
    render: (row) => fCurrency(row.resteACharge),
  },

  {
    name: 'resteACharge',
    headerLabel: i18next.t('prejudice.revalorisation.quotient.columnHeader'),
    render: (row) =>
      row.montantsDejaRevalorises
        ? ''
        : getQuotientRevalorisationPrint({
            anneeOrDateLiquidation: dateLiquidation || undefined,
            anneeOrDateMontant: row.dateJustificatif || undefined,
            monetaryErosions,
            coefficientsType: revalorisationCoefficientsType,
          }),
  },
  {
    name: 'resteACharge',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.resteACharge.print.revalorise.columnHeader',
    ),
    render: (row) =>
      row.montantsDejaRevalorises
        ? ''
        : getMontantRevalorisePrint({
            montant: row.resteACharge,
            montantsDejaRevalorises: row.montantsDejaRevalorises,
            anneeOrDateMontant: row.dateJustificatif || undefined,
            monetaryErosions,
            anneeOrDateLiquidation: dateLiquidation || undefined,
            displayStar: false,
            coefficientsType: revalorisationCoefficientsType,
          }),
  },
  {
    name: 'commentaires',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.commentaires.columnHeader',
    ),
  },
];
export const calendrierAssistancePrint = ({
  formData,
  tiersPayeurs,
  procedure,
  monetaryErosions,
}: {
  formData: PrejudiceFormCalendrierAssistance;
  tiersPayeurs: string[];
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
}): (Paragraph | Table | TableOfContents)[] => {
  const table = prejudiceFormTable(
    formData.rows,
    calendrierAssistanceTableRows({
      dateLiquidation: procedure.dateLiquidation,
      monetaryErosions,
      tiersPayeurs,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    }),
  );
  const totaux = CalculsFormCalendrierAssistance.totaux({
    rows: formData.rows,
    partResponsabilite: procedure.partResponsabilite,
    tiersPayeurs,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    monetaryErosions,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });
  const indemniteGlobaleARepartir = CalculsGlobal.getIndemnitesRepartie(
    totaux.resteACharge.total.value,
    Object.entries(totaux.priseEnChargeTiersPayeurs).reduce(
      (
        accumulator,
        [
          tiersPayeur,
          {
            total: { value },
          },
        ],
      ) => ({
        ...accumulator,
        [tiersPayeur]: value,
      }),
      {},
    ),
    procedure.partResponsabilite,
  );
  const debitSoldeTable = debitSoldeEchusPrint({
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
  });
  const notesPrint = prejudiceNotesPrint(formData.notes);
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...(table ? table : []),
    getParagraph(i18next.t('prejudice.revalorisation.print.footnote') || ''),
    getEmptyLine(),
    ...calendrierAssistanceRecapitulatif({
      totaux,
      tiersPayeurs,
    }),
    getEmptyLine(),
    ...debitSoldeTable,
    getEmptyLine(),
  ];
};
