import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { sum } from 'lodash';
import { prejudicesData } from 'src/constants/prejudices';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import {
  Prejudice,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { getPeriodePrint } from '../periodePrint';
import { prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const deficitFonctionnelTemporaireTotalPrint = (
  procedure: Procedure,
  prejudice: Pick<Prejudice, 'type' | 'formType' | 'formData'>,
  formData: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
): (Paragraph | Table | TableOfContents)[] => {
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const table = prejudiceFormTable(formData.rows, [
    {
      name: 'numerosPieces',
      headerLabel: i18next.t('numeroPiece.form.columnHeader'),
      render: (row) =>
        row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
    },
    {
      name: 'dateDebut',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.dateDebut.print.periodeColumnHeader',
      ),
      render: (row) =>
        getPeriodePrint({
          dateDebut: row.dateDebut,
          dateFin: row.dateFin,
        }),
    },
    {
      name: 'totalJours',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.totalJours.print.columnHeader',
      ),
    },
    {
      name: 'motifHospitalisation',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.motifHospitalisation.columnHeader',
      ),
    },
    {
      name: 'forfaitJour',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.forfaitJour.print.columnHeader',
      ),
      render: (row) => fCurrency(row.forfaitJour),
    },
    {
      name: 'montantTotal',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.montantTotal.columnHeader',
      ),
      render: (row) => fCurrency(row.montantTotal),
    },
  ]);
  const displayTotalPartResponsabilite =
    prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
      .displayTotalPartResponsabilite;
  const total = sum(formData.rows.map((row) => row.montantTotal));
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...table,
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: displayTotalPartResponsabilite
          ? i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.total.print.rowPartResponsabilite',
              {
                value: fCurrency(total * procedure.partResponsabilite),
                partResponsabilite: fPartResponsabilite(
                  procedure.partResponsabilite * 100,
                ),
              },
            )
          : i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.total.print.row',
              {
                total: fCurrency(total),
              },
            ),
      }),
    }),
  ];
};
