import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import {
  IndemniteRepartieEchusIndirecte,
  IndemniteRepartieTiersPayeurs,
} from './type';
import {
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormPerteRevenusProcheEnfantRow,
} from 'src/types/prejudice.type';
import { CalculsGlobal } from './calculsGlobal';
import { addYears, min } from 'date-fns';
import { max } from 'lodash';

export abstract class CalculPerteRevenusProche {
  static getTotal(conjointTotal?: number, enfantsTotal?: number): number {
    return (conjointTotal || 0) + (enfantsTotal || 0);
  }

  static getTotalCapitauxDeces(
    conjointTotalCapitauxDeces?: number,
    enfantsTotalCapitauxDeces?: number,
  ): number {
    return (conjointTotalCapitauxDeces || 0) + (enfantsTotalCapitauxDeces || 0);
  }

  static getTotalApresCapitauxDeces(
    conjointTotalApresCapitauxDeces?: number,
    enfantsTotalApresCapitauxDeces?: number,
  ): number {
    return (
      (conjointTotalApresCapitauxDeces || 0) +
      (enfantsTotalApresCapitauxDeces || 0)
    );
  }
  static getIndemniteRepartie({
    tiersPayeurs,
    victimesIndirectes,
    perteRevenusProche: { conjoint, enfants },
    partResponsabilite,
  }: {
    tiersPayeurs: string[];
    victimesIndirectes: VictimeIndirecte[];
    perteRevenusProche: Pick<
      PrejudiceFormPerteRevenusProche,
      'conjoint' | 'enfants'
    >;
    partResponsabilite: number;
  }): IndemniteRepartieEchusIndirecte {
    const indemniteGlobaleARepartir = this.getTotal(
      conjoint?.total,
      enfants?.total,
    );
    const totalApresCapitauxDeces = this.getTotalApresCapitauxDeces(
      conjoint?.totalApresCapitauxDeces,
      enfants?.totalApresCapitauxDeces,
    );
    const indemniteGlobaleARepartirPartResponsabilite =
      indemniteGlobaleARepartir * partResponsabilite;
    const totalVictimesIndirectes =
      CalculsGlobal.max([
        CalculsGlobal.min([
          indemniteGlobaleARepartirPartResponsabilite,
          totalApresCapitauxDeces,
        ]) || 0,
        0,
      ]) || 0;

    const enfantsVictimesIndirectes = victimesIndirectes.filter(
      (victimeIndirecte) => victimeIndirecte.lienVictime === 'enfant',
    );
    const conjointVictimeIndirecte = victimesIndirectes.find(
      (victimeIndirecte) => victimeIndirecte.lienVictime === 'conjointConcubin',
    );

    const parVictimeIndirecte: Record<string, number> = {
      ...(conjointVictimeIndirecte
        ? {
            [conjointVictimeIndirecte._id]: max([
              conjoint.totalApresCapitauxDeces,
              0,
            ]),
          }
        : {}),
      ...enfantsVictimesIndirectes.reduce((accumulator, { _id }) => {
        const row = enfants.rows.find(
          ({ victimeIndirecteId }) => victimeIndirecteId === _id,
        );
        return {
          ...accumulator,
          [_id]: max([row?.totalApresCapitauxDeces || 0, 0]) || 0,
        };
      }, {}),
    };

    const totalVictimesIndirectesParVictimeIndirecte = CalculsGlobal.sum(
      Object.values(parVictimeIndirecte),
    );

    const parVictimesIndirectesRatio =
      totalVictimesIndirectes / totalVictimesIndirectesParVictimeIndirecte;

    const parVictimeIndirecteWithRatio = Object.entries(
      parVictimeIndirecte,
    ).reduce(
      (accumulator, [victimeIndirecteId, montant]) => ({
        ...accumulator,
        [victimeIndirecteId]:
          max([montant * parVictimesIndirectesRatio || 0, 0]) || 0,
      }),
      {},
    );
    const totalTiersPayeurs =
      indemniteGlobaleARepartirPartResponsabilite - totalVictimesIndirectes;
    const parTiersPayeur = tiersPayeurs.map((tiersPayeur) => ({
      tiersPayeur,
      montant:
        max([
          conjoint.capitauxDeces.tiersPayeurs?.find(
            (tiersPayeurConjoint) =>
              tiersPayeurConjoint.tiersPayeur === tiersPayeur,
          )?.montant || 0,
          0,
        ]) ||
        0 +
          (CalculsGlobal.sum(
            enfants.rows.map(
              (enfant) =>
                max([
                  enfant.capitauxDeces.tiersPayeurs?.find(
                    (tiersPayeurEnfant) =>
                      tiersPayeurEnfant.tiersPayeur === tiersPayeur,
                  )?.montant || 0,
                  0,
                ]) || 0,
            ),
          ) || 0),
    }));
    const totalTiersPayeursParTiersPayeur = CalculsGlobal.sum(
      parTiersPayeur.map(({ montant }) => montant || 0),
    );
    const parTiersPayeurRatio =
      totalTiersPayeurs / totalTiersPayeursParTiersPayeur;

    const parTiersPayeurWithRatio: IndemniteRepartieTiersPayeurs =
      parTiersPayeur.map(({ tiersPayeur, montant }) => ({
        tiersPayeur,
        montant: max([montant * parTiersPayeurRatio || 0, 0]) || 0,
        montantNonReparti: montant,
      }));
    return {
      indemniteGlobaleARepartir: {
        solde: max([indemniteGlobaleARepartirPartResponsabilite, 0]) || 0,
        beforePartResponsabilite: max([indemniteGlobaleARepartir, 0]) || 0,
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          debit: max([totalTiersPayeurs || 0, 0]) || 0,
          parTiersPayeur: parTiersPayeurWithRatio,
          totalNonReparti: totalTiersPayeursParTiersPayeur || 0,
        },
      },
      indemniteProche: {
        arreragesEchus: {
          debit: max([totalVictimesIndirectes || 0, 0]) || 0,
          solde: 0,
          parVictimeIndirecte: parVictimeIndirecteWithRatio,
        },
      },
    };
  }
  static getPartIndividuelleEnfant({
    partTotale,
    victimesIndirectesEnfantsLength,
  }: Pick<PrejudiceFormPerteRevenusProche['enfants'], 'partTotale'> & {
    victimesIndirectesEnfantsLength: number;
  }): number {
    return Math.round(partTotale / victimesIndirectesEnfantsLength);
  }
  static getPartPerteAnnuelleEnfant({
    partIndividuelleEnfant,
    perteAnnuelleFoyer,
  }: {
    partIndividuelleEnfant: PrejudiceFormPerteRevenusProcheEnfantRow['partIndividuelle'];
    perteAnnuelleFoyer: PrejudiceFormPerteRevenusProche['foyer']['perteAnnuelle'];
  }): number {
    return ((partIndividuelleEnfant || 0) / 100) * perteAnnuelleFoyer;
  }
  static getDureeIndemnisationEnfant({
    dateNaissanceEnfant,
    dateDeces,
    dateLiquidation,
    ageFinSoutienFinancier,
  }: {
    dateNaissanceEnfant: Date;
    dateDeces?: Date;
    dateLiquidation?: Date;
    ageFinSoutienFinancier?: number;
  }): number {
    if (!dateLiquidation || !dateDeces) {
      return 0;
    }
    let dateFin: Date = dateLiquidation;
    if (ageFinSoutienFinancier) {
      const dateFinSoutienFinancier = addYears(
        dateNaissanceEnfant,
        ageFinSoutienFinancier,
      );
      dateFin = min([dateLiquidation, dateFinSoutienFinancier]);
    }
    const years = CalculsGlobal.getYears(dateDeces, dateFin);
    return years;
  }
  static getPrejudiceEntreDecesEtLiquidationEnfant({
    partPerteAnnuelleEnfant,
    dureeIndemnisationEnfant,
  }: {
    partPerteAnnuelleEnfant: number;
    dureeIndemnisationEnfant: number;
  }): number {
    return partPerteAnnuelleEnfant * dureeIndemnisationEnfant;
  }

  static getTotalEnfant({
    prejudiceEntreDecesEtLiquidation,
    montantCapitalise,
  }: Pick<
    PrejudiceFormPerteRevenusProcheEnfantRow,
    'prejudiceEntreDecesEtLiquidation' | 'montantCapitalise'
  >): number {
    return prejudiceEntreDecesEtLiquidation + (montantCapitalise || 0);
  }

  static getPrejudiceViagerFoyerEntreDecesEtLiquidation({
    perteAnnuelleFoyer,
    anneesEntreDecesEtLiquidation,
  }: {
    perteAnnuelleFoyer: PrejudiceFormPerteRevenusProche['foyer']['perteAnnuelle'];
    anneesEntreDecesEtLiquidation: number;
  }) {
    return perteAnnuelleFoyer * anneesEntreDecesEtLiquidation;
  }

  static getPrejudiceViagerFoyer({
    prejudiceViagerEntreDecesEtLiquidation,
    prejudiceViagerApresLiquidation,
  }: Pick<
    PrejudiceFormPerteRevenusProche['foyer'],
    'prejudiceViagerEntreDecesEtLiquidation' | 'prejudiceViagerApresLiquidation'
  >) {
    return (
      (prejudiceViagerEntreDecesEtLiquidation || 0) +
      (prejudiceViagerApresLiquidation || 0)
    );
  }

  static getPrejudiceViagerFoyerApresLiquidation({
    perteAnnuelleFoyer,
    coefficientCapitalisationVictime,
    coefficientCapitalisationConjoint,
  }: {
    perteAnnuelleFoyer: PrejudiceFormPerteRevenusProche['foyer']['perteAnnuelle'];
    coefficientCapitalisationVictime: PrejudiceFormPerteRevenusProche['victime']['coefficientCapitalisation'];
    coefficientCapitalisationConjoint: PrejudiceFormPerteRevenusProche['conjoint']['coefficientCapitalisation'];
  }) {
    return (
      (perteAnnuelleFoyer || 0) *
      CalculsGlobal.min([
        coefficientCapitalisationVictime || 0,
        coefficientCapitalisationConjoint || 0,
      ])
    );
  }

  static isFinSoutienFinancierBeforeLiquidation({
    dateNaissance,
    ageFinSoutienFinancier,
    dateLiquidation,
  }: {
    dateNaissance: Date | string;
    ageFinSoutienFinancier?: number;
    dateLiquidation?: Date | string;
  }): boolean {
    if (!ageFinSoutienFinancier || !dateLiquidation) {
      return false;
    }
    const dateFinSoutienFinancier = addYears(
      new Date(dateNaissance),
      ageFinSoutienFinancier,
    );
    return dateFinSoutienFinancier <= new Date(dateLiquidation);
  }
}
