import React, { useMemo, useState } from 'react';
import {
  getDefaultMinAndMaxDate,
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { dateString } from 'src/helpers/yup';
import {
  PrejudiceFormScolaire,
  PrejudiceFormScolairePerteDeChance,
  PrejudiceFormScolairePerteDeChanceRow,
  PrejudiceType,
} from 'src/types/prejudice.type';
import * as yup from 'yup';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { useTranslation } from 'react-i18next';
import { FieldPath, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { FormNumeroPieceDialog } from '../prejudiceFormComponents/FormNumeroPieceDialog';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { Accordion } from 'src/components/basic/Accordion';
import { NormalTable } from 'src/components/styled';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { CalculsFormScolaire } from 'src/constants/calculs/calculsFormScolaire';
import { DeleteRounded } from '@mui/icons-material';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { TotalDeficit } from '../prejudiceFormComponents/TotalDeficit';
import { DatePickerForm } from 'src/components/forms/DatePickerForm';
import { TooltipTableHeader } from '../TooltipTableHeader';
import { ResourcesButton } from 'src/components/resources/ResourcesButton';
import i18next from 'i18next';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { Victime } from 'src/types/victime.type';
import { Procedure } from 'src/types/procedure.type';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import { intersection } from 'lodash';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';

export const validationFormScolairePerteDeChanceRow = ({
  prejudiceType,
  victime,
  procedure,
}: {
  prejudiceType: PrejudiceType;
  victime: Victime;
  procedure: Procedure;
}): yup.ObjectSchema<PrejudiceFormScolairePerteDeChanceRow> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    prejudiceType,
    victime,
    procedure,
  });
  return yup.object({
    numerosPieces: validationSchemaNumeroPieces,
    libelle: yup.string().required(),
    date: dateString()
      .required()
      .minDate(undefined, minDate.message, minDate.date)
      .maxDate(undefined, maxDate.message, maxDate.date),
    montant: yup.number().required(),
    coefficientPerteDeChance: yup
      .number()
      .required()
      .min(0)
      .max(100)
      .transform((value) => value / 100),
  });
};
export const validationFormScolairePerteDeChance = ({
  prejudiceType,
  victime,
  procedure,
}: {
  prejudiceType: PrejudiceType;
  victime: Victime;
  procedure: Procedure;
}): yup.ObjectSchema<PrejudiceFormScolairePerteDeChance> =>
  yup.object({
    rows: yup
      .array()
      .of(
        validationFormScolairePerteDeChanceRow({
          prejudiceType,
          victime,
          procedure,
        }),
      )
      .defined(),
    total: yup.number().required(),
  });

export const validationFormScolaire = ({
  prejudiceType,
  victime,
  procedure,
}: {
  prejudiceType: PrejudiceType;
  victime: Victime;
  procedure: Procedure;
}): yup.ObjectSchema<PrejudiceFormScolaire> =>
  validationSchemaPrejudiceBase.shape({
    montant: yup.number().required(i18next.t('forms.rules.required')),

    perteDeChance: validationFormScolairePerteDeChance({
      prejudiceType,
      victime,
      procedure,
    }),
    numerosPieces: validationSchemaNumeroPieces,
    total: yup.number().required(i18next.t('forms.rules.required')),
  });

const defaultScolairePerteDeChanceRow: PrejudiceFormScolairePerteDeChanceRow = {
  numerosPieces: { rows: [] },
  libelle: '',
  date: null,
  montant: 0,
  coefficientPerteDeChance: 100,
};

export const getScolaireDefaultValues = ({
  values,
  prejudiceType,
}: {
  values?: PrejudiceFormScolaire;
  prejudiceType: PrejudiceType;
}): PrejudiceFormScolaire => ({
  ...prejudiceBaseDefaultValues({
    prejudiceType,
    values,
  }),
  notes: values?.notes || '',
  montant: values?.montant || 0,
  perteDeChance: {
    rows:
      values?.perteDeChance?.rows.map((row) => ({
        ...defaultScolairePerteDeChanceRow,
        ...row,
        coefficientPerteDeChance: row.coefficientPerteDeChance * 100,
      })) || [],
    total: values?.perteDeChance?.total || 0,
  },
  numerosPieces: values?.numerosPieces || { rows: [] },
  total: values?.total || 0,
});

type Props = PrejudiceFormProps<PrejudiceFormScolaire>;

export const FormScolaire: React.FC<Props> = ({
  values,
  allNumerosPieces,
  displayTotalPartResponsabilite,
  partResponsabilite,
  ...props
}) => {
  const { t } = useTranslation();
  const useFormReturn = useForm<PrejudiceFormScolaire>({
    defaultValues: getScolaireDefaultValues({
      prejudiceType: props.prejudiceType,
      values,
    }),
    resolver: yupResolver(
      validationFormScolaire({
        prejudiceType: props.prejudiceType,
        victime: props.victime,
        procedure: props.procedure,
      }),
    ),
    mode: 'onTouched',
  });
  const {
    control,
    formState: { errors },
  } = useFormReturn;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'perteDeChance.rows',
  });
  const [isPerteDeChanceActive, setIsPerteDeChanceActive] = useState(false);
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    prejudiceType: props.prejudiceType,
    victime: props.victime,
    procedure: props.procedure,
  });
  const isPerteDeChanceError = useMemo(() => {
    const fields: FieldPath<PrejudiceFormScolaire>[] = ['perteDeChance'];
    return (
      intersection(Object.keys(errors), fields).length > 0 &&
      !isPerteDeChanceActive
    );
  }, [errors, isPerteDeChanceActive]);

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormScolaire>
      {...props}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack spacing={2}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={10}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <FormNumeroPieceDialog
            control={control}
            name="numerosPieces"
            displayNumeroPieceText={true}
            allOtherNumeroPieces={allNumerosPieces}
          />
          <TextFieldForm
            control={control}
            name="montant"
            label={t(
              'prejudice.prejudicesFormTypes.SCOLAIRE.fields.montant.label',
            )}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                suffix: '€',
                numberMask: { scale: 2 },
              } as IMaskNumberProps,
            }}
            sx={{ minWidth: '268px' }}
          />
          <Accordion
            title={t(
              'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.accordionTitle',
            )}
            isError={isPerteDeChanceError}
            errorTooltip={
              t(
                'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.errorTooltip',
              ) || ''
            }
            expanded={isPerteDeChanceActive}
            onChange={() => setIsPerteDeChanceActive(!isPerteDeChanceActive)}
          >
            <>
              <TableContainer>
                <NormalTable>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {t('numeroPiece.form.columnHeader')}
                      </TableCell>
                      <TableCell align="center">
                        {t(
                          'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.libelle.columnHeader',
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {t(
                          'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.date.columnHeader',
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {t(
                          'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.montant.columnHeader',
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <TooltipTableHeader
                          tooltipTitle={t(
                            'prejudice.perteDeChance.fields.coefficientPerteDeChance.headerTooltip',
                          )}
                          headerLabel={t(
                            'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.coefficientPerteDeChance.columnHeader',
                          )}
                        />
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((field, index) => (
                      <TableRow key={field.id}>
                        <TableCell align="center">
                          <FormNumeroPieceDialog
                            control={control}
                            name={`perteDeChance.rows.${index}.numerosPieces`}
                            allOtherNumeroPieces={allNumerosPieces}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextFieldForm
                            control={control}
                            name={`perteDeChance.rows.${index}.libelle`}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="center">
                          <DatePickerForm
                            control={control}
                            name={`perteDeChance.rows.${index}.date`}
                            minDate={minDate.date}
                            maxDate={maxDate.date}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextFieldForm
                            control={control}
                            name={`perteDeChance.rows.${index}.montant`}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 2 },
                                suffix: '€',
                              } as IMaskNumberProps,
                            }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextFieldForm
                            control={control}
                            name={`perteDeChance.rows.${index}.coefficientPerteDeChance`}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 2, min: 0, max: 100 },
                                suffix: '%',
                              } as IMaskNumberProps,
                            }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => remove(index)}
                            aria-label={
                              t('forms.table.deleteRow', {
                                index: index + 1,
                              }) || ''
                            }
                            size="small"
                          >
                            <DeleteRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={5} align="right">
                        <ComputedReadFieldForm
                          control={control}
                          name="perteDeChance.total"
                          watchFields={['perteDeChance.rows']}
                          compute={([rows]) =>
                            CalculsFormScolaire.getTotalPerteDeChance(
                              rows.map((row) => ({
                                ...row,
                                coefficientPerteDeChance:
                                  row.coefficientPerteDeChance / 100,
                              })),
                            )
                          }
                          InputProps={{
                            inputComponent: MaskNumber as any,
                            inputProps: {
                              numberMask: { scale: 2 },
                              suffix: '€',
                            } as IMaskNumberProps,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </NormalTable>
              </TableContainer>
              <Stack alignItems="center">
                <Button onClick={() => append(defaultScolairePerteDeChanceRow)}>
                  {t('forms.table.addRow')}
                </Button>
              </Stack>
            </>
          </Accordion>
          <ComputedReadFieldForm
            control={control}
            name="total"
            watchFields={['montant', 'perteDeChance.total']}
            compute={([montant, totalPerteDeChance]) =>
              CalculsFormScolaire.getTotal(montant, totalPerteDeChance)
            }
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: '€',
              } as IMaskNumberProps,
            }}
          />
          {displayTotalPartResponsabilite && (
            <ComputedPropsForm
              control={control}
              watchFields={['total']}
              compute={([total]) => {
                return {
                  props: {
                    totalPartResponsabilite: total * (partResponsabilite || 0),
                    total,
                    partResponsabilite,
                  },
                };
              }}
              render={({
                totalPartResponsabilite,
                total,
                partResponsabilite,
              }) => (
                <Stack alignSelf="end" maxWidth="400px">
                  <TotalDeficit
                    totalPartResponsabilite={totalPartResponsabilite}
                    totalMontantTotal={total}
                    displayPartResponsabilite={displayTotalPartResponsabilite}
                    partResponsabilite={partResponsabilite}
                  />
                </Stack>
              )}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SavePrejudiceButton victime={props.victime} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <ResourcesButton baremeType="Scolaire" isMultipleBaremes />
            </Grid>
          </Grid>
        </Stack>
      )}
    />
  );
};
