import React, { useMemo, useState } from 'react';
import {
  Control,
  FieldPath,
  useFieldArray,
  useFormState,
} from 'react-hook-form';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import * as yup from 'yup';
import { NormalTable } from '../../../styled';
import {
  LIBELLE_PGPF_RELIQUAT,
  ListeProjectionIncidenceProfessionelle,
  ListeProjectionIncidenceProfessionelleRow,
  NumeroPieceValuesRow,
  PerteDeRetraiteValues,
  PrejudiceFormListeProjection,
  PGPFReliquatAndPGPFReliquatActivationStatus,
} from 'src/types/prejudice.type';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { useTranslation } from 'react-i18next';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import {
  validationSchemaCapitalisation,
  validationSchemaNumeroPieces,
} from 'src/constants/prejudice/validation';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { fCurrency } from 'src/helpers/formatNumber';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { CalculsFormListeProjection } from 'src/constants/calculs';
import { PrejudiceTab } from '../../PrejudiceTab';
import { PerteDeRetraite } from './PerteDeRetraite';
import { Procedure } from 'src/types/procedure.type';
import { Bareme } from 'src/types/bareme.type';
import { intersection } from 'lodash';
import { TotalIndemniteGlobaleARepartirAEchoir } from '../TotalIndemniteGlobaleARepartirAEchoir';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { dateString } from 'src/helpers/yup';
import i18next from 'i18next';

export const incidenceProfessionnellePGPFReliquatRow = (
  PGPFReliquat: number | null,
): ListeProjectionIncidenceProfessionelleRow => ({
  libelle: LIBELLE_PGPF_RELIQUAT,
  montant: PGPFReliquat || 0,
  numerosPieces: { rows: [] },
});

interface Props {
  control: Control<PrejudiceFormListeProjection>;
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  partResponsabilite: number;
  procedure: Procedure;
  dateLiquidation: Date | null | undefined;
  baremes: Bareme[];
  dateNaissance: Date | null | undefined;
  dateDeces: Date | null | undefined;
  sexe: 'm' | 'f' | 'u';
  allNumerosPieces: NumeroPieceValuesRow[];
}

const validationSchemaPerteDeRetraite = (
  birthYear: number,
  dateLiquidation: Date | undefined,
): yup.ObjectSchema<PerteDeRetraiteValues> =>
  yup.object({
    retraite: yup.object({
      anneeRetraite: yup.number().nullable().defined().min(birthYear),
      retraiteTheoriqueTotal: yup.number().required(),
      retraitePercue: yup.number().required(),
      perteAnnuelle: yup.number().required(),
    }),
    echus: yup.object({
      nombreAnneesEntreAnneeRetraiteEtAnneeCalcul: yup.number().required(),
      montantAPayerADateCalcul: yup.number().required(),
    }),
    aEchoir: validationSchemaCapitalisation.shape({
      sommeACapitaliser: yup.number().required(),
      dateCapitalisation: dateString()
        .nullable()
        .defined()
        .when([], (_, schema) =>
          dateLiquidation
            ? schema.minDate(
                undefined,
                i18next.t('validation.prejudices.dates.minDateLiquidation'),
                dateLiquidation,
              )
            : schema,
        ),
    }),
  });
export const validationSchemaIncidenceProfessionnelle = (
  birthYear: number,
  dateLiquidation: Date | undefined,
): yup.ObjectSchema<ListeProjectionIncidenceProfessionelle> =>
  yup.object({
    rows: yup
      .array()
      .defined()
      .of(
        yup.object({
          numerosPieces: validationSchemaNumeroPieces,
          libelle: yup.string().required(),
          montant: yup.number().required(),
        }),
      ),
    perteDeRetraite: validationSchemaPerteDeRetraite(
      birthYear,
      dateLiquidation,
    ),
  });
const initialValuesRow: ListeProjectionIncidenceProfessionelleRow = {
  libelle: '',
  montant: 0,
  numerosPieces: { rows: [] },
};

export const getInitialValuesIncidenceProfessionnelle = (
  values: ListeProjectionIncidenceProfessionelle | undefined,
): ListeProjectionIncidenceProfessionelle => ({
  rows:
    values?.rows.map((row) => ({
      ...initialValuesRow,
      ...row,
    })) || [],
  perteDeRetraite: {
    retraite: values?.perteDeRetraite?.retraite || {
      anneeRetraite: null,
      retraiteTheoriqueTotal: 0,
      retraitePercue: 0,
      perteAnnuelle: 0,
    },
    echus: values?.perteDeRetraite?.echus || {
      nombreAnneesEntreAnneeRetraiteEtAnneeCalcul: 0,
      montantAPayerADateCalcul: 0,
    },
    aEchoir: values?.perteDeRetraite?.aEchoir || {
      sommeACapitaliser: 0,
      ageDernierArrerage: null,
      isLastArrerageViager: false,
      coefficient: null,
      montantCapitalise: 0,
      dateCapitalisation: null,
    },
  },
});

export const IncidenceProfessionnelle: React.FC<Props> = ({
  control,
  PGPFReliquat,
  partResponsabilite,
  procedure,
  dateLiquidation,
  baremes,
  dateNaissance,
  dateDeces,
  sexe,
  allNumerosPieces,
}) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prejudiceValues.rows',
  });
  const { errors } = useFormState({ control });
  const [tabIndex, setTabIndex] = useState(0);

  const isFirstTabError = useMemo(() => {
    const firstTabFields: FieldPath<ListeProjectionIncidenceProfessionelle>[] =
      ['rows'];
    return (
      intersection(Object.keys(errors.prejudiceValues || {}), firstTabFields)
        .length > 0
    );
  }, [errors]);
  const isSecondTabError = useMemo(() => {
    const secondTabFields: FieldPath<ListeProjectionIncidenceProfessionelle>[] =
      ['perteDeRetraite'];
    return (
      intersection(Object.keys(errors.prejudiceValues || {}), secondTabFields)
        .length > 0
    );
  }, [errors]);

  return (
    <>
      <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
        <PrejudiceTab
          label={t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.tabs.incidenceProfessionnelle',
          )}
          value={0}
          isError={isFirstTabError}
        />
        <PrejudiceTab
          label={t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.tabs.perteDeRetraite',
          )}
          value={1}
          isError={isSecondTabError}
        />
        <PrejudiceTab
          label={t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.tabs.recapitulatif',
          )}
          value={2}
        />
      </Tabs>

      <Stack
        sx={{ display: tabIndex === 0 ? undefined : 'none' }}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <NormalTable size="medium">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.numerosPieces.columnHeader',
                  )}
                </TableCell>
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.libelle.columnHeader',
                  )}
                </TableCell>
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.montant.columnHeader',
                  )}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => (
                <TableRow key={field.id}>
                  <TableCell>
                    <FormNumeroPieceDialog
                      control={control}
                      name={`prejudiceValues.rows.${index}.numerosPieces`}
                      allOtherNumeroPieces={allNumerosPieces}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextFieldForm
                      control={control}
                      name={`prejudiceValues.rows.${index}.libelle`}
                      sx={{ minWidth: 300 }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextFieldForm
                      control={control}
                      name={`prejudiceValues.rows.${index}.montant`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: { scale: 2, signed: true },
                          suffix: '€',
                        } as IMaskNumberProps,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-label={`${t(
                        'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.deleteRow',
                      )} ${index + 1}`}
                      onClick={() => remove(index)}
                    >
                      <DeleteRounded />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </NormalTable>
        </TableContainer>
        <Button
          sx={{ alignSelf: 'center' }}
          onClick={() => {
            append(initialValuesRow);
          }}
        >
          {t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.addRow',
          )}
        </Button>
      </Stack>
      <Box sx={{ display: tabIndex === 1 ? undefined : 'none' }}>
        <PerteDeRetraite
          control={control}
          procedure={procedure}
          dateLiquidation={dateLiquidation}
          baremes={baremes}
          dateNaissance={dateNaissance}
          dateDeces={dateDeces}
          sexe={sexe}
        />
      </Box>
      <Box sx={{ display: tabIndex === 2 ? undefined : 'none' }}>
        <ComputedPropsForm
          control={control}
          watchFields={[
            'prejudiceValues.rows',
            'prejudiceValues.perteDeRetraite',
          ]}
          compute={([rows, perteDeRetraite]) => ({
            props:
              CalculsFormListeProjection.getIncidenceProfessionnelleIndemnite({
                partResponsabilite,
                PGPFReliquat,
                rows: rows as ListeProjectionIncidenceProfessionelleRow[],
                perteDeRetraite,
              }),
          })}
          render={(values) => {
            const { montantTotal, beforePGPFReliquatTotal } =
              values as ReturnType<
                typeof CalculsFormListeProjection.getIncidenceProfessionnelleIndemnite
              >;
            return (
              <Box sx={{ marginTop: 3, marginBottom: 2 }}>
                <ComputedReadFieldForm
                  name="montantTotal"
                  control={control}
                  watchFields={[]}
                  customValues={[montantTotal]}
                  compute={(_, customValues) => customValues?.[0] || 0}
                />
                {PGPFReliquat.PGPFReliquat &&
                PGPFReliquat.activatePGPFReliquat ? (
                  <CalculsBox>
                    <NormalTable>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell align="center">
                            {t(
                              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.PGPFReliquat.totalTable.fields.incidenceProfessionnelle.columnHeader',
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {t(
                              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.PGPFReliquat.totalTable.fields.tiersPayeurs.columnHeader',
                            )}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" variant="head">
                            {t(
                              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.PGPFReliquat.totalTable.totalRowHeader',
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {beforePGPFReliquatTotal &&
                              fCurrency(beforePGPFReliquatTotal)}
                          </TableCell>
                          <TableCell align="center">
                            {fCurrency(-PGPFReliquat.PGPFReliquat)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" variant="head">
                            {t(`partResponsabilite.tableRowHeader`, {
                              partResponsabilite: fPartResponsabilite(
                                partResponsabilite * 100,
                              ),
                            })}
                          </TableCell>
                          <TableCell align="center">
                            {beforePGPFReliquatTotal &&
                              fCurrency(
                                beforePGPFReliquatTotal * partResponsabilite,
                              )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </NormalTable>
                  </CalculsBox>
                ) : null}
              </Box>
            );
          }}
        />
        <ComputedPropsForm
          control={control}
          watchFields={['prejudiceValues']}
          compute={([prejudiceValues]) => ({
            props: {
              indemniteRepartie:
                CalculsFormListeProjection.getIncidenceProfessionnelleIndemniteRepartie(
                  {
                    partResponsabilite,
                    incidenceProfessionnelle:
                      prejudiceValues as ListeProjectionIncidenceProfessionelle,
                    PGPFReliquat,
                  },
                ),
            },
          })}
          render={(props) => {
            const indemniteRepartie = props.indemniteRepartie as ReturnType<
              typeof CalculsFormListeProjection.getIncidenceProfessionnelleIndemniteRepartie
            >;
            return (
              <TotalIndemniteGlobaleARepartirAEchoir
                indemniteGlobaleARepartir={indemniteRepartie}
                hide={{
                  indemniteTiersPayeurs: {
                    arreragesEchus: true,
                    arreragesAEchoir: true,
                  },
                }}
                partResponsabilite={partResponsabilite}
              />
            );
          }}
        />
      </Box>
    </>
  );
};
