import React from 'react';

interface Props {
  onClick: () => void;
}

export const DintilhacButton: React.FC<Props> = () => {
  // Hidden for now
  return null;
  // return (
  //   <Tooltip title={t('prejudice.fields.dintilhac.buttonText')}>
  //     <IconButton
  //       onClick={onClick}
  //       color="primary"
  //       sx={{
  //         borderRadius: '50%',
  //         border: 'primary.main',
  //         borderStyle: 'solid',
  //         borderWidth: 2,
  //         padding: '4px',
  //       }}
  //     >
  //       <Balance />
  //     </IconButton>
  //   </Tooltip>
  // );
};
