import React, { useRef } from 'react';
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers';
import { TextFieldProps, Tooltip } from '@mui/material';
import {
  UseControllerProps,
  useController,
  FieldValues,
  FieldPath,
} from 'react-hook-form';
import { MAX_DATE, MIN_DATE } from 'src/constants/date';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  TextFieldProps?: Partial<TextFieldProps>;
  required?: boolean;
}

//TODO save dates without time

export const DatePickerForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  TextFieldProps,
  required,
  ...props
}: Props<TFieldValues, TName> &
  Omit<
    DatePickerProps<Date>,
    'onChange' | 'value' | 'renderInput' | 'date' | 'openPicker' | 'rawValue'
  >) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    field: { value, ref: fieldRef, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, shouldUnregister, defaultValue });
  return (
    <Tooltip title={error?.message || ''}>
      <div>
        <DesktopDatePicker
          {...props}
          ref={(e) => {
            fieldRef(e);
            ref.current = e;
          }}
          label={required ? props.label + ' *' : props.label}
          value={value ? new Date(value) : null}
          {...field}
          onChange={(date) => {
            if (date === null || !date || date.toString() === 'Invalid Date') {
              field.onChange(null);
              return;
            }
            if (date < MIN_DATE || date > MAX_DATE) {
              return;
            }
            field.onChange(date.toISOString());
          }}
          onClose={field.onBlur}
          slotProps={{
            textField: {
              ...(TextFieldProps || {}),
              error: !!error,
              onBlur: field.onBlur,
            },
          }}
        />
      </div>
    </Tooltip>
  );
};
