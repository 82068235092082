import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { Document } from 'docx';
import { PrintFormat } from 'src/types/print.type';
import { Prejudice } from 'src/types/prejudice.type';
import i18next from 'i18next';

export const getPrintProcedureFileName = (
  procedure: Procedure,
  victime: Victime,
) =>
  `norma-${
    i18next
      .t(`procedure.fields.procedureType.options.${procedure.procedureType}`)
      ?.toLowerCase() || ''
  }-${victime.referenceDossierVictime.toLowerCase()}`.replaceAll(' ', '');

export const getPrintPrejudiceFileName = (
  procedure: Procedure,
  victime: Victime,
  prejudice: Pick<Prejudice, 'type' | 'formType'>,
) =>
  `norma-${
    i18next
      .t(`procedure.fields.procedureType.options.${procedure.procedureType}`)
      ?.toLowerCase() || ''
  }-${victime.referenceDossierVictime.toLowerCase()}-${prejudice.type
    .toLowerCase()
    .replaceAll(' ', '')}`;

const saveAsDocx = (document: Document, fileName: string) => {
  Packer.toBlob(document)
    .then((blob) => saveAs(blob, `${fileName}.docx`))
    .catch((e) => {
      console.log(e);
    });
};

export const saveDocument = (
  document: Document,
  fileName: string,
  printFormat: PrintFormat,
) => {
  if (printFormat === 'docx') {
    saveAsDocx(document, fileName);
  }
};
