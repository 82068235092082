import React from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import {
  Stack,
  Button,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';

import {
  ListeProjectionMaladie,
  ListeProjectionMaladieRow,
  NumeroPieceValuesRow,
  PrejudiceFormListeProjection,
  PrejudiceType,
} from '../../../../types/prejudice.type';
import { NormalTable } from '../../../styled';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import { DatePickerForm } from '../../../forms/DatePickerForm';
import { CalculsBox } from '../../../basic/CalculsBox';
import { CalculsGlobal } from '../../../../constants/calculs';
import { dateString } from 'src/helpers/yup';
import {
  getDefaultMinAndMaxDate,
  validationSchemaNumeroPieces,
} from 'src/constants/prejudice/validation';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { Victime } from 'src/types/victime.type';
import { Procedure } from 'src/types/procedure.type';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface Props {
  control: Control<PrejudiceFormListeProjection>;
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
  allNumerosPieces: NumeroPieceValuesRow[];
}

export const validationSchemaPrejudiceMaladie = ({
  procedure,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<ListeProjectionMaladie> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  return yup.object({
    rows: yup
      .array()
      .defined()
      .of(
        yup.object({
          maladie: yup.string().optional().defined(),
          dateDebut: dateString()
            .nullable()
            .optional()
            .defined()
            .minDate(undefined, minDate.message, minDate.date)
            .maxDate(undefined, maxDate.message, maxDate.date),
          dateFin: dateString()
            .nullable()
            .optional()
            .defined()
            .minDate(
              'dateDebut',
              i18next.t('validation.prejudices.dates.minDateDebut') || '',
            )
            .maxDate(undefined, maxDate.message, maxDate.date),
          jours: yup.number().required().min(0).integer(),
          degre: yup.number().when([], (_, schema) => {
            if (prejudiceType === 'PREJUDICE_SEXUEL') {
              return schema.nullable().optional();
            }
            return schema.defined().nullable().min(0).max(7);
          }),
          montant: yup.number().required().min(0),
          numerosPieces: validationSchemaNumeroPieces,
        }),
      ),
  });
};
const initialValuesRow = (
  prejudiceType: PrejudiceType,
): ListeProjectionMaladieRow => ({
  maladie: '',
  dateDebut: null,
  dateFin: null,
  jours: 0,
  degre: prejudiceType === 'PREJUDICE_SEXUEL' ? undefined : null,
  montant: 0,
  numerosPieces: { rows: [] },
});
export const getInitialValuesPrejudiceMaladie = (
  values: ListeProjectionMaladie | undefined,
): ListeProjectionMaladie => ({
  rows:
    values?.rows?.map((row) => ({
      ...initialValuesRow,
      ...row,
    })) ?? [],
});

export const PrejudiceMaladie: React.FC<Props> = ({
  control,
  procedure,
  victime,
  prejudiceType,
  allNumerosPieces,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prejudiceValues.rows',
  });

  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = getDefaultMinAndMaxDate({ procedure, victime, prejudiceType });
  const displayDegre = prejudiceType !== 'PREJUDICE_SEXUEL';
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.maladie.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.dateDebut.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.dateFin.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.jours.columnHeader',
                )}
              </TableCell>
              {displayDegre && (
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.degre.columnHeader',
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.montant.columnHeader',
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormNumeroPieceDialog
                    control={control}
                    name={`prejudiceValues.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.maladie`}
                    sx={{ width: '100%' }}
                  />
                </TableCell>
                <TableCell>
                  <DatePickerForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.dateDebut`}
                    TextFieldProps={{
                      sx: {
                        minWidth: 155,
                        width: 155,
                      },
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </TableCell>
                <TableCell>
                  <DatePickerForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.dateFin`}
                    TextFieldProps={{
                      sx: {
                        minWidth: 155,
                        width: 155,
                      },
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </TableCell>
                <TableCell>
                  <ComputedTextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.jours`}
                    watchFields={[
                      `prejudiceValues.rows.${index}.dateDebut`,
                      `prejudiceValues.rows.${index}.dateFin`,
                    ]}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 0 },
                      } as IMaskNumberProps,
                    }}
                    compute={(values) => {
                      const [dateDebut, dateFin] = values;

                      if (dateDebut && dateFin) {
                        return CalculsGlobal.getDays(dateDebut, dateFin);
                      }
                      return 0;
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                {displayDegre && (
                  <TableCell>
                    <TextFieldForm
                      control={control}
                      name={`prejudiceValues.rows.${index}.degre`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: { scale: 2 },
                          nullable: true,
                        } as IMaskNumberProps,
                      }}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.montant`}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label={
                      t('forms.table.deleteRow', {
                        index: index + 1,
                      }) || ''
                    }
                    onClick={() => remove(index)}
                  >
                    <DeleteRounded />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <CalculsBox
        sx={{
          alignSelf: 'flex-end',
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'background.default',
          },
        }}
      >
        <Stack direction="column" spacing={2}>
          <ComputedTextFieldForm
            control={control}
            name="montantTotal"
            watchFields={['prejudiceValues.rows']}
            compute={([rows]) => {
              return CalculsGlobal.sommeMontantRows(rows);
            }}
            label={t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.listeProjection.montantTotal.label',
            )}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: '€',
              } as IMaskNumberProps,
            }}
            InputLabelProps={{ shrink: true }}
            editedFieldsName="editedFields"
          />
        </Stack>
      </CalculsBox>
      <Button
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          append(initialValuesRow(prejudiceType));
        }}
      >
        {t('forms.table.addRow')}
      </Button>
    </>
  );
};
