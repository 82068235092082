import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog, Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { HelpOutline } from '@mui/icons-material';
import { PrejudiceType } from 'src/types/prejudice.type';

const IFRAME_WIDTH = '640px';
const IFRAME_HEIGHT = '640px';

interface Props {
  prejudiceType?: PrejudiceType;
  icon: boolean;
}

export const HelpDialog: React.FC<Props> = ({ prejudiceType, icon }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const src = !prejudiceType
    ? 'https://scribehow.com/embed/Creer_un_dossier_une_procedure_acceder_aux_calculs__piyZb_HQT8SLdtVK0jA6yA'
    : (() => {
        switch (prejudiceType) {
          case 'PERTES_REVENUS_DES_PROCHES':
            return 'https://scribehow.com/embed/Perte_de_revenu_des_proches_en_cas_de_deces_de_la_victime_directe__sV4f2pU9R5GzNrH5qL-qYw';
          case 'PREJUDICES_EXTRA_PATRIMONIAUX_EXCEPTIONNELS':
            return 'https://scribehow.com/embed/Prejudices_extrapatrimoniaux_des_victimes_indirectes__boklmM5bQP-Ib5f0kkxaxw';
          case 'PREJUDICE_IMPREPARATION_MEDICALE':
            return 'https://scribehow.com/embed/Prejudice_dimpreparation_medicale__476cFSpRQrqpVQIRE_8aFg';
          case 'PREJUDICE_DANGOISSE_DE_MORT_IMMINENTE':
          case 'PREJUDICE_ETABLISSEMENT':
          case 'PREJUDICE_PERMANENT_EXCEPTIONNEL':
          case 'PREJUDICE_LIES_PATHOLOGIES_EVOLUTIVES':
          case 'PREJUDICE_ANXIETE': {
            return 'https://scribehow.com/embed/Prejudices_detablissement_permanent_exceptionnel_lie_aux_pathologies_evolutives_danxiete_dangoisse_de_mort_imminente__YdYlCjQyRCyXIIaS9WIOAA';
          }
          case 'INDEMNITE_PROVISIONNELLE':
            return 'https://scribehow.com/embed/Indemnite_provisionnelle__ioXFDWVvSsiFp3xaZb6Pmw';
          case 'DEPENSE_SANTE_ACTUELLES':
          case 'FRAIS_DIVERS_ACTUELS': {
            return 'https://scribehow.com/embed/DSA_Frais_divers_actuels__EOQaExWFQQm98hln3mU1rw';
          }
          case 'FRAIS_OBSEQUES':
            return 'https://scribehow.com/embed/Frais_dobseques__Tafqa1OGT6eUrMgdRjJtrw';
          case 'FRAIS_DIVERS_DES_PROCHES':
            return 'https://scribehow.com/embed/Frais_divers_des_proches__yHd6OGXgTCui9J5zENEx_Q';
          case 'ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE':
            return 'https://scribehow.com/embed/Assistance_par_une_tierce_personne_future__Hi0qVl_JR3OwE6ccHUKvGA';
          case 'ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE':
            return 'https://scribehow.com/embed/Assistance_par_une_tierce_personne_temporaire__MwpxKBcWTDyljCfaOkhyIg';
          case 'PERTES_GAINS_PROFESSIONNELS_ACTUELS':
            return 'https://scribehow.com/embed/Pertes_de_gains_professionnels_actuels__qNlIcS70R6GjxqVtX0v6kA';
          case 'PERTES_GAINS_PROFESSIONNELS_FUTURS':
            return 'https://scribehow.com/embed/Pertes_de_gains_professionnels_futurs__dl5eZnIXSa6jHUab42al6Q';
          case 'INCIDENCE_PROFESSIONNELLE':
            return 'https://scribehow.com/embed/Incidence_professionnelle__ZoX2cVLhQnuJgRNcqEk4PA';
          case 'PREJUDICE_AGREMENT':
          case 'PREJUDICE_ESTHETIQUE_PERMANENT':
          case 'PREJUDICE_SEXUEL': {
            return 'https://scribehow.com/embed/Prejudice_dagrement_prejudice_sexuel_prejudice_esthetique_permanent__cA6Yt_6ISaeYp4FzWbtc9A';
          }
          case 'DEFICIT_FONCTIONNEL_PERMANENT':
            return 'https://scribehow.com/embed/Deficit_fonctionnel_permanent__sekt17zDTyC1iQytWMo2FQ';
          case 'SOUFFRANCES_ENDUREES':
          case 'PREJUDICE_ESTHETIQUE_TEMPORAIRE': {
            return 'https://scribehow.com/embed/Souffrances_endurees_temporaires_prejudice_esthetique_temporaire__wA88IHfqSdWuSRtlM7vlnQ';
          }
          case 'DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL':
          case 'DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL': {
            return 'https://scribehow.com/embed/Deficit_fonctionnel_temporaire__ddJQMUS9SgG3yUl-00X7wQ';
          }
          case 'PREJUDICE_MATERIEL':
            return 'https://scribehow.com/embed/Dommage_materiel__ZQ8ddQREQpKHmWfUnQW0zA';
          case 'PREJUDICE_SCOLAIRE_UNIVERSITAIRE_FORMATION':
            return 'https://scribehow.com/embed/Prejudice_scolaire_universitaire_ou_de_formation__pArNnUshT_mRskOU1hhyHA';
        }
      })();

  const handleButtonClick = () => setOpen(true);

  const srcWithOption = src ? `${src}?removeLogo=true` : null;
  return srcWithOption ? (
    <div>
      {icon ? (
        <IconButton onClick={handleButtonClick}>
          <HelpOutline />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          onClick={handleButtonClick}
          sx={(theme) => ({
            borderRadius: `${theme.shape.borderRadius}px`,
            margin: theme.spacing(0, 2),
            backgroundColor: '#1270FD',
            transform: 'scale(1)',
            transition: '0.3s linear',
            '&:hover': {
              backgroundColor: '#1270FD',
              transform: 'scale(1.1)',
              transition: '0.3s linear',
            },
          })}
        >
          {t('loggedLayout.aide')}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            minWidth: '500px',
            padding: '30px',
            borderRadius: 0,
          }}
        >
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              cursor: 'pointer',
            }}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            title="embedded-content"
            src={srcWithOption}
            width={IFRAME_WIDTH}
            height={IFRAME_HEIGHT}
          />
        </Card>
      </Dialog>
    </div>
  ) : null;
};
