import { Paragraph, Table, TableOfContents } from 'docx';
import { getParagraph } from '../docxFunctions';
import i18next from 'i18next';
import { Bareme } from 'src/types/bareme.type';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';

export const capitalisationSelectedBaremeIntroductionWrittenPrint = (
  selectedBareme: Bareme | undefined,
): (Table | TableOfContents | Paragraph)[] => [
  getParagraph(
    selectedBareme
      ? i18next.t(
          'prejudice.capitalisation.writtenPrint.selectedBaremeIntroduction',
          {
            bareme: getCapitalisationBaremeSourceLabel(selectedBareme),
          },
        )
      : i18next.t(
          'prejudice.capitalisation.writtenPrint.noSelectedBaremeIntroduction',
        ),
  ),
];
