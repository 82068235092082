import React, { useMemo, useState } from 'react';
import {
  Control,
  FieldPath,
  useFieldArray,
  useFormState,
} from 'react-hook-form';
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import { LayoutTable, NormalTable, SpanNoWrap } from '../../../styled';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';

import { useTranslation } from 'react-i18next';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { fCurrency } from '../../../../helpers/formatNumber';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  NumeroPieceValuesRow,
  unites,
} from '../../../../types/prejudice.type';
import {
  getDisplayedQuotientRevalorisations,
  getMontantRevalorise,
  getQuotientRevalorisationTooltip,
} from 'src/helpers/prejudices/revalorisation';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { defaultRevenuActiviteAnnuelDeReferenceRow } from '../../prejudiceFormTypes/NewFormPerteGainsProfessionnelsFuturs';
import { TotalPerteGainsProfessionnelsActuel } from './TablePerteDeChanceDeGainProfessionnel';
import { Accordion } from 'src/components/basic/Accordion';
import { intersection } from 'lodash';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';

interface Props {
  control: Control<
    | NewPrejudiceFormPerteGainProfessionnelsActuel
    | NewPrejudiceFormPerteGainProfessionnelsFuturs
  >;
  isError?: boolean;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  allNumerosPieces: NumeroPieceValuesRow[];
}

export const TableRevenuActiviteAnnuelDeReference: React.FC<Props> = ({
  control,
  isError = false,
  dateLiquidation,
  monetaryErosions,
  allNumerosPieces,
}) => {
  const [
    isRevenuActiviteAnnuelDeReferenceAccordionActive,
    setIsRevenuActiviteAnnuelDeReferenceAccordionActive,
  ] = useState(false);
  const [
    isPerteDeChanceDeGainProfessionnelAccordionActive,
    setIsPerteDeChanceDeGainProfessionnelAccordionActive,
  ] = useState(false);
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    name: 'revenuActiviteAnnuelDeReference.rows',
    control,
  });
  const { errors } = useFormState({
    control,
  });
  const onAppendRow = () => {
    append(defaultRevenuActiviteAnnuelDeReferenceRow);
  };
  const isRevenuActiviteAnnuelDeReferenceError = useMemo(() => {
    const fields: FieldPath<NewPrejudiceFormPerteGainProfessionnelsActuel>[] = [
      'revenuActiviteAnnuelDeReference.rows',
    ];

    return (
      intersection(Object.keys(errors), fields).length > 0 &&
      !isRevenuActiviteAnnuelDeReferenceAccordionActive
    );
  }, [errors, isRevenuActiviteAnnuelDeReferenceAccordionActive]);

  const isPerteDeChanceDeGainProfessionnelError = useMemo(() => {
    const fields: FieldPath<NewPrejudiceFormPerteGainProfessionnelsActuel>[] = [
      'perteDeChanceDeGainProfessionnel',
    ];
    return (
      intersection(Object.keys(errors), fields).length > 0 &&
      !isPerteDeChanceDeGainProfessionnelAccordionActive
    );
  }, [errors, isPerteDeChanceDeGainProfessionnelAccordionActive]);

  return (
    <>
      <Stack alignItems="start" width="100%">
        <CalculsBox padding={2}>
          <FormControl>
            <FormLabel
              sx={{
                textAlign: 'center',
              }}
            >
              {t('prejudice.revalorisation.coefficientsType.choice.label')}
            </FormLabel>
            <RadioFormField
              control={control}
              name="revalorisationCoefficientsType"
              options={[
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                  ),
                  value: 'annuel',
                },
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.smic',
                  ),
                  value: 'smic',
                },
              ]}
              row={true}
            />
          </FormControl>
        </CalculsBox>
      </Stack>
      <Accordion
        title={t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.title',
        )}
        isError={isRevenuActiviteAnnuelDeReferenceError}
        errorTooltip={t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.errorTooltip',
        )}
        expanded={isRevenuActiviteAnnuelDeReferenceAccordionActive}
        onChange={() =>
          setIsRevenuActiviteAnnuelDeReferenceAccordionActive(
            !isRevenuActiviteAnnuelDeReferenceAccordionActive,
          )
        }
      >
        <TableContainer component={Paper}>
          <NormalTable size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: '50px' }}>
                  {t('numeroPiece.form.columnHeader')}
                </TableCell>
                <TableCell align="center" sx={{ width: '50px' }}>
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.rows.annee.columnHeader',
                  )}
                </TableCell>
                <TableCell align="center" sx={{ width: '350px' }}>
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.rows.revenuNet.columnHeader',
                  )}
                </TableCell>
                <TableCell align="center">
                  {t(
                    'prejudice.revalorisation.columnHeaders.montantDejaRevalorise',
                  )}
                </TableCell>
                <TableCell align="center">
                  {t('prejudice.revalorisation.quotient.columnHeader')}
                </TableCell>
                <TableCell align="center" sx={{ minWidth: '150px' }}>
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.rows.revenuActiviteAnnuelNet.columnHeader',
                  )}
                </TableCell>
                <TableCell align="center" sx={{ minWidth: '50px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => (
                <TableRow key={field.id}>
                  <TableCell align="center">
                    <FormNumeroPieceDialog
                      control={control}
                      name={`revenuActiviteAnnuelDeReference.rows.${index}.numerosPieces`}
                      allOtherNumeroPieces={allNumerosPieces}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextFieldForm
                      control={control}
                      name={`revenuActiviteAnnuelDeReference.rows.${index}.annee`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          maxLength: 4,
                          numberMask: { scale: 2, thousandsSeparator: '' },
                        } as IMaskNumberProps,
                      }}
                      sx={{ maxWidth: '100px' }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <TextFieldForm
                        control={control}
                        name={`revenuActiviteAnnuelDeReference.rows.${index}.revenuNet`}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                      />
                      <SelectFieldForm
                        control={control}
                        name={`revenuActiviteAnnuelDeReference.rows.${index}.unite`}
                        options={unites.map((unite) => ({
                          label: t(
                            `prejudice.fields.unite.optionsPar.${unite}`,
                          ),
                          value: unite,
                        }))}
                        sx={{ width: '130px' }}
                      />
                    </Stack>
                  </TableCell>
                  <ComputedReadFieldForm
                    control={control}
                    name={`revenuActiviteAnnuelDeReference.rows.${index}.revenuActiviteAnnuelNet`}
                    watchFields={[
                      `revenuActiviteAnnuelDeReference.rows.${index}.revenuNet`,
                      `revenuActiviteAnnuelDeReference.rows.${index}.unite`,
                      `revenuActiviteAnnuelDeReference.rows.${index}.annee`,
                      `revenuActiviteAnnuelDeReference.rows.${index}.montantsDejaRevalorises`,
                    ]}
                    compute={([revenuNet, unite]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getRevenuActiviteAnnuelNetReference(
                        {
                          revenuNet,
                          unite,
                        },
                      )
                    }
                  />
                  <TableCell align="center">
                    <CheckboxFormField
                      control={control}
                      name={`revenuActiviteAnnuelDeReference.rows.${index}.montantsDejaRevalorises`}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <ComputedPropsForm
                      control={control}
                      watchFields={[
                        `revenuActiviteAnnuelDeReference.rows.${index}.annee`,
                        `revenuActiviteAnnuelDeReference.rows.${index}.montantsDejaRevalorises`,
                        'revalorisationCoefficientsType',
                      ]}
                      compute={([
                        annee,
                        montantsDejaRevalorises,
                        revalorisationCoefficientsType,
                      ]) => {
                        if (
                          montantsDejaRevalorises ||
                          !monetaryErosions ||
                          !dateLiquidation ||
                          !(annee || annee === 0)
                        ) {
                          return {
                            props: {
                              displayedQuotientRevalorisation: '',
                            },
                            hidden: true,
                          };
                        }
                        let displayedQuotientRevalorisation = '';

                        if (
                          revalorisationCoefficientsType === 'annuel' ||
                          revalorisationCoefficientsType === 'smic'
                        ) {
                          displayedQuotientRevalorisation =
                            getDisplayedQuotientRevalorisations({
                              anneeOrDateLiquidation: dateLiquidation,
                              anneeOrDateMontant: annee,
                              monetaryErosions,
                              coefficientsType: revalorisationCoefficientsType,
                            });
                        }
                        return {
                          props: {
                            displayedQuotientRevalorisation,
                          },
                        };
                      }}
                      render={(values) => {
                        const displayedQuotientRevalorisation =
                          values.displayedQuotientRevalorisation as string;
                        return (
                          <SpanNoWrap>
                            {displayedQuotientRevalorisation}
                          </SpanNoWrap>
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <ComputedPropsForm
                      control={control}
                      watchFields={[
                        `revenuActiviteAnnuelDeReference.rows.${index}.revenuActiviteAnnuelNet`,
                        `revenuActiviteAnnuelDeReference.rows.${index}.montantsDejaRevalorises`,
                        `revenuActiviteAnnuelDeReference.rows.${index}.annee`,
                        'revalorisationCoefficientsType',
                      ]}
                      compute={([
                        revenuActiviteAnnuelNet,
                        montantsDejaRevalorises,
                        annee,
                        revalorisationCoefficientsType,
                      ]) => {
                        if (
                          montantsDejaRevalorises ||
                          !dateLiquidation ||
                          !(annee || annee === 0) ||
                          !revenuActiviteAnnuelNet
                        ) {
                          return {
                            props: {
                              revenuActiviteAnnuelNet,
                              montantRevalorise: null,
                              quotientRevalorisationTooltip: '',
                              displayMontantRevalorise: false,
                            },
                          };
                        }
                        return {
                          props: {
                            revenuActiviteAnnuelNet,
                            montantRevalorise: getMontantRevalorise({
                              anneeOrDateLiquidation: dateLiquidation,
                              montant: revenuActiviteAnnuelNet,
                              monetaryErosions: monetaryErosions,
                              anneeOrDateMontant: annee,
                              coefficientsType: revalorisationCoefficientsType,
                            }),
                            quotientRevalorisationTooltip:
                              getQuotientRevalorisationTooltip({
                                anneeOrDateLiquidation: dateLiquidation,
                                anneeOrDateMontant: annee,
                                monetaryErosions,
                                coefficientsType:
                                  revalorisationCoefficientsType,
                              }),
                            displayMontantRevalorise: true,
                          },
                        };
                      }}
                      render={({
                        revenuActiviteAnnuelNet,
                        montantRevalorise,
                        quotientRevalorisationTooltip,
                        displayMontantRevalorise,
                      }) =>
                        !displayMontantRevalorise ? (
                          <SpanNoWrap>
                            {fCurrency(revenuActiviteAnnuelNet)}
                          </SpanNoWrap>
                        ) : (
                          <Tooltip
                            title={quotientRevalorisationTooltip || ''}
                            placement="top"
                          >
                            <SpanNoWrap>
                              {montantRevalorise !== null
                                ? fCurrency(montantRevalorise)
                                : null}
                            </SpanNoWrap>
                          </Tooltip>
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-label={`${t('forms.table.deleteRow', {
                        index: index + 1,
                      })}`}
                      onClick={() => remove(index)}
                    >
                      <DeleteRounded />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell align="right">
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.rowHeader',
                  )}
                </TableCell>
                <TableCell align="center">
                  <ComputedPropsForm
                    control={control}
                    watchFields={[
                      'revenuActiviteAnnuelDeReference.rows',
                      'revalorisationCoefficientsType',
                    ]}
                    compute={([rows, revalorisationCoefficientsType]) => {
                      const total =
                        CalculsFormNewPerteGainsProfessionnelsActuels.getTotalRevenuDeReferenceAnnuel(
                          {
                            rows,
                            revalorisationCoefficientsType,
                            dateLiquidation,
                            monetaryErosions,
                          },
                        );
                      return {
                        props: { total },
                      };
                    }}
                    render={({ total }) => (
                      <SpanNoWrap>{fCurrency(total)}</SpanNoWrap>
                    )}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableFooter>
          </NormalTable>
        </TableContainer>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={1}
          paddingBottom={2}
        >
          <Button variant="contained" color="primary" onClick={onAppendRow}>
            {t('forms.table.addRow')}
          </Button>
        </Stack>
      </Accordion>
      <Accordion
        title={t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.title',
        )}
        isError={isPerteDeChanceDeGainProfessionnelError}
        errorTooltip={t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.errorTooltip',
        )}
        expanded={isPerteDeChanceDeGainProfessionnelAccordionActive}
        onChange={() =>
          setIsPerteDeChanceDeGainProfessionnelAccordionActive(
            !isPerteDeChanceDeGainProfessionnelAccordionActive,
          )
        }
      >
        <TotalPerteGainsProfessionnelsActuel
          control={
            control as Control<NewPrejudiceFormPerteGainProfessionnelsActuel>
          }
          allNumerosPieces={allNumerosPieces}
        />
      </Accordion>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <CalculsBox>
          <LayoutTable>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.table.title',
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <ComputedPropsForm
                control={control}
                watchFields={['revenuActiviteAnnuelDeReference.total']}
                compute={([totalRevenuActiviteAnnuelDeReference]) => {
                  const total =
                    CalculsFormNewPerteGainsProfessionnelsActuels.getTotalRevenuDeReference(
                      {
                        totalRevenuActiviteAnnuelDeReference,
                      },
                    );
                  return {
                    props: total,
                  };
                }}
                render={(value) => {
                  const { mois, semaine, jour } = value as ReturnType<
                    typeof CalculsFormNewPerteGainsProfessionnelsActuels.getTotalRevenuDeReference
                  >;
                  return (
                    <>
                      <TableRow>
                        <TableCell align="left">
                          {t(
                            'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.table.jour.rowHeader',
                          )}
                        </TableCell>
                        <TableCell align="right">{fCurrency(jour)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          {t(
                            'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.table.semaine.rowHeader',
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {fCurrency(semaine)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          {t(
                            'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.table.mois.rowHeader',
                          )}
                        </TableCell>
                        <TableCell align="right">{fCurrency(mois)}</TableCell>
                      </TableRow>
                    </>
                  );
                }}
              />
              <TableRow>
                <TableCell align="left">
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.table.annee.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <ComputedTextFieldForm
                    control={control}
                    watchFields={[
                      'revenuActiviteAnnuelDeReference.rows',
                      'revalorisationCoefficientsType',
                      'perteDeChanceDeGainProfessionnel.perteDeChance',
                    ]}
                    compute={([
                      rows,
                      revalorisationCoefficientsType,
                      perteDeChance,
                    ]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getTotalRevenuDeReferenceAnnuelAndPerteDeChance(
                        {
                          rows,
                          dateLiquidation,
                          monetaryErosions,
                          revalorisationCoefficientsType,
                          perteDeChanceDeGainProfessionnelTotal: perteDeChance,
                        },
                      ) || 0
                    }
                    name="revenuActiviteAnnuelDeReference.total"
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </LayoutTable>
        </CalculsBox>
      </Stack>
      {isError && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={'center'}
        >
          <Typography color="error" align={'center'} fontWeight={'bold'}>
            {t(
              'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.stepper.error.requiredStep',
            )}
          </Typography>
        </Stack>
      )}
    </>
  );
};
