import React, { useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APP_NAME } from '../constants/global';
import Page from '../components/basic/Page';
import { theme } from '../constants/theme';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchAllVictimes, updateVictime } from 'src/slices/victime';
import { NavBar } from 'src/containers/NavBar';
import VictimeEdition from 'src/containers/client/VictimeEdition';
import { victimeSelectors } from '../store/selectors';
import { ConfirmationDialog } from '../components/basic/ConfirmationDialog';
import { VictimMenu } from '../components/dashboard/VictimMenu';
import VictimTrashCanDialog from '../containers/VictimTrashCanDialog';
import { TrashCanCTA } from '../components/TrashCanCTA';
import { Victime } from 'src/types/victime.type';
import { UpdateVictimeRolesDialog } from 'src/components/dashboard/UpdateVictimeRolesDialog';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { useCanCreateVictime } from 'src/hooks/store/victime.hook';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

type GridRowType = Pick<
  Victime,
  'createdAt' | 'updatedAt' | 'referenceDossierVictime'
> & {
  id: string;
  victime: Victime;
};

const DashboardAsDataTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteVictimConfirmDialog, setDeleteVictimConfirmDialog] =
    useState(false);
  const [victimToDelete, setVictimToDelete] = useState<string | null>(null);

  const victimes = useAppSelector(victimeSelectors.selectAll);
  const { organization } = useDisplayedOrganization();
  const fetchVictimes = () => dispatch(fetchAllVictimes(organization?._id));
  const [openEditVictime, setOpenEditVictime] = useState(false);
  const [openVictimTrashCan, setOpenVictimTrashCan] = useState(false);
  const [openUpdateVictimeRolesVictime, setOpenUpdateVictimeRolesVictime] =
    useState<Victime | null>(null);

  const [victimeRows, setDossierRows] = useState<GridRowType[]>([]);

  useEffect(() => {
    fetchVictimes();
  }, [organization?._id]);

  useEffect(() => {
    setDossierRows(
      victimes
        .filter((victime) => !victime.inTrash)
        .map((victime) => ({
          id: victime._id,
          referenceDossierVictime: victime.referenceDossierVictime,
          createdAt: new Date(victime.createdAt).toLocaleDateString(),
          updatedAt: new Date(victime.updatedAt).toLocaleDateString(),
          victime,
        })),
    );
  }, [victimes]);

  const redirect = (dossierId: string) => {
    navigate(`./client/${dossierId}`, { replace: false });
  };

  const putVictimInTrash = async () => {
    if (victimToDelete) {
      await dispatch(
        updateVictime({ id: victimToDelete, data: { inTrash: true } }),
      );
      setDeleteVictimConfirmDialog(false);
      fetchVictimes();
    }
  };

  const canCreateVictime = useCanCreateVictime();
  const renderHeader = (title: string) => (
    <Typography
      color="primary"
      fontWeight={600}
      variant="body2"
      sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
    >
      {title}
    </Typography>
  );
  const columns: GridColDef<GridRowType>[] = [
    {
      field: 'referenceDossierVictime',
      renderHeader: () =>
        renderHeader(t('dashboard.table.referenceDossierVictime')),
      width: 200,
      flex: 1,
      hideable: false,
    },
    {
      field: 'createdAt',
      renderHeader: () => renderHeader(t('dashboard.table.creation')),
      headerAlign: 'center',
      width: 200,
      align: 'center',
      hideable: false,
    },
    {
      field: 'updatedAt',
      renderHeader: () => renderHeader(t('dashboard.table.edit')),
      headerAlign: 'center',
      width: 200,
      align: 'center',
      hideable: false,
    },
    {
      field: 'actions',
      renderHeader: () => (
        <TrashCanCTA onClick={() => setOpenVictimTrashCan(true)} />
      ),
      renderCell: (params) => (
        <VictimMenu
          victime={params.row.victime}
          setVictimToDelete={setVictimToDelete}
          setDeleteVictimConfirmDialog={setDeleteVictimConfirmDialog}
          setUpdateVictimRolesDialog={(open) =>
            setOpenUpdateVictimeRolesVictime(open ? params.row.victime : null)
          }
        />
      ),
      hideable: false,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
    },
  ];
  return (
    <>
      <Page title={APP_NAME}>
        <NavBar />
        <Box
          sx={{
            padding: 3,
            position: 'relative',
            top: '64px',
          }}
        >
          <DataGrid
            rows={victimeRows}
            columns={columns}
            onRowClick={(params) => {
              redirect(params.id.toString());
            }}
            hideFooter
            sx={{
              border: 'none',
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-cell': {
                '&:focus': {
                  outline: 'none',
                },
              },
            }}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: () => <>{t('dashboard.table.noData')}</>,
              noResultsOverlay: () => <>{t('dashboard.table.noData')}</>,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                sx: {
                  flexDirection: 'row-reverse',
                },
                quickFilterProps: {
                  placeholder: t('dashboard.table.search.placeholder'),
                  sx: {
                    width: '300px',
                  },
                },
              },
            }}
          />
        </Box>
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          sx={{
            color: '#ffffff',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            position: 'fixed',
            bottom: 24,
            right: 24,
          }}
          onClick={() =>
            canCreateVictime
              ? setOpenEditVictime(true)
              : navigate('/subscription')
          }
          disabled={!canCreateVictime && !!organization}
        >
          {canCreateVictime
            ? t('dashboard.folderButton.validSubscription')
            : !organization
              ? t('dashboard.folderButton.invalidSubscription')
              : t('dashboard.folderButton.noPermission')}
        </Fab>
      </Page>
      <ConfirmationDialog
        open={deleteVictimConfirmDialog}
        onCancel={() => setDeleteVictimConfirmDialog(false)}
        onConfirm={putVictimInTrash}
        title="Mettre ce dossier dans la corbeille"
        description="Voulez-vous vraiment mettre ce dossier dans la corbeille ?"
        confirmText="Mettre dans la corbeille"
        cancelText="Annuler"
      />
      <VictimeEdition
        isSidebar={true}
        isOpen={openEditVictime}
        closeSideBar={() => setOpenEditVictime(false)}
      />
      <VictimTrashCanDialog
        isSidebar={true}
        isOpen={openVictimTrashCan}
        closeSideBar={() => setOpenVictimTrashCan(false)}
      />
      <UpdateVictimeRolesDialog
        open={openUpdateVictimeRolesVictime !== null}
        victime={openUpdateVictimeRolesVictime}
        onClose={() => setOpenUpdateVictimeRolesVictime(null)}
      />
    </>
  );
};

export default DashboardAsDataTable;
