import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { apiRequest } from 'src/helpers/api';
import { pendingReducer, rejectedReducer } from '../helpers/slice';
import {
  CreateProcedureDto,
  UpdateProcedureDto,
  Procedure,
} from '../types/procedure.type';
import { addLocalProcedure, updateLocalProcedure } from './victime';

/* Thunks */

export const createProcedure = createAsyncThunk(
  'procedure/create',
  async ({
    victimeId,
    data,
  }: {
    victimeId: string;
    data: CreateProcedureDto;
  }) => {
    return await apiRequest<Procedure>(
      'POST',
      `/procedures/${victimeId}`,
      undefined,
      data,
    );
  },
);

export const updateProcedure = createAsyncThunk(
  'procedure/update',
  async (
    {
      victimeId,
      procedureId,
      data,
    }: { victimeId: string; procedureId: string; data: UpdateProcedureDto },
    thunkAPI,
  ) => {
    const updatedProcedure = await apiRequest<Procedure>(
      'PATCH',
      `/procedures/${procedureId}`,
      undefined,
      data,
    );
    thunkAPI.dispatch(
      updateLocalProcedure({ victimeId, procedure: updatedProcedure }),
    );
    return updatedProcedure;
  },
);

export const deleteProcedureById = createAsyncThunk(
  'procedure/delete',
  async (procedureId: string) => {
    return await apiRequest<Procedure>('DELETE', `/procedures/${procedureId}`);
  },
);
export const duplicateProcedure = createAsyncThunk(
  'procedure/duplicate',
  async (
    {
      procedureId,
      victimeId,
    }: {
      procedureId: string;
      victimeId: string;
    },
    { dispatch },
  ) => {
    const duplicatedProcedure = await apiRequest<Procedure>(
      'POST',
      `/procedures/${victimeId}/duplicate/${procedureId}`,
    );

    dispatch(addLocalProcedure({ victimeId, procedure: duplicatedProcedure }));
  },
);
/* Slice */

export const procedureAdapter = createEntityAdapter<Procedure>({
  selectId: (procedure: Procedure) => procedure._id,
});

const procedureSlice = createSlice({
  name: 'procedure',
  initialState: procedureAdapter.getInitialState({
    isLoading: false,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(duplicateProcedure.pending, pendingReducer)
      .addCase(duplicateProcedure.rejected, rejectedReducer)
      .addCase(duplicateProcedure.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createProcedure.pending, pendingReducer)
      .addCase(createProcedure.rejected, rejectedReducer)
      .addCase(createProcedure.fulfilled, (state, { payload }) => {
        procedureAdapter.addOne(state, payload);
        state.isLoading = false;
      })
      .addCase(updateProcedure.pending, pendingReducer)
      .addCase(updateProcedure.rejected, rejectedReducer)
      .addCase(updateProcedure.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteProcedureById.pending, pendingReducer)
      .addCase(deleteProcedureById.rejected, rejectedReducer)
      .addCase(deleteProcedureById.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default procedureSlice.reducer;
