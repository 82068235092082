import {
  IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  PerteDeChanceDeGainProfessionnel,
  PerteDeChanceDeGainProfessionnelRow,
  PerteDeGainsProfessionnelsRow,
  PertesDeGainsProfessionnels,
  RevenuActiviteAnnuelDeReference,
  RevenuActiviteAnnuelDeReferenceRow,
} from 'src/types/prejudice.type';
import { CalculsFormNewPerteGainsProfessionnelsFuturs, CalculsGlobal } from '.';
import {
  IndemniteRepartieEchus,
  TropPercuEchus,
} from 'src/constants/calculs/type';
import { Time } from '../time';
import { differenceInDays } from 'date-fns';
import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { groupBy } from 'lodash';

export abstract class CalculsFormNewPerteGainsProfessionnelsActuels {
  static getIndemnitesRepartie({
    partResponsabilite,
    perteGainProfessionnelsTotal,
    indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal,
    indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet,
    indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
  }: {
    partResponsabilite: number;
    perteGainProfessionnelsTotal: number;
    indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal:
      | number
      | null;
    indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet:
      | number
      | null;
    indemnitesJournalieresPercuesPendantLaPeriodeDArretRows: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow[];
  }): IndemniteRepartieEchus & TropPercuEchus {
    const total = CalculsFormNewPerteGainsProfessionnelsFuturs.getTotal(
      perteGainProfessionnelsTotal,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet,
    );
    const { B, Da, D, E, rows } = {
      B: perteGainProfessionnelsTotal,
      D: indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet,
      Da: indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal,
      E: total < 0 ? 0 : total,
      rows: indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
    };
    const FBeforePartResponsabilite = (Number(B) || 0) + (Number(Da) || 0);
    const F =
      ((Number(B) || 0) + (Number(Da) || 0)) * Number(partResponsabilite);
    const G = Number(E) <= Number(F) ? Number(E) : Number(F);
    const H = Number(F) - Number(G);
    const tiersPayeursTotaux =
      CalculsFormNewPerteGainsProfessionnelsFuturs.getTiersPayeursTotaux(rows);
    return {
      indemniteGlobaleARepartir: {
        solde: F,
        beforePartResponsabilite: FBeforePartResponsabilite,
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          debit: H,
          parTiersPayeur: tiersPayeursTotaux.map(
            ({
              tiersPayeur,
              csgRDSParPeriode,
              indemnitesPercuesNetParPeriode,
            }) => ({
              montant:
                (H *
                  (Number(csgRDSParPeriode) +
                    Number(indemnitesPercuesNetParPeriode))) /
                (Number(D) + Number(Da) || 1),

              montantNonReparti:
                Number(csgRDSParPeriode) +
                Number(indemnitesPercuesNetParPeriode),
              tiersPayeur,
            }),
          ),
          totalNonReparti: Number(D) + Number(Da),
        },
      },
      indemniteVictime: {
        arreragesEchus: {
          debit: G,
          solde: H,
        },
      },
      tropPercu: total < 0 ? total : null,
    };
  }
  static getRevenuActiviteAnnuelNetReference({
    unite,
    revenuNet,
  }: Pick<RevenuActiviteAnnuelDeReferenceRow, 'unite' | 'revenuNet'>) {
    switch (unite) {
      case 'jour':
        return revenuNet * Time.daysInYear;
      case 'semaine':
        return revenuNet * Time.weeksInYear;
      case 'mois':
        return revenuNet * Time.monthsInYear;
      case 'annee':
        return Number(revenuNet);
      default:
        return 0;
    }
  }

  static getRevenuPercuParPeriode({
    unite,
    revenuPercuNet,
    duree,
  }: Pick<
    PerteDeGainsProfessionnelsRow,
    'unite' | 'revenuPercuNet' | 'duree'
  >) {
    duree = duree || 0;
    switch (unite) {
      case 'jour':
        return revenuPercuNet * duree;
      case 'semaine':
        return (revenuPercuNet * duree) / Time.daysInWeek;
      case 'mois':
        return (revenuPercuNet * duree) / Time.daysInMonth;
      case 'annee':
        return (revenuPercuNet * duree) / Time.daysInYear;
      default:
        return 0;
    }
  }

  static getDuree({
    dateDebut,
    dateFin,
  }: Pick<
    IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
    'dateDebut' | 'dateFin'
  >) {
    if (!dateDebut || !dateFin) return 0;
    return differenceInDays(new Date(dateFin), new Date(dateDebut)) + 1;
  }

  static getRevenuDeReferencePeriode({
    duree,
    revenuDeReferenceAnnuel,
  }: Pick<PerteDeGainsProfessionnelsRow, 'duree' | 'revenuDeReferenceAnnuel'>) {
    return (revenuDeReferenceAnnuel * (duree || 0)) / (Time.daysInYear || 0);
  }

  static getTotalRevenuDeReferenceAnnuel({
    rows,
    dateLiquidation,
    monetaryErosions,
    revalorisationCoefficientsType,
  }: {
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
  } & Pick<
    NewPrejudiceFormPerteGainProfessionnelsActuel,
    'revalorisationCoefficientsType'
  > &
    Pick<RevenuActiviteAnnuelDeReference, 'rows'>): number {
    const rowsByYear = Object.values(groupBy(rows, 'annee'));
    const totalRevenuDeReference =
      CalculsGlobal.average(
        rowsByYear.map((rows) =>
          CalculsGlobal.sum(
            rows.map((row) => {
              if (row.montantsDejaRevalorises) {
                return row.revenuActiviteAnnuelNet;
              }
              if (
                (revalorisationCoefficientsType === 'annuel' ||
                  revalorisationCoefficientsType === 'smic') &&
                row.annee
              ) {
                return (
                  getMontantRevalorise({
                    anneeOrDateLiquidation: dateLiquidation,
                    anneeOrDateMontant: row.annee,
                    coefficientsType: revalorisationCoefficientsType,
                    monetaryErosions,
                    montant: row.revenuActiviteAnnuelNet,
                  }) || row.revenuActiviteAnnuelNet
                );
              } else {
                return row.revenuActiviteAnnuelNet;
              }
            }),
          ),
        ),
      ) || 0;
    return totalRevenuDeReference;
  }

  static getTotalRevenuDeReferenceAnnuelAndPerteDeChance({
    rows,
    dateLiquidation,
    monetaryErosions,
    revalorisationCoefficientsType,
    perteDeChanceDeGainProfessionnelTotal,
  }: {
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
    perteDeChanceDeGainProfessionnelTotal: PerteDeChanceDeGainProfessionnel['perteDeChance'];
  } & Pick<
    NewPrejudiceFormPerteGainProfessionnelsActuel,
    'revalorisationCoefficientsType'
  > &
    Pick<RevenuActiviteAnnuelDeReference, 'rows'>): number {
    const totalRevenuDeReference = this.getTotalRevenuDeReferenceAnnuel({
      dateLiquidation,
      monetaryErosions,
      revalorisationCoefficientsType,
      rows,
    });
    return totalRevenuDeReference + perteDeChanceDeGainProfessionnelTotal;
  }

  static getTotalRevenuDeReference({
    totalRevenuActiviteAnnuelDeReference,
  }: {
    totalRevenuActiviteAnnuelDeReference: number;
  }): {
    mois: number;
    semaine: number;
    jour: number;
  } {
    const totalMois = totalRevenuActiviteAnnuelDeReference / Time.monthsInYear;
    const totalJour = totalMois / Time.daysInMonth;
    const totalSemaine = totalJour * Time.daysInWeek;
    return {
      jour: totalJour,
      mois: totalMois,
      semaine: totalSemaine,
    };
  }

  static getPerteDeGainsProfessionnels({
    revenuDeReferencePeriode,
    revenuPercuParPeriode,
    montantsDejaRevalorises,
    dateFin,
    monetaryErosions,
    dateLiquidation,
    revalorisationCoefficientsType,
  }: Pick<
    PerteDeGainsProfessionnelsRow,
    | 'revenuDeReferencePeriode'
    | 'revenuPercuParPeriode'
    | 'montantsDejaRevalorises'
    | 'dateFin'
  > & {
    monetaryErosions: MonetaryErosion[];
    dateLiquidation: Date | undefined;
  } & Pick<
      NewPrejudiceFormPerteGainProfessionnelsActuel,
      'revalorisationCoefficientsType'
    >) {
    return (
      revenuDeReferencePeriode -
      ((montantsDejaRevalorises
        ? revenuPercuParPeriode
        : getMontantRevalorise({
            anneeOrDateLiquidation: dateLiquidation,
            anneeOrDateMontant: dateFin ?? undefined,
            coefficientsType: revalorisationCoefficientsType,
            monetaryErosions,
            montant: revenuPercuParPeriode,
          })) || 0)
    );
  }
  static getIndemnitesPercuesNetParPeriode({
    uniteIndemniteJournalieresPercuesNet,
    duree,
    indemniteJournalieresPercuesNet,
  }: Pick<
    IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
    | 'uniteIndemniteJournalieresPercuesNet'
    | 'duree'
    | 'indemniteJournalieresPercuesNet'
  >) {
    if (
      uniteIndemniteJournalieresPercuesNet &&
      duree &&
      indemniteJournalieresPercuesNet
    ) {
      switch (uniteIndemniteJournalieresPercuesNet) {
        case 'jour':
          return duree * indemniteJournalieresPercuesNet;
        case 'semaine':
          return (duree * indemniteJournalieresPercuesNet) / Time.daysInWeek;
        case 'mois':
          return (duree * indemniteJournalieresPercuesNet) / Time.daysInMonth;
        case 'annee':
          return (duree * indemniteJournalieresPercuesNet) / Time.daysInYear;
        default:
          return null;
      }
    } else return null;
  }
  static getCsgRDSParPeriode({
    uniteCsgRds,
    duree,
    csgRds,
  }: Pick<
    IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
    'uniteCsgRds' | 'duree' | 'csgRds'
  >) {
    if (uniteCsgRds && duree && csgRds) {
      switch (uniteCsgRds) {
        case 'jour':
          return duree * csgRds;
        case 'semaine':
          return (duree * csgRds) / Time.daysInWeek;
        case 'mois':
          return (duree * csgRds) / Time.daysInMonth;
        case 'annee':
          return (duree * csgRds) / Time.daysInYear;
        default:
          return null;
      }
    } else return null;
  }

  static getIndemnitesJournalieresPercuesNetTotal({
    rows,
  }: {
    rows: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow[];
  }) {
    const indemnitésJournalieresPercuesNet = CalculsGlobal.sum(
      rows
        .map((row) =>
          row.indemnitesPercuesNetParPeriode
            ? Number(row.indemnitesPercuesNetParPeriode)
            : null,
        )
        .filter((value): value is number => value !== null),
    );
    const csgRdsTotal = CalculsGlobal.sum(
      rows
        .map((row) =>
          row.csgRDSParPeriode ? Number(row.csgRDSParPeriode) : null,
        )
        .filter((value): value is number => value !== null),
    );
    return {
      csgRdsTotal,
      indemnitésJournalieresPercuesNet,
    };
  }

  static getTotalPerteDeGainsProfessionnels({
    rows,
  }: Pick<PertesDeGainsProfessionnels, 'rows'>) {
    return CalculsGlobal.max([
      CalculsGlobal.sum(rows.map((row) => row.perteDeGainsProfessionnels || 0)),
      0,
    ]);
  }
  static getIndemnitePerteDeChanceAnnuel({
    indemnite,
    unite,
  }: Pick<PerteDeChanceDeGainProfessionnelRow, 'indemnite' | 'unite'>) {
    switch (unite) {
      case 'jour':
        return (indemnite || 0) * Time.daysInYear;
      case 'semaine':
        return (indemnite || 0) * Time.weeksInYear;
      case 'mois':
        return (indemnite || 0) * Time.monthsInYear;
      case 'annee':
        return indemnite || 0;
      default:
        return 0;
    }
  }
  static getTotalIndemnitePerteDeChanceDeGainProfessionnels(
    rows: PerteDeChanceDeGainProfessionnelRow[],
  ) {
    const indemnites = Object.values(groupBy(rows, 'annee')).map((rows) =>
      CalculsGlobal.sum(rows.map(this.getIndemnitePerteDeChanceAnnuel)),
    );
    return CalculsGlobal.average(indemnites) || 0;
  }
  static getPerteDeChanceDeGainProfessionnelsPerteDeChance({
    coefficientPerteDeChance,
    totalIndemnite,
  }: Pick<
    PerteDeChanceDeGainProfessionnel,
    'coefficientPerteDeChance' | 'totalIndemnite'
  >) {
    return (totalIndemnite * coefficientPerteDeChance) / 100;
  }
}
