import React, { useRef, memo, useMemo } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material';
import { Control, useFieldArray } from 'react-hook-form';
import { NormalTable } from '../../../styled';
import {
  PrejudiceFormCalendrierDepenseRow,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierDepenseCapitalisationRow,
  PrejudiceType,
  DepenseType,
  NumeroPieceValuesRow,
} from '../../../../types/prejudice.type';
import { useTranslation } from 'react-i18next';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { Bareme } from 'src/types/bareme.type';
import { TableDepensesRow } from './TableDepensesRow';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { TableDepensesHeader } from './TableDepensesHeader';
import { TableDepensesFooter } from './TableDepensesFooter';
import { SelectBareme } from '../Capitalisation/SelectBareme';
import { getDefaultMinAndMaxDate } from 'src/constants/prejudice/validation';
import { CalculsFormCalendrierDepense } from 'src/constants/calculs';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { Help } from '@mui/icons-material';

export const defaultTableDepenseRow = ({
  tiersPayeurs,
  hidePeriode,
  type,
  isCalculCapitalisation,
  procedure,
  victime,
}: {
  tiersPayeurs: string[];
  hidePeriode?: boolean;
  type: DepenseType;
  isCalculCapitalisation?: boolean;
  procedure: Procedure;
  victime: Victime;
}):
  | PrejudiceFormCalendrierDepenseRow
  | PrejudiceFormCalendrierDepenseCapitalisationRow => {
  const initialTiersPayeursValues = tiersPayeurs.map(
    (tiersPayeur) => ({ tiersPayeur, montant: 0 }),
    {},
  );
  const { dateAccident, dateDeces } = victime;
  const { dateConsolidation, dateLiquidation } = procedure;
  const defaultDateDebut =
    type === 'recurrente' && !isCalculCapitalisation
      ? dateAccident || null
      : type === 'recurrente' && isCalculCapitalisation
        ? dateConsolidation || null
        : null;
  const defaultDateFin =
    type === 'ponctuelle'
      ? null
      : !isCalculCapitalisation
        ? dateConsolidation || dateDeces || dateLiquidation || null
        : null;
  return {
    type,
    intitule: '',
    dateDebut: hidePeriode ? undefined : defaultDateDebut,
    dateFin: hidePeriode ? undefined : defaultDateFin,
    dureeDansUniteChoisie: 1,
    frequenceMontant: 'non_renseigne',
    montantUnitaire: 0,
    montantTotal: 0,
    quantite: 1,
    dateJustificatif: defaultDateDebut,
    montantsDejaRevalorises: false,
    priseEnChargeTiersPayeurs: initialTiersPayeursValues,
    priseEnChargeTiersPayeursProratises: initialTiersPayeursValues,
    resteACharge: 0,
    renouvellementMaintenance: 0,
    numerosPieces: { rows: [] },
    capitalisation: {
      isLastArrerageViager: false,
      ageDernierArrerage: null,
      coefficient: null,
      montantCapitalise: 0,
      dateAttribution: null,
    },
    capitalisationTiersPayeurs: {
      parTiersPayeur: tiersPayeurs.map((tiersPayeur) => ({
        tiersPayeur,
        sommeACapitaliser: 0,
        coefficient: 0,
        montantCapitalise: 0,
      })),
    },
  };
};

interface Props {
  control:
    | Control<PrejudiceFormCalendrierDepenseCapitalisation>
    | Control<PrejudiceFormCalendrierDepense>;
  tiersPayeurs: string[];
  isCalculCapitalisation?: boolean;
  dateConsolidation: Date | undefined;
  dateLiquidation?: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  hideDepenseRecurrente?: boolean;
  hideFrequenceViagere?: boolean;
  hidePeriode?: boolean;
  victimesIndirectes?: VictimeIndirecte[];
  isVictimeIndirecte?: boolean;
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
  baremesCapitalisation?: Bareme[];
  allNumerosPieces: NumeroPieceValuesRow[];
  shouldNotDisplayCapitalisation?: boolean;
  dateDeces: Date | undefined;
}

const TableDepensesComponent: React.FC<Props> = ({
  control,
  tiersPayeurs,
  isCalculCapitalisation,
  dateLiquidation,
  monetaryErosions,
  hideDepenseRecurrente,
  hideFrequenceViagere,
  isVictimeIndirecte,
  victimesIndirectes,
  hidePeriode,
  procedure,
  victime,
  prejudiceType,
  shouldNotDisplayCapitalisation,
  dateDeces,
  ...props
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    name: 'rows',
    control: control as Control<PrejudiceFormCalendrierDepenseCapitalisation>,
  });
  const tableRef = useRef<HTMLTableElement>(null);

  const onAddRow = (type: DepenseType) => {
    append(
      defaultTableDepenseRow({
        hidePeriode,
        tiersPayeurs,
        isCalculCapitalisation,
        type,
        procedure,
        victime,
      }) as PrejudiceFormCalendrierDepenseCapitalisationRow,
    );
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  };

  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = useMemo(
    () =>
      getDefaultMinAndMaxDate({
        procedure,
        victime,
        prejudiceType,
      }),
    [procedure, victime, prejudiceType],
  );
  const {
    minDate: { date: minDateJustificatif },
    maxDate: { date: maxDateJustificatif },
  } = useMemo(
    () =>
      CalculsFormCalendrierDepense.getMinAndMaxDateJustificatif({
        procedure,
        isCalculCapitalisation,
      }),
    [procedure, victime, isCalculCapitalisation],
  );

  return (
    <>
      <Stack
        alignItems="start"
        width="100%"
        direction="row"
        spacing={2}
        height={100}
      >
        <CalculsBox padding={2} height="100%">
          <FormControl>
            <FormLabel
              sx={{
                textAlign: 'center',
              }}
            >
              {t('prejudice.revalorisation.coefficientsType.choice.label')}
            </FormLabel>
            <RadioFormField
              control={control as Control<PrejudiceFormCalendrierDepense>}
              name="revalorisationCoefficientsType"
              options={[
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.mensuel',
                  ),
                  value: 'mensuel',
                },
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                  ),
                  value: 'annuel',
                },
              ]}
              row={true}
            />
          </FormControl>
        </CalculsBox>
        {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
          <>
            <CalculsBox
              padding={2}
              display="flex"
              height="100%"
              justifyContent="center"
            >
              <SelectBareme
                control={
                  control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                }
                baremes={props.baremesCapitalisation ?? []}
                name="capitalisation.bareme"
              />
            </CalculsBox>
            {/* <CalculsBox
              alignItems="center"
              padding={2}
              height="100%"
              display="flex"
            >
              <FormControlLabel
                label={t(
                  'prejudice.capitalisation.form.fields.enableCapitalisationDifferee.label',
                )}
                labelPlacement="start"
                control={
                  <CheckboxFormField
                    id="capitalisation.enableCapitalisationDifferee"
                    control={
                      control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                    }
                    name="capitalisation.enableCapitalisationDifferee"
                  />
                }
              />
              <Tooltip
                title={
                  <Typography
                    whiteSpace="pre-line"
                    maxHeight="400px"
                    overflow="auto"
                  >
                    {t(
                      'prejudice.capitalisation.form.fields.enableCapitalisationDifferee.tooltip',
                    )}
                  </Typography>
                }
              >
                <IconButton>
                  <Help />
                </IconButton>
              </Tooltip>
            </CalculsBox> */}
          </>
        ) : null}
      </Stack>
      <TableContainer component={Paper} sx={{ maxHeight: '570px' }}>
        <NormalTable size="medium" stickyHeader ref={tableRef}>
          <TableDepensesHeader
            hideDepenseRecurrente={hideDepenseRecurrente}
            hidePeriode={hidePeriode}
            isVictimeIndirecte={isVictimeIndirecte}
            tiersPayeurs={tiersPayeurs}
            isCalculCapitalisation={isCalculCapitalisation}
            shouldNotDisplayCapitalisation={
              shouldNotDisplayCapitalisation ?? false
            }
          />
          <TableBody>
            {fields.map((field, index) => {
              return (
                <TableDepensesRow
                  key={field.id}
                  control={control}
                  tiersPayeurs={procedure.tiersPayeurs}
                  monetaryErosions={monetaryErosions}
                  dateLiquidation={dateLiquidation}
                  hideDepenseRecurrente={hideDepenseRecurrente}
                  hideFrequenceViagere={hideFrequenceViagere}
                  hidePeriode={hidePeriode}
                  isVictimeIndirecte={isVictimeIndirecte}
                  victimesIndirectes={victimesIndirectes}
                  procedure={procedure}
                  victime={victime}
                  isCalculCapitalisation={isCalculCapitalisation}
                  rowIndex={index}
                  fieldId={field.id}
                  fieldType={field.type}
                  onClickRemove={remove}
                  minDate={minDate}
                  maxDate={maxDate}
                  minDateJustificatif={minDateJustificatif}
                  maxDateJustificatif={maxDateJustificatif}
                  shouldNotDisplayCapitalisation={
                    shouldNotDisplayCapitalisation ?? false
                  }
                  dateDeces={dateDeces}
                  {...props}
                />
              );
            })}
          </TableBody>
          <TableDepensesFooter
            control={control}
            hideDepenseRecurrente={hideDepenseRecurrente}
            hidePeriode={hidePeriode}
            isCalculCapitalisation={isCalculCapitalisation}
            tiersPayeurs={tiersPayeurs}
            dateLiquidation={dateLiquidation}
            monetaryErosions={monetaryErosions}
            isVictimeIndirecte={isVictimeIndirecte}
            shouldNotDisplayCapitalisation={
              shouldNotDisplayCapitalisation ?? false
            }
          />
        </NormalTable>
      </TableContainer>
      <Stack
        direction="row"
        spacing={4}
        alignSelf="center"
        width="100%"
        justifyContent="center"
      >
        <Button
          onClick={() => {
            onAddRow('ponctuelle');
          }}
        >
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.table.addDepensePonctuelleRow',
          )}
        </Button>
        {!hideDepenseRecurrente && (
          <Button onClick={() => onAddRow('recurrente')}>
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.table.addDepenseRecurrenteRow',
            )}
          </Button>
        )}
      </Stack>
    </>
  );
};

export const TableDepenses = memo(TableDepensesComponent);
