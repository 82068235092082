import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { getEmptyLine, getParagraph } from 'src/helpers/print/docxFunctions';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  PertesDeGainsProfessionnels,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
} from 'src/types/prejudice.type';
import {
  getMontantRevalorisePrint,
  getQuotientRevalorisationPrint,
} from '../../revalorisationPrint';
import { getPeriodePrint } from '../../periodePrint';

export const tablePerteDeGainProfessionnelPrint = ({
  rows,
  total,
  ...props
}: (
  | ({
      formType: 'REVENUS';
      dateLiquidation: Date | null;
      monetaryErosions: MonetaryErosion[];
    } & Pick<
      NewPrejudiceFormPerteGainProfessionnelsActuel,
      'revalorisationCoefficientsType'
    >)
  | {
      formType: 'SAISIE_DIRECTE';
    }
) &
  Omit<PertesDeGainsProfessionnels, 'annualisation'>): (
  | Table
  | TableOfContents
  | Paragraph
)[] => [
  ...(rows.length > 0
    ? [
        simpleHorizontalTablePrint(
          [
            i18next.t('numeroPiece.form.columnHeader'),
            i18next.t(
              'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.commentaires.columnHeader',
            ),
            ...(props.formType === 'REVENUS'
              ? [
                  i18next.t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.dateDebut.print.periodeColumnHeader',
                  ),
                ]
              : [
                  i18next.t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.rows.dateDebut.periodeColumnHeader',
                  ),
                ]),
            ...(props.formType === 'REVENUS'
              ? [
                  i18next.t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.revenuDeReferencePeriode.columnHeader',
                  ),
                ]
              : [
                  i18next.t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.rows.revenuDeReferencePeriode.columnHeader',
                  ),
                ]),
            ...(props.formType === 'REVENUS'
              ? [
                  i18next.t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.revenuPercuNet.columnHeader',
                  ),
                ]
              : [
                  i18next.t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.rows.revenuPercuNet.columnHeader',
                  ),
                ]),
            i18next.t(
              'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.revenuPercuParPeriode.columnHeader',
            ),
            ...(props.formType === 'REVENUS'
              ? [
                  i18next.t('prejudice.revalorisation.quotient.columnHeader'),
                  i18next.t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.perteDeGainsProfessionnels.print.columnHeader',
                  ),
                ]
              : []),
            i18next.t(
              'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.perteDeGainsProfessionnels.columnHeader',
            ),
          ],
          rows.map(
            ({
              numerosPieces,
              commentaires,
              dateDebut,
              dateFin,
              revenuDeReferencePeriode,
              revenuPercuNet,
              revenuPercuParPeriode,
              unite,
              duree,
              montantsDejaRevalorises,
              perteDeGainsProfessionnels,
            }) => ({
              rowLabel: numerosPieces ? displayNumeroPiece(numerosPieces) : '',
              columns: [
                commentaires || '',
                getPeriodePrint({
                  dateDebut,
                  dateFin,
                  duree,
                }),
                fCurrency(revenuDeReferencePeriode),
                ...(unite
                  ? [
                      i18next.t('prejudice.fields.unite.print.value', {
                        unite,
                        value: fCurrency(revenuPercuNet),
                      }),
                    ]
                  : ['']),
                fCurrency(revenuPercuParPeriode),
                ...(props.formType === 'REVENUS'
                  ? [
                      montantsDejaRevalorises
                        ? ''
                        : getQuotientRevalorisationPrint({
                            anneeOrDateLiquidation:
                              props.dateLiquidation || undefined,
                            anneeOrDateMontant: dateFin || undefined,
                            monetaryErosions: props.monetaryErosions,
                            coefficientsType:
                              props.revalorisationCoefficientsType,
                          }),
                      montantsDejaRevalorises
                        ? ''
                        : getMontantRevalorisePrint({
                            montant: revenuPercuParPeriode,
                            anneeOrDateLiquidation:
                              props.dateLiquidation || undefined,
                            anneeOrDateMontant: dateFin || undefined,
                            monetaryErosions: props.monetaryErosions,
                            montantsDejaRevalorises,
                            coefficientsType:
                              props.revalorisationCoefficientsType,
                          }),
                    ]
                  : []),
                fCurrency(perteDeGainsProfessionnels),
              ],
            }),
          ),
        ),
        getParagraph(
          i18next.t('prejudice.revalorisation.print.footnote') || '',
        ),
        getEmptyLine(),
      ]
    : []),
  getParagraph(
    props.formType === 'REVENUS'
      ? `${i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.total.print.value',
          {
            value: fCurrency(total),
          },
        )}`
      : `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.total.print.value',
          {
            value: fCurrency(total),
          },
        )}`,
  ),
];
