import { Paragraph, Table, TableOfContents } from 'docx';
import {
  NumeroPieceValues,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
  NumberingType,
} from '../../docxFunctions';
import i18next from 'i18next';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';
import { fDate, fDays } from 'src/helpers/formatTime';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { Procedure } from 'src/types/procedure.type';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { CalculsGlobal } from 'src/constants/calculs';
import { displayRowsSumFormula } from '../formula';

export const dfttWrittenPrint = ({
  victime,
  formData,
  procedure,
}: {
  victime: Victime;
  formData: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal;
  procedure: Procedure;
}): (Table | TableOfContents | Paragraph)[] => {
  const nonZeroRows = formData.rows.filter((row) => !!row.montantTotal);
  const montantsTotal = CalculsGlobal.sommeMontantRows(
    formData.rows.map((row) => ({ montant: row.montantTotal })),
  );
  const firstPart = i18next.t(
    'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.writtenPrint.firstPart',
  );
  const periodeFirstPart = i18next.t(
    'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.writtenPrint.periodeFirstPart',
    {
      name: displayVictimeNameWrittenPrint(victime),
      baseJournaliere: fCurrency(formData.forfaitJour ?? 0),
    },
  );
  const total = i18next.t(
    'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.writtenPrint.total',
    {
      fractionIndemnisable: fPartResponsabilite(
        procedure.partResponsabilite * 100,
      ),
      total: fCurrency(montantsTotal),
      montant: fCurrency(montantsTotal * procedure.partResponsabilite),
      formule: displayRowsSumFormula({
        rowValues: formData.rows.map((row) => row.montantTotal),
        options: {
          withParentheses: true,
        },
        editedFieldsParameters: {
          formData,
          fieldName: 'rows',
        },
      }),
    },
  );

  const getCommentaire = (
    numerosPieces?: NumeroPieceValues,
    motifHospitalisation?: string,
  ): string =>
    numerosPieces?.rows[0] && motifHospitalisation
      ? ` (${displayNumerosPiecesWrittenPrint(numerosPieces)} - ${motifHospitalisation})`
      : numerosPieces?.rows[0] || motifHospitalisation
        ? ` (${(!!numerosPieces && displayNumerosPiecesWrittenPrint(numerosPieces)) || motifHospitalisation})`
        : '';

  const getPeriodes = (
    row: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow,
  ) =>
    i18next.t(
      'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.writtenPrint.periode',
      {
        dateDeDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        dateDeFin: row.dateFin ? fDate(row.dateFin) : '',
        totalJours: fDays(row.totalJours),
        commentaire: getCommentaire(
          row.numerosPieces,
          row.motifHospitalisation,
        ),
        baseJournaliere: fCurrency(row.forfaitJour),
        montant: fCurrency(row.montantTotal),
      },
    );

  const recapitulatif = simpleHorizontalTablePrint(
    [
      i18next.t('numeroPiece.form.numeroPiece.label'),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.motifHospitalisation.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.forfaitJour.label',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.totalJours.print.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL.fields.rows.montantTotal.columnHeader',
      ),
    ],
    nonZeroRows.map((row) => ({
      rowLabel: row.numerosPieces.rows[0]
        ? i18next.t('numeroPiece.form.numeroPiece.renderShort', {
            numeroPiece: displayNumeroPiece(row.numerosPieces),
          })
        : '',
      columns: [
        row.motifHospitalisation,
        fCurrency(row.forfaitJour),
        fDays(row.totalJours),
        fCurrency(row.montantTotal),
      ],
    })),
  );
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...(!!nonZeroRows.length && nonZeroRows[0]
      ? [
          getParagraph(firstPart),
          ...(nonZeroRows.length > 1
            ? [
                getParagraph(`${periodeFirstPart} :`),
                getEmptyLine(),
                ...nonZeroRows.flatMap((row) =>
                  getParagraph({
                    numbering: {
                      reference: NumberingType.SIMPLE_LIST,
                      level: 0,
                    },
                    children: getTextRun(getPeriodes(row)),
                  }),
                ),
              ]
            : [
                getParagraph(
                  `${periodeFirstPart} ${getPeriodes(nonZeroRows[0])}`,
                ),
              ]),
          getEmptyLine(),
          getParagraph(total),
          recapitulatif,
          getEmptyLine(),
        ]
      : []),
  ];
};
