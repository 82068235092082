import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
  Tooltip,
  styled,
} from '@mui/material';
import {
  useController,
  UseControllerProps,
  useWatch,
  FieldValues,
  FieldPath,
  FieldPathValues,
  useFormContext,
} from 'react-hook-form';
import { Cancel, Edit } from '@mui/icons-material';
import { SpanNoWrap } from '../styled';
import { uniq } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

/* Styled components */
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-adornedStart': {
    paddingLeft: theme.spacing(1),
  },
}));

/* Component */

// TODO Add hidden field to hide field

interface Props<
  TFieldValues extends FieldValues,
  TFieldNames extends readonly FieldPath<TFieldValues>[],
  TName extends FieldPath<TFieldValues>,
  TCustomValues extends readonly any[],
> extends UseControllerProps<TFieldValues, TName> {
  watchFields: readonly [...TFieldNames];
  customValues?: TCustomValues;
  editedFieldsName?: FieldPath<TFieldValues>;
  compute: (
    values: FieldPathValues<TFieldValues, TFieldNames>,
    customValues?: TCustomValues,
  ) => any;
  computeTooltip?: (
    values: FieldPathValues<TFieldValues, TFieldNames>,
    fieldValue?: any,
    customValues?: TCustomValues,
  ) => ReactNode;
  forcedError?: string;
}

export const ComputedTextFieldForm = <
  TFieldValues extends FieldValues,
  TFieldNames extends readonly FieldPath<TFieldValues>[],
  TName extends FieldPath<TFieldValues>,
  TCustomValues extends readonly any[],
>({
  name,
  control,
  watchFields,
  compute,
  computeTooltip,
  rules,
  shouldUnregister,
  defaultValue,
  customValues,
  editedFieldsName,
  forcedError,
  ...props
}: Props<TFieldValues, TFieldNames, TName, TCustomValues> & TextFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const { setValue, getValues } = useFormContext();
  const [initialized, setInitialized] = useState(false);
  const [manualOverwrite, setManualOverwrite] = useState(false);
  const [iconHovered, setIconHovered] = useState(false);
  const [computeTooltipValue, setComputeTooltipValue] = useState<ReactNode>('');

  const handleUpdateEditedFieldsArray = (isManualOverwrite: boolean) => {
    if (editedFieldsName) {
      let editedFieldsArray = getValues(editedFieldsName);
      if (editedFieldsArray) {
        if (isManualOverwrite) {
          editedFieldsArray = uniq([
            ...editedFieldsArray,
            name,
          ]) as typeof editedFieldsArray;
        } else {
          editedFieldsArray = editedFieldsArray.filter(
            (field: string) => field !== name,
          );
        }
        setValue(editedFieldsName, editedFieldsArray);
      }
    }
  };

  const handleSetManualOverwrite = (value: boolean) => {
    if (value !== manualOverwrite) {
      setManualOverwrite(value);
      handleUpdateEditedFieldsArray(value);
    }
  };

  const watchValues = useWatch({
    control,
    name: watchFields,
  });
  const memoizedCustomValues = useMemo(() => customValues, [customValues]);

  useEffect(() => {
    const computedValue = compute(watchValues, memoizedCustomValues);
    if (computeTooltip) {
      setComputeTooltipValue(
        computeTooltip(watchValues, field.value, memoizedCustomValues),
      );
    }
    const values = getValues();
    const isEdited =
      editedFieldsName && Array.isArray(values[editedFieldsName])
        ? values[editedFieldsName].includes(name)
        : false;
    if (
      computedValue?.toString() !== field.value?.toString() &&
      field.value !== undefined &&
      isEdited
    ) {
      handleSetManualOverwrite(true);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && (!manualOverwrite || field.value === undefined)) {
      const computedValue = compute(watchValues, memoizedCustomValues);
      if (computedValue?.toString() !== field.value?.toString()) {
        field.onChange(computedValue);
      }
      if (computeTooltip) {
        setComputeTooltipValue(
          computeTooltip(watchValues, computedValue, memoizedCustomValues),
        );
      }
    }
  }, [watchValues, initialized, memoizedCustomValues]);

  useEffect(() => {
    if (initialized && manualOverwrite && computeTooltip) {
      setComputeTooltipValue(
        computeTooltip(watchValues, field.value, memoizedCustomValues),
      );
    }
  }, [watchValues, initialized, field.value, memoizedCustomValues]);

  const handleManualOverwrite = useDebouncedCallback(() => {
    if (!manualOverwrite) {
      handleSetManualOverwrite(true);
    }
  });

  const onChangeCallback = useDebouncedCallback((value: any) =>
    field.onChange(value),
  );

  return (
    <Tooltip
      title={forcedError || error?.message ? '' : computeTooltipValue}
      placement="top"
    >
      <SpanNoWrap>
        <Tooltip title={forcedError || error?.message || ''}>
          <StyledTextField
            {...field}
            {...props}
            error={!!forcedError || !!error}
            onChange={onChangeCallback}
            onInput={handleManualOverwrite}
            InputProps={{
              ...props.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    title={
                      manualOverwrite
                        ? 'Vous avez renseigné ce champ manuellement. Supprimer ?'
                        : ''
                    }
                    arrow
                  >
                    <IconButton
                      style={{
                        visibility: !manualOverwrite ? 'hidden' : 'visible',
                      }}
                      size="small"
                      edge="start"
                      color="primary"
                      onClick={() => {
                        if (manualOverwrite) {
                          handleSetManualOverwrite(false);
                          setIconHovered(false);
                          field.onChange(compute(watchValues, customValues));
                          if (computeTooltip) {
                            setComputeTooltipValue(
                              computeTooltip(
                                watchValues,
                                undefined,
                                customValues,
                              ),
                            );
                          }
                        }
                      }}
                      onMouseEnter={() => {
                        if (manualOverwrite) {
                          setIconHovered(true);
                        }
                      }}
                      onMouseLeave={() => {
                        if (manualOverwrite) {
                          setIconHovered(false);
                        }
                      }}
                    >
                      {iconHovered ? <Cancel /> : <Edit />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              sx: {
                ...props.InputProps?.sx,
                ...(manualOverwrite && { color: 'primary.main' }),
              },
            }}
          />
        </Tooltip>
      </SpanNoWrap>
    </Tooltip>
  );
};
