import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Box, Stack, Tabs } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import {
  getCapitalisationBaremes,
  getShouldNotDisplayCapitalisation,
} from '../../../helpers/prejudices/capitalisation';
import { useTranslation } from 'react-i18next';
import { intersection } from 'lodash';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnelsRow } from './NewFormPerteGainsProfessionnelsActuel';
import {
  getDefaultMinAndMaxDate,
  validationSchemaCapitalisation,
  validationSchemaPrejudiceBase,
  validationSchemaPrejudiceFormCapitalisationOrRenteBase,
  validationSchemaTiersPayeursCapitalisation,
} from 'src/constants/prejudice/validation';
import { PrejudiceTab } from '../PrejudiceTab';
import { TotalCapitalisation } from '../prejudiceFormComponents/Capitalisation/TotalCapitalisation';
import {
  PGPASaisieDirecteDefaultValues,
  validationSchemaPerteGainsProfessionnelsActuelSaisieDirecte,
} from './FormPerteGainsProfessionnelsActuelSaisieDirecte';
import { StepManagerSaisieDirecte } from '../prejudiceFormComponents/perteGainsProfessionnelsActuel/StepManagerSaisieDirecte';
import { TotalPerteGainsFutursSaisieDirecte } from '../prejudiceFormComponents/perteGainsProfessionnelsActuel/TotalPerteGainsFutursSaisieDirecte';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { validationSchemaNewFormPerteGainsProfessionnelsFutursPerteDeGainsProfessionnels } from './NewFormPerteGainsProfessionnelsFuturs';
import { CalculsFormNewPerteGainsProfessionnelsFuturs } from 'src/constants/calculs';
import { SavePrejudiceButton } from '../SavePrejudiceButton';

export const validationSchemaNewFormPerteGainsProfessionnelsFuturs = ({
  procedure,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  return validationSchemaPrejudiceBase
    .concat(validationSchemaPrejudiceFormCapitalisationOrRenteBase)
    .concat(
      validationSchemaPerteGainsProfessionnelsActuelSaisieDirecte({
        victime,
        procedure,
        prejudiceType,
      }),
    )
    .shape({
      perteDeGainsProfessionnels:
        validationSchemaNewFormPerteGainsProfessionnelsFutursPerteDeGainsProfessionnels(
          {
            victime,
            procedure,
            prejudiceType,
          },
        ).shape({
          rows: yup
            .array()
            .defined()
            .of(
              validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnelsRow(
                {
                  victime,
                  procedure,
                  prejudiceType,
                  isPGPF: true,
                },
              ).shape({
                dateDebut: yup
                  .string()
                  .isDateString()
                  .optional()
                  .nullable()
                  .defined()
                  .minDate(undefined, minDate.message, minDate.date)
                  .maxDate(undefined, maxDate.message, maxDate.date),
              }),
            ),
        }),
      capitalisation: validationSchemaCapitalisation.shape({
        sommeACapitaliser: yup.number().defined(),
      }),
      capitalisationTiersPayeurs: validationSchemaTiersPayeursCapitalisation,
      PGPFReliquat: yup.number().nullable().defined(),
      activatePGPFReliquat: yup.boolean().nullable().defined(),
      totalCapitalisation: yup.number().defined(),
    });
};

export const pgpfSaisieDirecteDefaultValues = ({
  values,
  procedure,
  prejudiceType,
}: {
  values: PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte | undefined;
  procedure: Procedure;
  prejudiceType: PrejudiceType;
}): PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte => ({
  ...PGPASaisieDirecteDefaultValues({ values, prejudiceType }),
  perteDeGainsProfessionnels: {
    ...PGPASaisieDirecteDefaultValues({ values, prejudiceType })
      .perteDeGainsProfessionnels,
    annualisation: values?.perteDeGainsProfessionnels?.annualisation || 0,
  },
  capitalisation: {
    sommeACapitaliser: values?.capitalisation.sommeACapitaliser || 0,
    coefficient: values?.capitalisation.coefficient || 0,
    isLastArrerageViager: values?.capitalisation.isLastArrerageViager || false,
    ageDernierArrerage: values?.capitalisation.ageDernierArrerage || null,
    bareme: values?.capitalisation.bareme || '',
    montantCapitalise: values?.capitalisation.montantCapitalise || 0,
    ageDateAttribution: values?.capitalisation.ageDateAttribution,
    enableCapitalisationDifferee:
      values?.capitalisation.enableCapitalisationDifferee ?? false,
  },
  isRentesOption: values?.isRentesOption || false,
  rentes: {
    montant: values?.rentes?.montant || 0,
  },
  capitalisationTiersPayeurs: {
    parTiersPayeur: procedure.tiersPayeurs.map((tiersPayeur) => ({
      tiersPayeur,
      sommeACapitaliser: 0,
      coefficient: 0,
      montantCapitalise: 0,
      ...values?.capitalisationTiersPayeurs?.parTiersPayeur?.find(
        (row) => row.tiersPayeur === tiersPayeur,
      ),
    })),
    montantCapitalise:
      values?.capitalisationTiersPayeurs?.montantCapitalise || 0,
  },
  PGPFReliquat: values?.PGPFReliquat || 0,
  activatePGPFReliquat: values?.activatePGPFReliquat || false,
  totalCapitalisation: values?.totalCapitalisation || 0,
});

interface Props
  extends PrejudiceFormProps<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte> {
  tauxIPP: number | null;
}

export const FormPerteGainsProfessionnelsFutursSaisieDirecte: React.FC<
  Props
> = ({
  victime,
  procedure,
  dommages,
  values,
  baremes,
  dateLiquidation,
  monetaryErosions,
  onPrevious,
  dateConsolidation,
  tauxIPP,
  allNumerosPieces,
  dateDeces,
  ...props
}) => {
  const [activeTab, setActiveTabs] = useState(0);
  const { t } = useTranslation();

  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, victime.sexe),
    [baremes, victime.sexe],
  );
  const useFormReturn =
    useForm<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte>({
      defaultValues: pgpfSaisieDirecteDefaultValues({
        values,
        procedure,
        prejudiceType: props.prejudiceType,
      }),
      resolver: yupResolver(
        validationSchemaNewFormPerteGainsProfessionnelsFuturs({
          procedure,
          victime,
          prejudiceType: props.prejudiceType,
        }),
      ),
    });
  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
    getValues,
  } = useFormReturn;

  const isFirstTabError = useMemo(() => {
    const firstTabFields: (keyof PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte)[] =
      ['revenuEspere', 'perteDeGainsProfessionnels'];
    return (
      intersection(Object.keys(errors), firstTabFields).length > 0 &&
      activeTab !== 0
    );
  }, [errors, activeTab]);

  const isSecondTabError = useMemo(() => {
    const secondTabFields: (keyof PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte)[] =
      ['capitalisation', 'rentes'];
    return (
      intersection(Object.keys(errors), secondTabFields).length > 0 &&
      activeTab !== 1
    );
  }, [errors, activeTab]);

  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation,
    dateLiquidation,
    dateDeces,
  });

  return (
    <>
      <PrejudiceContainer<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte>
        {...props}
        victime={victime}
        procedure={procedure}
        monetaryErosions={monetaryErosions}
        baremes={baremes}
        {...useFormReturn}
        renderPrejudice={({ setAdditionalTitle }) => (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <TextFieldForm
                control={control}
                name="notes"
                label={t('prejudice.fields.notes.label')}
                placeholder={t('prejudice.fields.notes.placeholder') || ''}
                InputLabelProps={{ shrink: true }}
                multiline
                maxRows={4}
                fullWidth
              />
              <Tabs
                value={activeTab}
                onChange={(_, value) => setActiveTabs(value)}
              >
                <PrejudiceTab
                  label={t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.PGPF',
                    {
                      context: victime.dateDeces ? 'deces' : undefined,
                    },
                  )}
                  value={0}
                  isError={isFirstTabError}
                />
                {!shouldNotDisplayCapitalisation ? (
                  <PrejudiceTab
                    label={t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.capitalisation',
                      {
                        context: victime.dateDeces ? 'deces' : undefined,
                      },
                    )}
                    value={1}
                    isError={isSecondTabError}
                  />
                ) : null}
                <PrejudiceTab
                  label={t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tab.total',
                  )}
                  value={2}
                />
              </Tabs>
            </Box>
            <Stack
              direction="column"
              spacing={4}
              sx={{ display: activeTab === 0 ? '' : 'none' }}
            >
              <StepManagerSaisieDirecte
                setAdditionalTitle={setAdditionalTitle}
                procedure={procedure}
                dommage={dommages?.[0]}
                prejudiceType={props.prejudiceType}
                victime={victime}
                control={control}
                trigger={trigger}
                values={values}
                getValues={getValues}
                onClose={props.onClose}
                monetaryErosions={monetaryErosions}
                onPrevious={onPrevious ? onPrevious : undefined}
                handleSubmit={handleSubmit}
                isPGPF
                dateConsolidation={dateConsolidation}
                dateLiquidation={dateLiquidation}
                tauxIPP={tauxIPP}
                allNumerosPieces={allNumerosPieces}
                user={props.user}
              />
            </Stack>
            {!shouldNotDisplayCapitalisation ? (
              <Stack
                direction="column"
                sx={{ display: activeTab === 1 ? '' : 'none' }}
                spacing={2}
              >
                <TotalCapitalisation
                  control={control}
                  victimeName={{
                    sommeACapitaliser: 'capitalisation.sommeACapitaliser',
                    bareme: 'capitalisation.bareme',
                    coefficient: 'capitalisation.coefficient',
                    montantCapitalise: 'capitalisation.montantCapitalise',
                    isLastArrerageViager: 'capitalisation.isLastArrerageViager',
                    ageDernierArrerage: 'capitalisation.ageDernierArrerage',
                    ageDateAttribution: 'capitalisation.ageDateAttribution',
                    enableCapitalisationDifferee:
                      'capitalisation.enableCapitalisationDifferee',
                  }}
                  tiersPayeursName={{
                    root: 'capitalisationTiersPayeurs.parTiersPayeur',
                    montantCapitalise:
                      'capitalisationTiersPayeurs.montantCapitalise',
                  }}
                  tiersPayeurSuffix={{
                    sommeACapitaliser: 'sommeACapitaliser',
                    montantCapitalise: 'montantCapitalise',
                    coefficient: 'coefficient',
                  }}
                  procedure={procedure}
                  dateLiquidation={
                    dateLiquidation ? new Date(dateLiquidation) : null
                  }
                  baremes={capitalisationBaremes}
                  dateNaissance={
                    victime.dateNaissance
                      ? new Date(victime.dateNaissance)
                      : null
                  }
                  dateDeces={
                    victime.dateDeces ? new Date(victime.dateDeces) : null
                  }
                  sexe={victime.sexe}
                  defaultValuesNames={{
                    victimeSommeACapitaliser:
                      'perteDeGainsProfessionnels.annualisation',
                  }}
                  editable={{
                    victime: {
                      montantCapitalise: true,
                      ageDateAttribution: true,
                    },
                  }}
                  titles={{
                    victime: {
                      sommeACapitaliser: t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.sommeACapitaliser.title',
                      ),
                    },
                  }}
                  totalName="totalCapitalisation"
                  editedFieldsName="editedFields"
                />
                <SavePrejudiceButton
                  sx={{ alignSelf: 'center' }}
                  victime={victime}
                />
              </Stack>
            ) : null}
            <Stack sx={{ display: activeTab === 2 ? '' : 'none' }} spacing={4}>
              <TotalPerteGainsFutursSaisieDirecte
                control={control}
                procedure={procedure}
                dateConsolidation={dateConsolidation}
                dateLiquidation={dateLiquidation}
                dateDeces={dateDeces}
              />
              <SavePrejudiceButton
                sx={{ alignSelf: 'center' }}
                victime={victime}
              />
            </Stack>
            <ComputedReadFieldForm
              control={control}
              name="perteDeGainsProfessionnels.annualisation"
              watchFields={['perteDeGainsProfessionnels.rows', 'editedFields']}
              compute={([rows, editedFields]) =>
                CalculsFormNewPerteGainsProfessionnelsFuturs.getPerteDeGainAnnualisee(
                  {
                    rows,
                    editedFields,
                  },
                ) || 0
              }
            />
          </>
        )}
      />
    </>
  );
};
