import {
  CalculsFormCalendrierAssistance,
  CalculsFormCalendrierDeficitFonctionnelTemporairePartiel,
  CalculsFormCalendrierDeficitFonctionnelTemporaireTotal,
  CalculsFormCalendrierDepense,
  CalculsFormCapitalisation,
  CalculsFormListeProjection,
  CalculsFormNewPerteGainsProfessionnelsActuels,
  CalculsFormNewPerteGainsProfessionnelsFuturs,
  CalculsFormPerteGainsProfessionnelsActuel,
  CalculsFormPerteGainsProfessionnelsFuturs,
  CalculsGlobal,
} from 'src/constants/calculs';

import { prejudicesData } from 'src/constants/prejudices';
import {
  Prejudice,
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierValeur,
  PrejudiceFormListeProjection,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  PrejudiceFormValeur,
  PrejudiceFormValeurVictimesIndirectes,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormMateriel,
  PrejudiceType,
  ListeProjectionIncidenceProfessionelle,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  PrejudiceFormScolaire,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { fCurrency } from '../formatNumber';
import i18n from 'i18next';
import { isPrejudiceVictimeIndirecte } from './prejudice';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getPGPFReliquatAndPGPFReliquatActivationStatus } from './pgpfReliquat';
import i18next from 'i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { CalculPerteRevenusProche } from 'src/constants/calculs/calculPerteRevenusProche';

export const prejudiceTotalNames = [
  'indemniteProche',
  'indemniteVictimeEchue',
  'indemniteVictimeAEchoir',
  'indemniteTiersPayeurEchue',
  'indemniteTiersPayeurAEchoir',
  'indemnitePGPFPerteDeChanceEchue',
  'indemnitePGPFPerteDeChanceAEchoir',
  'rentes',
] as const;

export type PrejudiceTotalName = (typeof prejudiceTotalNames)[number];

export const prejduiceTotalNamesAddedTotal = prejudiceTotalNames.filter(
  (prejudiceTotalName) => prejudiceTotalName !== 'rentes',
);

export interface PrejudiceTotalValue {
  otherAmounts?: { name: string; amount: number }[];
  subAmounts?: { name: string; amount: number }[];
  amount: number;
  name: PrejudiceTotalName;
  customLabel?: string;
}

export interface PrejudicesTotalAmount {
  victime: number;
  tiersPayeurs: number | null;
  proche: number | null;
  rentes: number | null;
  total: number;
}

export type FormattedPrejudiceTotal = {
  fieldName: string;
  fieldValue: string;
}[];

export const getTotalValuesSum = (values: PrejudiceTotalValue[]) =>
  CalculsGlobal.sum(
    values.map(
      ({ amount, otherAmounts }) =>
        amount +
        (CalculsGlobal.sum(otherAmounts?.map(({ amount }) => amount) || []) ||
          0),
    ),
  );

export const getSignedPrejudiceAmount = (
  amount: number,
  prejudiceType: PrejudiceType,
) =>
  Number(
    prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES']
      ?.isValueSubstractedFromTotal
      ? -amount
      : amount,
  ) || 0;

export const getPrejudiceTotal = ({
  victime,
  victimesIndirectes,
  procedure,
  prejudice,
  dateConsolidation,
  dateLiquidation,
  monetaryErosions,
  PGPFReliquat,
  dateDeces,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudice: Pick<Prejudice, 'type' | 'formType' | 'formData'>;
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  dateDeces: Date | undefined;
}): PrejudiceTotalValue[] => {
  try {
    const prejudiceData =
      prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES'];
    switch (prejudice.formType) {
      case 'VALEUR': {
        const formData = prejudice.formData as PrejudiceFormValeur;
        const partResponsabilite =
          formData.partResponsabilite || formData.partResponsabilite === 0
            ? formData.partResponsabilite
            : procedure.partResponsabilite;
        let amount = getSignedPrejudiceAmount(formData.montant, prejudice.type);
        if (prejudiceData.displayTotalPartResponsabilite) {
          amount = amount * partResponsabilite;
        }
        return [
          {
            amount,
            name: 'indemniteVictimeEchue',
          },
        ];
      }
      case 'CALENDRIER_ASSISTANCE': {
        const formData = prejudice.formData as
          | PrejudiceFormCalendrierAssistance
          | PrejudiceFormCalendrierAssistanceCapitalisation;
        const totaux = CalculsFormCalendrierAssistance.totaux({
          rows: formData.rows,
          partResponsabilite: procedure.partResponsabilite,
          tiersPayeurs: procedure.tiersPayeurs,
          dateLiquidation,
          monetaryErosions,
          revalorisationCoefficientsType:
            formData.revalorisationCoefficientsType,
        });
        const indemnitesRepartie = CalculsGlobal.getIndemnitesRepartie(
          totaux.resteACharge.total.value,
          Object.entries(totaux.priseEnChargeTiersPayeurs).reduce(
            (
              accumulator,
              [
                tiersPayeur,
                {
                  total: { value },
                },
              ],
            ) => ({
              ...accumulator,
              [tiersPayeur]: value,
            }),
            {},
          ),
          procedure.partResponsabilite,
        );
        return [
          {
            amount: getSignedPrejudiceAmount(
              indemnitesRepartie.indemniteVictime.arreragesEchus.debit,
              prejudice.type,
            ),
            name: 'indemniteVictimeEchue',
          },
          {
            amount: getSignedPrejudiceAmount(
              indemnitesRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
              prejudice.type,
            ),
            name: 'indemniteTiersPayeurEchue',
          },
        ];
      }
      case 'CALENDRIER_DEPENSE': {
        const formData = prejudice.formData as PrejudiceFormCalendrierDepense;
        const isVictimeIndirecte = isPrejudiceVictimeIndirecte(prejudice.type);
        if (!isVictimeIndirecte) {
          const indemnitesRepartie =
            CalculsFormCalendrierDepense.getIndemniteRepartieEchus({
              rows: formData.rows,
              partResponsabilite: procedure.partResponsabilite,
              tiersPayeurs: procedure.tiersPayeurs,
              dateLiquidation,
              monetaryErosions,
              revalorisationCoefficientsType:
                formData.revalorisationCoefficientsType,
            });
          return [
            {
              amount: getSignedPrejudiceAmount(
                indemnitesRepartie.indemniteVictime.arreragesEchus.debit,
                prejudice.type,
              ),
              name: 'indemniteVictimeEchue',
            },
            {
              amount: getSignedPrejudiceAmount(
                indemnitesRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
                prejudice.type,
              ),
              name: 'indemniteTiersPayeurEchue',
            },
          ];
        } else {
          const partResponsabilite =
            formData.partResponsabilite || formData.partResponsabilite === 0
              ? formData.partResponsabilite
              : procedure.partResponsabilite;
          const indemnitesRepartie =
            CalculsFormCalendrierDepense.getIndemniteRepartieEchusIndirecte({
              rows: formData.rows,
              partResponsabilite,
              tiersPayeurs: procedure.tiersPayeurs,
              dateLiquidation,
              monetaryErosions,
              revalorisationCoefficientsType:
                formData.revalorisationCoefficientsType,
            });
          return [
            {
              amount: getSignedPrejudiceAmount(
                indemnitesRepartie.indemniteProche.arreragesEchus.debit,
                prejudice.type,
              ),
              name: 'indemniteProche',
              subAmounts:
                isVictimeIndirecte && indemnitesRepartie.indemniteProche
                  ? Object.entries(
                      indemnitesRepartie.indemniteProche.arreragesEchus
                        .parVictimeIndirecte,
                    ).reduce(
                      (
                        accumulator: {
                          name: string;
                          amount: number;
                        }[],
                        [victimeIndirecteId, montant],
                      ) => {
                        const victimeIndirecte = victimesIndirectes.find(
                          ({ _id }) => _id === victimeIndirecteId,
                        );
                        if (victimeIndirecte) {
                          accumulator.push({
                            name: i18next.t(
                              'victimeIndirecte.displayLienVictimeLong',
                              {
                                lienVictime: victimeIndirecte.lienVictime,
                                nom: victimeIndirecte.nom,
                                prenom: victimeIndirecte.prenom,
                              },
                            ),
                            amount: montant,
                          });
                        }
                        return accumulator;
                      },
                      [],
                    )
                  : undefined,
            },
            {
              amount: getSignedPrejudiceAmount(
                indemnitesRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
                prejudice.type,
              ),
              name: 'indemniteTiersPayeurEchue',
            },
          ];
        }
      }
      case 'LISTE_PROJECTION': {
        const formData = prejudice.formData as PrejudiceFormListeProjection;

        if (
          formData.enableProrataTemporisDeces &&
          (!procedure?.dateConsolidation || !victime.dateDeces)
        ) {
          return [];
        }
        if (prejudice.type === 'INCIDENCE_PROFESSIONNELLE') {
          const indemnite =
            CalculsFormListeProjection.getIncidenceProfessionnelleIndemniteRepartie(
              {
                PGPFReliquat,
                partResponsabilite: procedure.partResponsabilite,
                incidenceProfessionnelle:
                  formData.prejudiceValues as ListeProjectionIncidenceProfessionelle,
              },
            );
          return [
            {
              amount: getSignedPrejudiceAmount(
                indemnite.indemniteVictime.arreragesEchus.debit || 0,
                prejudice.type,
              ),
              name: 'indemniteVictimeEchue',
            },
            {
              amount: getSignedPrejudiceAmount(
                indemnite.indemniteVictime.arreragesAEchoir.debit || 0,
                prejudice.type,
              ),
              name: 'indemniteVictimeAEchoir',
            },
            ...(PGPFReliquat && PGPFReliquat.activatePGPFReliquat
              ? ([
                  {
                    amount: getSignedPrejudiceAmount(
                      indemnite.indemniteTiersPayeurs.arreragesEchus.debit || 0,
                      prejudice.type,
                    ),
                    name: 'indemniteTiersPayeurEchue',
                    // TODO Change this when we separate Trop percu echu and a echoir and we separate IP TP echu and a echoir
                    customLabel: i18next.t(
                      'prejudice.total.totalNames.long.actuel.indemniteTiersPayeurEchue',
                    ),
                  },
                ] as PrejudiceTotalValue[])
              : []),
          ];
        }
        let amount = formData.enableProrataTemporisDeces
          ? formData.montantProratise
          : formData.montantTotal;
        if (
          prejudiceData.displayTotalPartResponsabilite &&
          !formData.enableProrataTemporisDeces
        ) {
          amount = (amount || 0) * procedure.partResponsabilite;
        }
        return [
          {
            amount: getSignedPrejudiceAmount(amount || 0, prejudice.type),
            name: 'indemniteVictimeEchue',
          },
        ];
      }
      case 'CALENDRIER_ASSISTANCE_CAPITALISATION': {
        const formData =
          prejudice.formData as PrejudiceFormCalendrierAssistanceCapitalisation;
        const indemnitesRepartie =
          CalculsFormCalendrierAssistance.getAssistanceIndemnitesRepartie({
            ...formData,
            partResponsabilite: procedure.partResponsabilite,
            tiersPayeurs: procedure.tiersPayeurs,
            dateLiquidation,
            monetaryErosions,
            dateConsolidation,
            dateDeces,
          });
        return [
          {
            amount: getSignedPrejudiceAmount(
              indemnitesRepartie.indemniteVictime.arreragesEchus.debit,
              prejudice.type,
            ),
            name: 'indemniteVictimeEchue',
          },
          ...(!formData.isRentesOption
            ? ([
                {
                  amount: getSignedPrejudiceAmount(
                    indemnitesRepartie.indemniteVictime.arreragesAEchoir.debit,
                    prejudice.type,
                  ),
                  name: 'indemniteVictimeAEchoir',
                },
              ] as PrejudiceTotalValue[])
            : []),
          {
            amount: getSignedPrejudiceAmount(
              indemnitesRepartie.indemniteTiersPayeurs.arreragesEchus.debit *
                procedure.partResponsabilite,
              prejudice.type,
            ),
            name: 'indemniteTiersPayeurEchue',
          },
          ...(!formData.isRentesOption
            ? ([
                {
                  amount: getSignedPrejudiceAmount(
                    indemnitesRepartie.indemniteTiersPayeurs.arreragesAEchoir
                      ?.debit || 0,
                    prejudice.type,
                  ),
                  name: 'indemniteTiersPayeurAEchoir',
                },
              ] as PrejudiceTotalValue[])
            : []),
          ...(formData.isRentesOption &&
          (formData.rentes?.montant || formData.rentes?.montant === 0)
            ? ([
                {
                  amount: getSignedPrejudiceAmount(
                    formData.rentes.montant,
                    prejudice.type,
                  ),
                  name: 'rentes',
                },
              ] as PrejudiceTotalValue[])
            : []),
        ];
      }
      case 'CALENDRIER_DEPENSE_CAPITALISATION': {
        const formData =
          prejudice.formData as PrejudiceFormCalendrierDepenseCapitalisation;
        const indemnitesRepartie =
          CalculsFormCapitalisation.getIndemnitesRepartie({
            rows: formData.rows,
            capitalisation: formData.capitalisation,
            capitalisationTiersPayeurs: formData.capitalisationTiersPayeurs,
            dateLiquidation,
            monetaryErosions,
            revalorisationCoefficientsType:
              formData.revalorisationCoefficientsType,
            tiersPayeurs: procedure.tiersPayeurs,
            partResponsabilite: procedure.partResponsabilite,
            dateConsolidation,
            dateDeces,
          });
        return [
          {
            amount: getSignedPrejudiceAmount(
              indemnitesRepartie.indemniteVictime.arreragesEchus.debit,
              prejudice.type,
            ),
            name: 'indemniteVictimeEchue',
          },
          ...(!formData.isRentesOption
            ? ([
                {
                  amount: getSignedPrejudiceAmount(
                    indemnitesRepartie.indemniteVictime.arreragesAEchoir.debit,
                    prejudice.type,
                  ),
                  name: 'indemniteVictimeAEchoir',
                },
              ] as PrejudiceTotalValue[])
            : []),
          {
            amount: getSignedPrejudiceAmount(
              indemnitesRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
              prejudice.type,
            ),
            name: 'indemniteTiersPayeurEchue',
          },
          ...(!formData.isRentesOption
            ? ([
                {
                  amount: getSignedPrejudiceAmount(
                    indemnitesRepartie.indemniteTiersPayeurs.arreragesAEchoir
                      ?.debit || 0,
                    prejudice.type,
                  ),
                  name: 'indemniteTiersPayeurAEchoir',
                },
              ] as PrejudiceTotalValue[])
            : []),
          ...(formData.isRentesOption &&
          (formData.rentes?.montant || formData.rentes?.montant === 0)
            ? ([
                {
                  amount: getSignedPrejudiceAmount(
                    formData.rentes?.montant,
                    prejudice.type,
                  ),
                  name: 'rentes',
                },
              ] as PrejudiceTotalValue[])
            : []),
        ];
      }

      case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL': {
        const formData = prejudice.formData as
          | OldPrejudiceFormPerteGainProfessionnelsActuel
          | NewPrejudiceFormPerteGainProfessionnelsActuel
          | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
        switch (formData.formType) {
          case 'DECLARATION_FISCALES': {
            const formData =
              prejudice.formData as OldPrejudiceFormPerteGainProfessionnelsActuel;
            const indemniteRepartie =
              CalculsFormPerteGainsProfessionnelsActuel.getIndemnitesRepartie(
                formData.situations,
                procedure.tiersPayeurs,
                procedure.partResponsabilite,
              );
            return [
              {
                amount: getSignedPrejudiceAmount(
                  indemniteRepartie.indemniteVictime.arreragesEchus.debit,
                  prejudice.type,
                ),
                name: 'indemniteVictimeEchue',
              },
              {
                amount: getSignedPrejudiceAmount(
                  indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
                  prejudice.type,
                ),
                name: 'indemniteTiersPayeurEchue',
              },
            ];
          }
          case 'REVENUS': {
            const formData =
              prejudice.formData as NewPrejudiceFormPerteGainProfessionnelsActuel;
            const values =
              CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie(
                {
                  perteGainProfessionnelsTotal:
                    formData.perteDeGainsProfessionnels.total,
                  indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .csgRdsTotal,
                  indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .indemnitesJournalieresPercuesNet,
                  indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .rows,
                  partResponsabilite: procedure.partResponsabilite,
                },
              );
            return [
              {
                amount: values.indemniteVictime.arreragesEchus.debit,
                name: 'indemniteVictimeEchue',
              },
              {
                amount: values.indemniteTiersPayeurs.arreragesEchus.debit,
                name: 'indemniteTiersPayeurEchue',
              },
            ];
          }
          case 'SAISIE_DIRECTE': {
            const formData =
              prejudice.formData as PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
            const values =
              CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie(
                {
                  indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .csgRdsTotal,
                  indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .indemnitesJournalieresPercuesNet,
                  indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .rows,
                  perteGainProfessionnelsTotal:
                    formData.perteDeGainsProfessionnels.total,
                  partResponsabilite: procedure.partResponsabilite,
                },
              );
            return [
              {
                amount: values.indemniteVictime.arreragesEchus.debit,
                name: 'indemniteVictimeEchue',
              },
              {
                amount: values.indemniteTiersPayeurs.arreragesEchus.debit,
                name: 'indemniteTiersPayeurEchue',
              },
            ];
          }
          default:
            return [];
        }
      }
      case 'PERTE_GAINS_PROFESSIONNELS_FUTURS': {
        const formData = prejudice.formData as
          | OldPrejudiceFormPerteGainProfessionnelsFuturs
          | NewPrejudiceFormPerteGainProfessionnelsFuturs
          | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
        switch (formData.formType) {
          case 'DECLARATION_FISCALES': {
            const formData =
              prejudice.formData as OldPrejudiceFormPerteGainProfessionnelsFuturs;
            const indemniteRepartie =
              CalculsFormPerteGainsProfessionnelsFuturs.getIndemnitesRepartie({
                situations: formData.situations,
                victimeTotalCapitalise: formData.victimeTotalCapitalise,
                tiersPayeursTotalCapitalise:
                  formData.tiersPayeursTotalCapitalise,
                partResponsabilite: procedure.partResponsabilite,
                tiersPayeurs: procedure.tiersPayeurs,
                capitalisationTiersPayeurs: formData.capitalisationTiersPayeurs,
                dateConsolidation,
                dateLiquidation,
                dateDeces,
              });
            const prejudiceTotalValues: PrejudiceTotalValue[] = [
              {
                amount: getSignedPrejudiceAmount(
                  indemniteRepartie.indemniteVictime.arreragesEchus.debit,
                  prejudice.type,
                ),
                name: 'indemniteVictimeEchue',
              },
              ...(!formData.isRentesOption
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        indemniteRepartie.indemniteVictime.arreragesAEchoir
                          .debit,
                        prejudice.type,
                      ),
                      name: 'indemniteVictimeAEchoir',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
              {
                amount: getSignedPrejudiceAmount(
                  indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
                  prejudice.type,
                ),
                name: 'indemniteTiersPayeurEchue',
              },
              ...(!formData.isRentesOption
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        indemniteRepartie.indemniteTiersPayeurs.arreragesAEchoir
                          ?.debit || 0,
                        prejudice.type,
                      ),
                      name: 'indemniteTiersPayeurAEchoir',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
              ...(formData.isRentesOption &&
              (formData.rentes?.montant || formData.rentes?.montant === 0)
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        formData.rentes.montant,
                        prejudice.type,
                      ),
                      name: 'rentes',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
            ];
            return prejudiceTotalValues;
          }
          case 'REVENUS': {
            const formData =
              prejudice.formData as NewPrejudiceFormPerteGainProfessionnelsFuturs;
            const values =
              CalculsFormNewPerteGainsProfessionnelsFuturs.getIndemnitesRepartie(
                {
                  perteGainProfessionnelsTotal:
                    formData.perteDeGainsProfessionnels.total,
                  partResponsabilite: procedure.partResponsabilite,
                  indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .rows,
                  renteCapitalisee: formData.renteCapitalisee,
                  tiersPayeursTotalCapitalise:
                    formData.tiersPayeursTotalCapitalise,
                  indemnitesJournalieresPercuesNet:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .indemnitesJournalieresPercuesNet,
                  capitalisationTiersPayeurs:
                    formData.capitalisationTiersPayeurs,
                  tiersPayeurs: procedure.tiersPayeurs,
                  dateConsolidation,
                  dateLiquidation,
                  dateDeces,
                },
              );
            const prejudiceTotalValues: PrejudiceTotalValue[] = [
              {
                amount: getSignedPrejudiceAmount(
                  values.indemniteVictime.arreragesEchus.debit,
                  prejudice.type,
                ),
                name: 'indemniteVictimeEchue',
              },
              ...(!formData.isRentesOption
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        values.indemniteVictime.arreragesAEchoir.debit,
                        prejudice.type,
                      ),
                      name: 'indemniteVictimeAEchoir',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
              {
                amount: getSignedPrejudiceAmount(
                  values.indemniteTiersPayeurs.arreragesEchus.debit,
                  prejudice.type,
                ),
                name: 'indemniteTiersPayeurEchue',
              },
              ...(!formData.isRentesOption
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        values.indemniteTiersPayeurs.arreragesAEchoir?.debit ||
                          0,
                        prejudice.type,
                      ),
                      name: 'indemniteTiersPayeurAEchoir',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
              ...(formData.isRentesOption &&
              (formData.rentes?.montant || formData.rentes?.montant === 0)
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        formData.rentes.montant,
                        prejudice.type,
                      ),
                      name: 'rentes',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
            ];
            return prejudiceTotalValues;
          }
          case 'SAISIE_DIRECTE': {
            const formData =
              prejudice.formData as PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
            const values =
              CalculsFormNewPerteGainsProfessionnelsFuturs.getIndemnitesRepartie(
                {
                  perteGainProfessionnelsTotal:
                    formData.perteDeGainsProfessionnels.total,
                  partResponsabilite: procedure.partResponsabilite,
                  renteCapitalisee: formData.capitalisation.montantCapitalise,
                  capitalisationTiersPayeurs:
                    formData.capitalisationTiersPayeurs,
                  indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .rows,
                  indemnitesJournalieresPercuesNet:
                    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
                      .indemnitesJournalieresPercuesNet,
                  tiersPayeursTotalCapitalise:
                    formData.capitalisationTiersPayeurs.montantCapitalise,
                  tiersPayeurs: procedure.tiersPayeurs,
                  dateConsolidation,
                  dateLiquidation,
                  dateDeces,
                },
              );
            const prejudiceTotalValues: PrejudiceTotalValue[] = [
              {
                amount: getSignedPrejudiceAmount(
                  values.indemniteVictime.arreragesEchus.debit,
                  prejudice.type,
                ),
                name: 'indemniteVictimeEchue',
              },
              ...(!formData.isRentesOption
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        values.indemniteVictime.arreragesAEchoir.debit,
                        prejudice.type,
                      ),
                      name: 'indemniteVictimeAEchoir',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
              {
                amount: getSignedPrejudiceAmount(
                  values.indemniteTiersPayeurs.arreragesEchus.debit,
                  prejudice.type,
                ),
                name: 'indemniteTiersPayeurEchue',
              },
              ...(!formData.isRentesOption
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        values.indemniteTiersPayeurs.arreragesAEchoir?.debit ||
                          0,
                        prejudice.type,
                      ),
                      name: 'indemniteTiersPayeurAEchoir',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
              ...(formData.isRentesOption &&
              (formData.rentes?.montant || formData.rentes?.montant === 0)
                ? ([
                    {
                      amount: getSignedPrejudiceAmount(
                        formData.rentes.montant,
                        prejudice.type,
                      ),
                      name: 'rentes',
                    },
                  ] as PrejudiceTotalValue[])
                : []),
            ];
            return prejudiceTotalValues;
          }
          default:
            return [];
        }
      }
      case 'CALENDRIER_VALEURS': {
        let amount = (prejudice.formData as PrejudiceFormCalendrierValeur)
          .total;
        if (prejudiceData.displayTotalPartResponsabilite) {
          amount = amount * procedure.partResponsabilite;
        }

        return [
          {
            amount: getSignedPrejudiceAmount(amount, prejudice.type),
            name: 'indemniteVictimeEchue',
          },
        ];
      }
      case 'MATERIEL': {
        const formData = prejudice.formData as PrejudiceFormMateriel;
        const partResponsabilite =
          formData.partResponsabilite || formData.partResponsabilite === 0
            ? formData.partResponsabilite
            : procedure.partResponsabilite;
        return [
          {
            amount: getSignedPrejudiceAmount(
              prejudiceData.displayTotalPartResponsabilite
                ? formData.total * partResponsabilite
                : formData.total,
              prejudice.type,
            ),
            name: 'indemniteVictimeEchue',
          },
        ];
      }
      case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL': {
        let amount =
          CalculsFormCalendrierDeficitFonctionnelTemporaireTotal.total(
            (
              prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal
            ).rows,
          );
        if (prejudiceData.displayTotalPartResponsabilite) {
          amount = amount * procedure.partResponsabilite;
        }
        return [
          {
            amount: getSignedPrejudiceAmount(amount, prejudice.type),
            name: 'indemniteVictimeEchue',
          },
        ];
      }
      case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL': {
        const formData =
          prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel;
        let amount =
          formData.total || formData.total === 0
            ? formData.total
            : CalculsFormCalendrierDeficitFonctionnelTemporairePartiel.total(
                (
                  prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel
                ).rows,
              );
        if (prejudiceData.displayTotalPartResponsabilite) {
          amount = amount * procedure.partResponsabilite;
        }
        return [
          {
            amount: getSignedPrejudiceAmount(amount, prejudice.type),
            name: 'indemniteVictimeEchue',
          },
        ];
      }
      case 'PERTES_REVENUS_PROCHE': {
        if ('surcroitActivite' in prejudice.formData) {
          const formData =
            prejudice.formData as PrejudiceFormPerteRevenusProcheVictimeNonDecedee;

          return [
            {
              amount: formData.partResponsableTotal,
              name: 'indemniteProche',
            },
          ];
        } else {
          const formData =
            prejudice.formData as PrejudiceFormPerteRevenusProche;
          const partResponsabilite =
            formData.partResponsabilite || formData.partResponsabilite === 0
              ? formData.partResponsabilite
              : procedure.partResponsabilite;
          const indemniteRepartie =
            CalculPerteRevenusProche.getIndemniteRepartie({
              tiersPayeurs: procedure.tiersPayeurs,
              partResponsabilite,
              victimesIndirectes: victimesIndirectes,
              perteRevenusProche: formData,
            });
          return [
            {
              amount: indemniteRepartie.indemniteProche.arreragesEchus.debit,
              name: 'indemniteProche',
              subAmounts: Object.entries(
                indemniteRepartie.indemniteProche.arreragesEchus
                  .parVictimeIndirecte,
              ).reduce((accumulator, [victimeIndirecteId, amount]) => {
                const victimeIndirecte = victimesIndirectes.find(
                  ({ _id }) => _id === victimeIndirecteId,
                );
                if (victimeIndirecte) {
                  accumulator.push({
                    amount,
                    name: i18next.t('victimeIndirecte.displayLienVictimeLong', {
                      lienVictime: victimeIndirecte.lienVictime,
                      prenom: victimeIndirecte.prenom,
                      nom: victimeIndirecte.nom,
                    }),
                  });
                }
                return accumulator;
              }, [] as PrejudiceTotalValue[]),
            },
            {
              name: 'indemniteTiersPayeurEchue',
              amount:
                indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
            },
          ];
        }
      }
      case 'VALEUR_VICTIMES_INDIRECTES': {
        const formData =
          prejudice.formData as PrejudiceFormValeurVictimesIndirectes;
        const partResponsabilite =
          formData.partResponsabilite || formData.partResponsabilite === 0
            ? formData.partResponsabilite
            : procedure.partResponsabilite;
        return [
          {
            amount: formData.montantTotal * partResponsabilite,
            name: 'indemniteProche',
            subAmounts: formData.victimesIndirectes.reduce(
              (accumulator, { montant, victimeIndirecteId }) => {
                const victimeIndirecte = victimesIndirectes.find(
                  (victimeIndirecte) =>
                    victimeIndirecte._id === victimeIndirecteId,
                );
                if (victimeIndirecte) {
                  accumulator.push({
                    name: i18next.t('victimeIndirecte.displayLienVictimeLong', {
                      lienVictime: victimeIndirecte.lienVictime,
                      prenom: victimeIndirecte.prenom,
                      nom: victimeIndirecte.nom,
                    }),
                    amount: montant * partResponsabilite,
                  });
                }
                return accumulator;
              },
              [] as { name: string; amount: number }[],
            ),
          },
        ];
      }
      case 'SCOLAIRE': {
        const formData = prejudice.formData as PrejudiceFormScolaire;
        return [
          {
            amount: getSignedPrejudiceAmount(
              formData.total * procedure.partResponsabilite,
              prejudice.type,
            ),
            name: 'indemniteVictimeEchue',
          },
        ];
      }
      default:
        return [];
    }
  } catch (e) {
    console.error(
      i18next.t('prejudice.total.error', {
        prejudiceName: i18next.t(
          `prejudice.prejudiceTypes.${prejudice.type}.title`,
        ),
        functionName: 'getPrejudiceTotal',
        prejudice: JSON.stringify(prejudice, null, 2),
        error: e?.toString(),
      }),
    );
    return [];
  }
};

export const formatPrejudiceTotal = (
  values: PrejudiceTotalValue[],
): FormattedPrejudiceTotal => {
  const isFutur = values.some(
    (value) =>
      value.name === 'indemniteVictimeAEchoir' ||
      value.name === 'indemniteTiersPayeurAEchoir',
  );
  return [
    ...values
      .filter(({ name }) => name !== 'rentes')
      .reduce(
        (
          accumulator: { fieldName: string; fieldValue: string }[],
          { name, amount, otherAmounts, customLabel, subAmounts },
        ) => [
          ...accumulator,
          {
            fieldName:
              customLabel ??
              i18n.t(
                `prejudice.total.totalNames.long.${
                  isFutur ? 'futur' : 'actuel'
                }.${name}`,
              ) ??
              '',
            fieldValue: fCurrency(amount),
          },
          ...(subAmounts?.map(({ name, amount }) => ({
            fieldName: `\t${name}`,
            fieldValue: fCurrency(amount),
          })) || []),
          ...(otherAmounts?.map(({ name, amount }) => ({
            fieldName: name,
            fieldValue: fCurrency(amount),
          })) || []),
        ],
        [],
      ),
    {
      fieldName: i18n.t('prejudice.total.totalNames.long.total'),
      fieldValue: fCurrency(
        CalculsGlobal.sum(
          values
            .filter(({ name }) => prejduiceTotalNamesAddedTotal.includes(name))
            .map(
              ({ amount, otherAmounts }) =>
                amount +
                (CalculsGlobal.sum(
                  otherAmounts?.map(({ amount }) => amount) || [],
                ) || 0),
            ),
        ),
      ),
    },
    ...values
      .filter(({ name }) => name === 'rentes')
      .reduce(
        (
          accumulator: { fieldName: string; fieldValue: string }[],
          { name, amount, customLabel },
        ) => [
          ...accumulator,
          {
            fieldName:
              customLabel ??
              i18n.t(`prejudice.total.totalNames.long.futur.${name}`) ??
              '',
            fieldValue: fCurrency(amount),
          },
        ],
        [],
      ),
  ];
};

export const getFormattedPrejudiceTotal = ({
  victime,
  victimesIndirectes,
  procedure,
  prejudice,
  dateConsolidation,
  dateLiquidation,
  monetaryErosions,
  PGPFReliquat,
  dateDeces,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudice: Prejudice;
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  dateDeces: Date | undefined;
}): FormattedPrejudiceTotal => {
  const values = getPrejudiceTotal({
    victime,
    victimesIndirectes,
    procedure,
    prejudice,
    dateLiquidation: dateLiquidation ? new Date(dateLiquidation) : undefined,
    monetaryErosions,
    PGPFReliquat,
    dateConsolidation,
    dateDeces,
  });
  return formatPrejudiceTotal(values);
};

export const getPrejudiceTotalAmount = ({
  prejudiceTotalValues,
}: {
  prejudiceTotalValues: PrejudiceTotalValue[];
}): PrejudicesTotalAmount => {
  const totalAmounts = prejudiceTotalValues.reduce(
    (accumulator: PrejudicesTotalAmount, { amount, name, otherAmounts }) => {
      if (
        name !== 'indemniteTiersPayeurEchue' &&
        name !== 'indemniteTiersPayeurAEchoir' &&
        name !== 'indemniteProche' &&
        name !== 'rentes'
      ) {
        accumulator.victime += Number(amount) || 0;
      }

      if (
        name === 'indemniteTiersPayeurEchue' ||
        name === 'indemniteTiersPayeurAEchoir'
      ) {
        accumulator.tiersPayeurs =
          (accumulator.tiersPayeurs || 0) + (Number(amount) || 0);
      }
      if (name === 'indemniteProche') {
        accumulator.proche = (accumulator.proche || 0) + (Number(amount) || 0);
      }
      if (name === 'rentes') {
        accumulator.rentes = (accumulator.rentes || 0) + (Number(amount) || 0);
      }
      if (otherAmounts) {
        accumulator.total += CalculsGlobal.sum(
          otherAmounts?.map(({ amount }) => amount),
        );
      }
      return accumulator;
    },
    { victime: 0, tiersPayeurs: null, proche: null, rentes: null, total: 0 },
  );

  totalAmounts.total +=
    totalAmounts.victime +
    (totalAmounts.tiersPayeurs || 0) +
    (totalAmounts.proche || 0);
  return totalAmounts;
};

export const getPrejudicesTotal = ({
  victime,
  victimesIndirectes,
  procedure,
  prejudices,
  monetaryErosions,
  dateConsolidation,
  dateLiquidation,
  dateDeces,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudices: Prejudice[];
  monetaryErosions: MonetaryErosion[];
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  dateDeces: Date | undefined;
}): PrejudicesTotalAmount => {
  const PGPFReliquat =
    getPGPFReliquatAndPGPFReliquatActivationStatus(prejudices);
  return prejudices.reduce(
    (total: PrejudicesTotalAmount, prejudice) => {
      const prejudiceTotalValues = getPrejudiceTotal({
        victime,
        victimesIndirectes,
        procedure,
        prejudice,
        monetaryErosions,
        PGPFReliquat,
        dateConsolidation,
        dateLiquidation,
        dateDeces,
      });
      switch (prejudice.formType) {
        case 'CALENDRIER_ASSISTANCE':
        case 'CALENDRIER_ASSISTANCE_CAPITALISATION':
        case 'CALENDRIER_DEPENSE_CAPITALISATION':
        case 'CALENDRIER_DEPENSE':
        case 'VALEUR':
        case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL':
        case 'PERTE_GAINS_PROFESSIONNELS_FUTURS':
        case 'VALEUR_VICTIMES_INDIRECTES':
        case 'CALENDRIER_VALEURS':
        case 'MATERIEL':
        case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL':
        case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL':
        case 'PERTES_REVENUS_PROCHE':
        case 'LISTE_PROJECTION':
        case 'SCOLAIRE': {
          try {
            const amount = getPrejudiceTotalAmount({
              prejudiceTotalValues,
            });
            return {
              victime: total.victime + amount.victime,
              tiersPayeurs:
                (total.tiersPayeurs || 0) + (amount.tiersPayeurs || 0),
              proche: (total.proche || 0) + (amount.proche || 0),
              rentes:
                amount.rentes || amount.rentes === 0
                  ? (total.rentes || 0) + (amount.rentes || 0)
                  : total.rentes,
              total: total.total + amount.total,
            };
          } catch (e) {
            console.error(i18next.t('prejudice.total.error'), {
              prejudiceName: i18next.t(
                `prejudice.prejudiceTypes.${prejudice.type}.title`,
              ),
              functionName: 'getPrejudiceTotalAmount',
              prejudice: JSON.stringify(prejudice, null, 2),
              error: e?.toString(),
            });
            return total;
          }
        }
        default:
          return total;
      }
    },
    {
      victime: 0,
      tiersPayeurs: 0,
      proche: 0,
      rentes: null,
      total: 0,
    },
  );
};

export type PrejudiceTotalSubAmounts = Record<
  keyof PrejudicesTotalAmount,
  Record<string, number>
>;

export const getPrejudiceTotalSubAmounts = (
  totalValues: PrejudiceTotalValue[],
): PrejudiceTotalSubAmounts => {
  const totalSubAmounts = totalValues.reduce(
    (accumulator: PrejudiceTotalSubAmounts, { name, amount, subAmounts }) => {
      const totalName = (
        [
          'indemniteVictimeEchue',
          'indemniteVictimeAEchoir',
          'indemnitePGPFPerteDeChanceEchue',
          'indemnitePGPFPerteDeChanceAEchoir',
        ] as PrejudiceTotalName[]
      ).includes(name)
        ? 'victime'
        : ['indemniteTiersPayeurEchue', 'indemniteTiersPayeurAEchoir'].includes(
              name,
            )
          ? 'tiersPayeurs'
          : name === 'indemniteProche'
            ? 'proche'
            : 'rentes';
      if (subAmounts) {
        accumulator[totalName] = subAmounts.reduce(
          (
            subAccumulator: Record<string, number>,
            { name: subName, amount: subAmount },
          ) => {
            subAccumulator[subName] =
              subAmount + (subAccumulator[subName] || 0);
            return subAccumulator;
          },
          accumulator[totalName] || {},
        );
        accumulator[totalName].total =
          (accumulator[totalName].total || 0) + (amount || 0);
      }
      return accumulator;
    },
    {
      victime: {},
      tiersPayeurs: {},
      proche: {},
      rentes: {},
      total: {},
    },
  );
  return totalSubAmounts;
};
