import { PrejudiceFormBase, PrejudiceType } from 'src/types/prejudice.type';

export const prejudiceBaseDefaultValues = ({
  values,
}: {
  prejudiceType: PrejudiceType;
  values: PrejudiceFormBase | undefined;
}): PrejudiceFormBase => ({
  notes: values?.notes || '',
  chiffrage: values?.chiffrage || 'en_cours',
  formVersion: values?.formVersion || 0,
  editedFields: values?.editedFields || [],
});
