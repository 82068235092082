import React from 'react';
import { Control, FieldArrayWithId } from 'react-hook-form';
import {
  IconButton,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import {
  Procedure,
  ProcedureHospitalisation,
} from '../../../../types/procedure.type';
import {
  AssistanceRowType,
  assistanceUniteNombresHeure,
  defaultJoursConges,
  NumeroPieceValuesRow,
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceType,
  typesAssistance,
} from '../../../../types/prejudice.type';
import { DatePickerForm } from '../../../forms/DatePickerForm';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { CalculsFormCalendrierAssistance } from 'src/constants/calculs/calculsFormCalendrierAssistance';
import { fCurrency } from '../../../../helpers/formatNumber';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { differenceInDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { AutocompleteForm } from 'src/components/forms/AutocompleteForm';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import {
  getMontantRevalorise,
  getQuotientRevalorisationTooltip,
} from 'src/helpers/prejudices/revalorisation';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { DefaultValuesTextFieldForm } from 'src/components/forms/DefaultValuesTextFieldForm';
import { CalculsGlobal } from 'src/constants/calculs/calculsGlobal';
import { getDefaultMinAndMaxDate } from 'src/constants/prejudice/validation';
import { Victime } from 'src/types/victime.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { HiddenFieldForm } from 'src/components/forms/HiddenFieldForm';
import { SpanNoWrap } from 'src/components/styled';

const DisplayedIfDateFinField: React.FC<{
  control:
    | Control<PrejudiceFormCalendrierAssistance>
    | Control<PrejudiceFormCalendrierAssistanceCapitalisation>;
  rowType: AssistanceRowType;
  renderComponent: () => React.ReactElement;
  index: number;
}> = ({ control, rowType, renderComponent, index }) =>
  rowType === 'echu' ? (
    renderComponent()
  ) : (
    <ComputedPropsForm
      control={control as Control<PrejudiceFormCalendrierAssistance>}
      watchFields={[`rows.${index}.dateFin`]}
      compute={([dateFin]) => ({
        hidden: !dateFin,
      })}
      render={renderComponent}
    />
  );

interface Props {
  control:
    | Control<PrejudiceFormCalendrierAssistance>
    | Control<PrejudiceFormCalendrierAssistanceCapitalisation>;
  tiersPayeurs: string[];
  hospitalisations: ProcedureHospitalisation[];
  monetaryErosions: MonetaryErosion[];
  dateConsolidation: Date | undefined | null;
  dateLiquidation: Date | undefined;
  dateDeces: Date | undefined;
  isCalculCapitalisation: boolean | undefined;
  allNumerosPieces: NumeroPieceValuesRow[];
  field: FieldArrayWithId<PrejudiceFormCalendrierAssistance, 'rows', 'id'>;
  index: number;
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
  shouldNotDisplayCapitalisation: boolean;
  onClickRemove: (index: number) => void;
}

export const TableAssistanceRow: React.FC<Props> = ({
  control,
  tiersPayeurs,
  hospitalisations,
  monetaryErosions,
  dateLiquidation,
  dateConsolidation,
  isCalculCapitalisation,
  allNumerosPieces,
  field,
  index,
  procedure,
  victime,
  prejudiceType,
  shouldNotDisplayCapitalisation,
  dateDeces,
  onClickRemove,
}) => {
  const { t } = useTranslation();

  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  const getMinDateByRowType = (rowType: 'echu' | 'aEchoir') => {
    if (rowType === 'echu') {
      return minDate;
    } else {
      return dateLiquidation || minDate;
    }
  };

  const getMaxDateByRowType = (rowType: 'echu' | 'aEchoir') => {
    if (rowType === 'echu') {
      return dateLiquidation || maxDate;
    } else {
      return maxDate;
    }
  };

  return (
    <TableRow>
      <TableCell>
        <FormNumeroPieceDialog
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          name={`rows.${index}.numerosPieces`}
          displayShortPieceText
          allOtherNumeroPieces={allNumerosPieces}
        />
      </TableCell>
      <TableCell
        colSpan={2}
        variant="head"
        sx={{
          position: 'sticky',
          left: 0,
          zIndex: 3,
        }}
      >
        <Stack direction="row" spacing={1}>
          <DatePickerForm
            control={control as Control<PrejudiceFormCalendrierAssistance>}
            label={t(
              'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.dateDebut.label',
            )}
            name={`rows.${index}.dateDebut`}
            TextFieldProps={{
              InputLabelProps: { shrink: true },
              sx: {
                minWidth: 155,
                width: 155,
              },
            }}
            minDate={getMinDateByRowType(field.type)}
            maxDate={getMaxDateByRowType(field.type)}
          />
          <DatePickerForm
            control={control as Control<PrejudiceFormCalendrierAssistance>}
            label={t(
              'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.dateFin.label',
            )}
            name={`rows.${index}.dateFin`}
            TextFieldProps={{
              InputLabelProps: { shrink: true },
              sx: {
                minWidth: 155,
                width: 155,
              },
            }}
            minDate={getMinDateByRowType(field.type)}
            maxDate={getMaxDateByRowType(field.type)}
          />
        </Stack>
      </TableCell>
      <TableCell align="right">
        <ComputedReadFieldForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          name={`rows.${index}.joursPeriode`}
          watchFields={[`rows.${index}.dateDebut`, `rows.${index}.dateFin`]}
          compute={([dateDebut, dateFin]) => {
            if (!dateDebut || !dateFin) {
              return 0;
            }
            const dateDebutDate = new Date(dateDebut);
            const dateFinDate = new Date(dateFin);
            return differenceInDays(dateFinDate, dateDebutDate) + 1;
          }}
          render={(value) => <>{value}</>}
        />
      </TableCell>
      {!isCalculCapitalisation && (
        <>
          <TableCell>
            <ComputedTextFieldForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.joursHospitalisationADeduire`}
              watchFields={[`rows.${index}.dateDebut`, `rows.${index}.dateFin`]}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 0 },
                } as IMaskNumberProps,
              }}
              compute={([dateDebut, dateFin]) =>
                CalculsGlobal.getJoursHospitalisationsADeduire(
                  dateDebut,
                  dateFin,
                  hospitalisations,
                )
              }
              editedFieldsName="editedFields"
            />
          </TableCell>
          <TableCell align="center">
            <CheckboxFormField
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.deduireJoursHospitalisation`}
            />
          </TableCell>
          <TableCell>
            <ComputedTextFieldForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.joursRetenus`}
              watchFields={[
                `rows.${index}.joursHospitalisationADeduire`,
                `rows.${index}.joursPeriode`,
                `rows.${index}.deduireJoursHospitalisation`,
              ]}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 0 },
                } as IMaskNumberProps,
              }}
              compute={([
                joursHospitalisationADeduire,
                joursPeriode,
                deduireJoursHospitalisation,
              ]) => {
                if (deduireJoursHospitalisation) {
                  return joursPeriode - joursHospitalisationADeduire;
                } else {
                  return joursPeriode;
                }
              }}
              editedFieldsName="editedFields"
            />
          </TableCell>
        </>
      )}
      <TableCell>
        <DefaultValuesTextFieldForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          name={`rows.${index}.joursConges`}
          defaultValues={defaultJoursConges.map((value) => value.toString())}
          InputProps={{
            inputComponent: MaskNumber as any,
            inputProps: {
              numberMask: { scale: 0 },
            } as IMaskNumberProps,
          }}
        />
      </TableCell>
      <TableCell align="right">
        <ComputedTextFieldForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          watchFields={[
            `rows.${index}.joursRetenus`,
            `rows.${index}.joursPeriode`,
            `rows.${index}.joursConges`,
          ]}
          name={`rows.${index}.totalJours`}
          compute={(values) => {
            const [joursRetenus, joursPeriode, joursConges] = values;
            return CalculsFormCalendrierAssistance.totalJours({
              joursRetenus: isCalculCapitalisation
                ? joursPeriode
                : joursRetenus,
              joursConges: Number(joursConges),
            });
          }}
          InputProps={{
            inputComponent: MaskNumber as any,
            inputProps: {
              numberMask: { scale: 2 },
            } as IMaskNumberProps,
          }}
          editedFieldsName="editedFields"
        />
      </TableCell>
      <TableCell>
        <AutocompleteForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          name={`rows.${index}.typeAssistance`}
          disablePortal
          getOptionLabel={(option) =>
            typesAssistance.includes(option)
              ? t(
                  `prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.typeAssistance.options.${option}`,
                  { defaultValue: null },
                ) ?? option
              : option
          }
          options={typesAssistance}
          sx={{ width: 350 }}
          blurOnSelect={true}
          freeSolo
        />
      </TableCell>
      <TableCell>
        <TextFieldForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          name={`rows.${index}.forfaitHoraire`}
          InputProps={{
            inputComponent: MaskNumber as any,
            inputProps: {
              numberMask: { scale: 2 },
              suffix: '€',
            } as IMaskNumberProps,
          }}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row">
          <TextFieldForm
            control={control as Control<PrejudiceFormCalendrierAssistance>}
            name={`rows.${index}.nombreHeureParUniteChoisie`}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: 'h',
              } as IMaskNumberProps,
            }}
            sx={{ maxWidth: '80px' }}
          />
          <TextFieldForm
            control={control as Control<PrejudiceFormCalendrierAssistance>}
            name={`rows.${index}.uniteNombreHeure`}
            select
          >
            {assistanceUniteNombresHeure.map((unite, index) => (
              <MenuItem key={index} value={unite}>
                {t(`prejudice.fields.unite.optionsPar.${unite}`)}
              </MenuItem>
            ))}
          </TextFieldForm>
        </Stack>
      </TableCell>
      <TableCell align="right">
        <ComputedPropsForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          watchFields={[`rows.${index}.dateFin`]}
          compute={([dateFin]) => ({
            hidden: !dateFin,
          })}
          render={() => (
            <ComputedTextFieldForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.quantite`}
              watchFields={[
                `rows.${index}.totalJours`,
                `rows.${index}.nombreHeureParUniteChoisie`,
                `rows.${index}.uniteNombreHeure`,
              ]}
              compute={(values) => {
                const [totalJours, nombreHeureParUniteChoisie] = values
                  .slice(0, 2)
                  .map(Number);
                const uniteNombreHeure = values[2];
                return CalculsFormCalendrierAssistance.quantite({
                  totalJours: totalJours || 0,
                  nombreHeureParUniteChoisie: nombreHeureParUniteChoisie || 0,
                  uniteNombreHeure,
                });
              }}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 2 },
                } as IMaskNumberProps,
              }}
              editedFieldsName="editedFields"
            />
          )}
        />
      </TableCell>
      {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
        <>
          <TableCell>
            <ComputedTextFieldForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.annualiseMontantTotal`}
              watchFields={[
                `rows.${index}.forfaitHoraire`,
                `rows.${index}.joursConges`,
                `rows.${index}.nombreHeureParUniteChoisie`,
                `rows.${index}.uniteNombreHeure`,
                `rows.${index}.dateJustificatif`,
                `rows.${index}.montantsDejaRevalorises`,
                `revalorisationCoefficientsType`,
              ]}
              compute={([
                forfaitHoraire,
                joursConges,
                nombreHeureParUniteChoisie,
                uniteNombreHeure,
              ]) => {
                if (!uniteNombreHeure) {
                  return 0;
                }
                return CalculsFormCalendrierAssistance.annualiseMontantTotal({
                  forfaitHoraire: forfaitHoraire || 0,
                  joursConges: Number(joursConges),
                  nombreHeureParUniteChoisie: nombreHeureParUniteChoisie || 0,
                  uniteNombreHeure,
                });
              }}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 2 },
                  suffix: '€',
                } as IMaskNumberProps,
              }}
              editedFieldsName="editedFields"
            />
          </TableCell>
          <TableCell align="right">
            {/* TODO : put this into a separate reusable component to display a montant revalorise */}
            <ComputedPropsForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              watchFields={[
                `rows.${index}.annualiseMontantTotal`,
                `rows.${index}.dateJustificatif`,
                `rows.${index}.montantsDejaRevalorises`,
                `revalorisationCoefficientsType`,
              ]}
              compute={([
                annualiseMontantTotal,
                dateJustificatif,
                montantsDejaRevalorises,
                revalorisationCoefficientsType,
              ]) => {
                if (
                  !dateJustificatif ||
                  !annualiseMontantTotal ||
                  montantsDejaRevalorises
                ) {
                  return {
                    props: {
                      annualiseMontantTotal,
                      annualiseMontantTotalRevalorise: 0,
                      quotientRevalorisationTooltip: '',
                      displayMontantRevalorise: false,
                    },
                  };
                } else {
                  return {
                    props: {
                      annualiseMontantTotal,
                      annualiseMontantTotalRevalorise:
                        getMontantRevalorise({
                          anneeOrDateMontant: dateJustificatif,
                          montant: annualiseMontantTotal,
                          anneeOrDateLiquidation: dateLiquidation,
                          coefficientsType: revalorisationCoefficientsType,
                          monetaryErosions,
                        }) || 0,
                      quotientRevalorisationTooltip:
                        getQuotientRevalorisationTooltip({
                          anneeOrDateMontant: dateJustificatif,
                          anneeOrDateLiquidation: dateLiquidation,
                          coefficientsType: revalorisationCoefficientsType,
                          monetaryErosions,
                        }),
                      displayMontantRevalorise: true,
                    },
                  };
                }
              }}
              render={({
                annualiseMontantTotal,
                annualiseMontantTotalRevalorise,
                quotientRevalorisationTooltip,
                displayMontantRevalorise,
              }) =>
                !displayMontantRevalorise ? (
                  <SpanNoWrap>
                    {fCurrency(annualiseMontantTotal || 0)}
                  </SpanNoWrap>
                ) : (
                  <Tooltip
                    title={quotientRevalorisationTooltip || ''}
                    placement="top"
                  >
                    <SpanNoWrap>
                      {fCurrency(annualiseMontantTotalRevalorise)}
                    </SpanNoWrap>
                  </Tooltip>
                )
              }
            />
          </TableCell>
        </>
      ) : null}
      <TableCell>
        <DatePickerForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          name={`rows.${index}.dateJustificatif`}
          maxDate={
            isCalculCapitalisation
              ? dateDeces || dateLiquidation || dateConsolidation || undefined
              : dateConsolidation || dateDeces || dateLiquidation
          }
        />
      </TableCell>
      <TableCell align="center">
        <ComputedPropsForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          watchFields={[`rows.${index}.dateJustificatif`]}
          compute={([dateJustificatif]) => ({
            hidden: !dateJustificatif,
          })}
          render={() => (
            <CheckboxFormField
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.montantsDejaRevalorises`}
            />
          )}
        />
      </TableCell>
      <TableCell>
        {isCalculCapitalisation ? (
          <DisplayedIfDateFinField
            control={control}
            rowType={field.type}
            index={index}
            renderComponent={() => (
              <ComputedTextFieldForm
                control={control as Control<PrejudiceFormCalendrierAssistance>}
                name={`rows.${index}.montantTotal`}
                watchFields={[
                  `rows.${index}.annualiseMontantTotal`,
                  `rows.${index}.totalJours`,
                  `rows.${index}.joursConges`,
                ]}
                compute={([annualiseMontantTotal, totalJours, joursConges]) =>
                  CalculsFormCalendrierAssistance.montantParPeriode({
                    annualiseMontant: annualiseMontantTotal,
                    totalJours,
                    joursConges,
                  })
                }
                InputProps={{
                  inputComponent: MaskNumber as any,
                  inputProps: {
                    suffix: '€',
                    numberMask: { scale: 2 },
                  } as IMaskNumberProps,
                }}
                editedFieldsName="editedFields"
              />
            )}
          />
        ) : (
          <ComputedTextFieldForm
            control={control as Control<PrejudiceFormCalendrierAssistance>}
            name={`rows.${index}.montantTotal`}
            watchFields={[
              `rows.${index}.forfaitHoraire`,
              `rows.${index}.joursConges`,
              `rows.${index}.nombreHeureParUniteChoisie`,
              `rows.${index}.uniteNombreHeure`,
              `rows.${index}.totalJours`,
            ]}
            compute={([
              forfaitHoraire,
              joursConges,
              nombreHeureParUniteChoisie,
              uniteNombreHeure,
              totalJours,
            ]) => {
              if (!uniteNombreHeure) {
                return 0;
              }
              return CalculsFormCalendrierAssistance.montantTotal({
                forfaitHoraire: forfaitHoraire || 0,
                joursConges: Number(joursConges),
                nombreHeureParUniteChoisie: nombreHeureParUniteChoisie || 0,
                uniteNombreHeure,
                totalJours,
              });
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                suffix: '€',
                numberMask: { scale: 2 },
              } as IMaskNumberProps,
            }}
            editedFieldsName="editedFields"
          />
        )}
      </TableCell>
      {tiersPayeurs.map((tiersPayeur, tiersPayeurIndex) => (
        <TableCell key={tiersPayeur}>
          <HiddenFieldForm
            control={control as Control<PrejudiceFormCalendrierAssistance>}
            name={`rows.${index}.priseEnChargeTiersPayeurs.${tiersPayeurIndex}.tiersPayeur`}
            value={tiersPayeur}
          />
          <DisplayedIfDateFinField
            control={control}
            rowType={field.type}
            index={index}
            renderComponent={() => (
              <TextFieldForm
                control={control as Control<PrejudiceFormCalendrierAssistance>}
                name={`rows.${index}.priseEnChargeTiersPayeurs.${tiersPayeurIndex}.montant`}
                InputProps={{
                  inputComponent: MaskNumber as any,
                  inputProps: {
                    suffix: '€',
                    numberMask: { scale: 2 },
                  } as IMaskNumberProps,
                }}
              />
            )}
          />
        </TableCell>
      ))}
      <TableCell>
        <DisplayedIfDateFinField
          control={control}
          rowType={field.type}
          index={index}
          renderComponent={() => (
            <TextFieldForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.deductionFiscale`}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  suffix: '€',
                  numberMask: { scale: 2 },
                } as IMaskNumberProps,
              }}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <DisplayedIfDateFinField
          control={control}
          rowType={field.type}
          index={index}
          renderComponent={() => (
            <TextFieldForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.autresDeductions`}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  suffix: '€',
                  numberMask: { scale: 2 },
                } as IMaskNumberProps,
              }}
            />
          )}
        />
      </TableCell>
      <TableCell align="right">
        {/* TODO : put this into a separate reusable component to display a montant revalorise */}
        <DisplayedIfDateFinField
          control={control}
          rowType={field.type}
          index={index}
          renderComponent={() => (
            <ComputedTextFieldForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              name={`rows.${index}.resteACharge`}
              watchFields={[
                `rows.${index}.montantTotal`,
                `rows.${index}.deductionFiscale`,
                `rows.${index}.autresDeductions`,
                `rows.${index}.priseEnChargeTiersPayeurs`,
                `revalorisationCoefficientsType`,
                `rows.${index}.montantsDejaRevalorises`,
                `rows.${index}.dateJustificatif`,
              ]}
              compute={([
                montantTotal,
                deductionFiscale,
                autresDeductions,
                priseEnChargeTiersPayeurs,
              ]) =>
                CalculsFormCalendrierAssistance.resteACharge({
                  montantTotal: montantTotal || 0,
                  deductionFiscale,
                  priseEnChargeTiersPayeurs,
                  autresDeductions,
                })
              }
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  suffix: '€',
                  numberMask: { scale: 2 },
                } as IMaskNumberProps,
              }}
              editedFieldsName="editedFields"
            />
          )}
        />
      </TableCell>
      <TableCell align="right">
        <DisplayedIfDateFinField
          control={control}
          rowType={field.type}
          index={index}
          renderComponent={() => (
            <ComputedPropsForm
              control={control as Control<PrejudiceFormCalendrierAssistance>}
              watchFields={[
                `rows.${index}.resteACharge`,
                `rows.${index}.dateJustificatif`,
                `rows.${index}.montantsDejaRevalorises`,
                `revalorisationCoefficientsType`,
              ]}
              compute={([
                resteACharge,
                dateJustificatif,
                montantsDejaRevalorises,
                revalorisationCoefficientsType,
              ]) => {
                if (
                  !dateJustificatif ||
                  !resteACharge ||
                  montantsDejaRevalorises
                ) {
                  return {
                    props: {
                      resteACharge,
                      resteAChargeRevalorise: 0,
                      quotientRevalorisationTooltip: '',
                      displayMontantRevalorise: false,
                    },
                  };
                } else {
                  return {
                    props: {
                      resteACharge,
                      resteAChargeRevalorise: getMontantRevalorise({
                        anneeOrDateMontant: dateJustificatif,
                        montant: resteACharge,
                        anneeOrDateLiquidation: dateLiquidation,
                        coefficientsType: revalorisationCoefficientsType,
                        monetaryErosions,
                      }),
                      quotientRevalorisationTooltip:
                        getQuotientRevalorisationTooltip({
                          anneeOrDateMontant: dateJustificatif,
                          anneeOrDateLiquidation: dateLiquidation,
                          coefficientsType: revalorisationCoefficientsType,
                          monetaryErosions,
                        }),
                      displayMontantRevalorise: true,
                    },
                  };
                }
              }}
              render={({
                resteACharge,
                resteAChargeRevalorise,
                quotientRevalorisationTooltip,
                displayMontantRevalorise,
              }) =>
                !displayMontantRevalorise ? (
                  <SpanNoWrap>{fCurrency(resteACharge)}</SpanNoWrap>
                ) : (
                  <Tooltip
                    title={quotientRevalorisationTooltip || ''}
                    placement="top"
                  >
                    <SpanNoWrap>
                      {fCurrency(resteAChargeRevalorise || 0)}
                    </SpanNoWrap>
                  </Tooltip>
                )
              }
            />
          )}
        />
      </TableCell>
      <TableCell>
        <TextFieldForm
          control={control as Control<PrejudiceFormCalendrierAssistance>}
          name={`rows.${index}.commentaires`}
          sx={{ minWidth: '200px' }}
        />
      </TableCell>
      <TableCell align="center">
        <IconButton
          aria-label={
            t('forms.table.deleteRow', {
              index: index + 1,
            }) || ''
          }
          onClick={() => onClickRemove(index)}
        >
          <DeleteRounded />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
