import React from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { FormControlLabel, TableCell, TableRow } from '@mui/material';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { Procedure } from 'src/types/procedure.type';
import { Bareme } from 'src/types/bareme.type';
import { getAgeDateAttribution } from 'src/helpers/prejudices/capitalisation';
import { SelectBareme } from './SelectBareme';
import { SelectDerniersArrerages } from './SelectDernierArrerages';
import { Coefficient } from './Coefficient';
import { MontantCapitalise } from './MontantCapitalise';
import { TypeBareme } from './TypeBareme';
import { useTranslation } from 'react-i18next';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { capitalize } from 'lodash';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { CalculsGlobal } from 'src/constants/calculs';
import { isAfter } from 'date-fns';

interface Props<
  TFieldValues extends FieldValues,
  TSommeACapitaliserName extends FieldPath<TFieldValues>,
  TBaremeName extends FieldPath<TFieldValues>,
  TAgeDernierArrerageName extends FieldPath<TFieldValues>,
  TCoefficientName extends FieldPath<TFieldValues>,
  TIsLastArrerageViagerName extends FieldPath<TFieldValues>,
  TMontantCapitaliseName extends FieldPath<TFieldValues>,
  TDefaultValuesSommeACapitaliserName extends FieldPath<TFieldValues>,
  TAgeDateAttributionName extends FieldPath<TFieldValues>,
  TEnableCapitalisationDiffereeName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  name: {
    sommeACapitaliser?: TSommeACapitaliserName;
    bareme?: TBaremeName;
    ageDernierArrerage?: TAgeDernierArrerageName;
    coefficient?: TCoefficientName;
    isLastArrerageViager?: TIsLastArrerageViagerName;
    montantCapitalise?: TMontantCapitaliseName;
    ageDateAttribution?: TAgeDateAttributionName;
    enableCapitalisationDifferee?: TEnableCapitalisationDiffereeName;
  };
  editedFieldsName: TEditedFieldsName;
  component?: {
    sommeACapitaliser?: React.ReactNode | null;
    bareme?: React.ReactNode | null;
    ageDernierArrerage?: React.ReactNode | null;
    coefficient?: React.ReactNode | null;
    isLastArrerageViager?: React.ReactNode | null;
    montantCapitalise?: React.ReactNode | null;
    baremeType?: React.ReactNode | null;
  };
  procedure: Procedure;
  dateLiquidation: Date | null | undefined;
  baremes: Bareme[];
  dateNaissance: Date | null | undefined;
  dateDeces: Date | null | undefined;
  ageDateAttribution?: number;
  dateAttribution?: Date | string | undefined;
  sexe: 'm' | 'f' | 'u';
  titles?: {
    ageDateAttribution?: string;
    sexe?: string;
    sommeACapitaliser?: string;
    bareme?: string;
    ageDernierArrerage?: string;
    coefficient?: string;
    isLastArrerageViager?: string;
    montantCapitalise?: string;
    baremeType?: string;
    enableCapitalisationDifferee?: string;
  };
  hide?: {
    sommeACapitaliser?: boolean;
    bareme?: boolean;
    ageDernierArrerage?: boolean;
    coefficient?: boolean;
    isLastArrerageViager?: boolean;
    montantCapitalise?: boolean;
    baremeType?: boolean;
    sexe?: boolean;
    ageDateAttribution?: boolean;
    enableCapitalisationDifferee?: boolean;
  };
  defaultValuesNames?: {
    victimeSommeACapitaliser?: TDefaultValuesSommeACapitaliserName;
  };
  editable?: {
    victime?: {
      montantCapitalise?: boolean;
      ageDateAttribution?: boolean;
    };
  };
}

export const CapitalisationRows = <
  TFieldValues extends FieldValues,
  TSommeACapitaliserName extends FieldPath<TFieldValues>,
  TBaremeName extends FieldPath<TFieldValues>,
  TAgeDernierArreragename extends FieldPath<TFieldValues>,
  TCoefficientname extends FieldPath<TFieldValues>,
  TIsLastArrerageViagerName extends FieldPath<TFieldValues>,
  TMontantCapitaliseName extends FieldPath<TFieldValues>,
  TDefaultValuesSommeACapitaliserName extends FieldPath<TFieldValues>,
  TAgeDateAttributionName extends FieldPath<TFieldValues>,
  TEnableCapitalisationDiffereeName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  component,
  dateLiquidation,
  baremes,
  dateNaissance,
  dateDeces,
  sexe,
  titles,
  hide,
  defaultValuesNames,
  editable,
  editedFieldsName,
  ...props
}: Props<
  TFieldValues,
  TSommeACapitaliserName,
  TBaremeName,
  TAgeDernierArreragename,
  TCoefficientname,
  TIsLastArrerageViagerName,
  TMontantCapitaliseName,
  TDefaultValuesSommeACapitaliserName,
  TAgeDateAttributionName,
  TEnableCapitalisationDiffereeName,
  TEditedFieldsName
>) => {
  const { t } = useTranslation();
  const ageDateAttribution =
    props.ageDateAttribution !== undefined
      ? props.ageDateAttribution
      : getAgeDateAttribution({
          dateNaissance,
          dateLiquidation,
          dateDeces,
          dateAttribution: props.dateAttribution,
        })?.age ?? null;
  const { setValue } = useFormContext<TFieldValues>();
  return (
    <>
      {hide?.ageDateAttribution ? null : (
        <TableRow>
          <TableCell>
            {titles?.ageDateAttribution ||
              t(
                'prejudice.capitalisation.form.fields.ageDateAttribution.title',
              )}
          </TableCell>
          <TableCell align="right">
            {name.ageDateAttribution &&
            editable?.victime?.ageDateAttribution ? (
              <ComputedTextFieldForm<
                TFieldValues,
                any[],
                TAgeDateAttributionName,
                any[]
              >
                control={control}
                name={name.ageDateAttribution}
                watchFields={[]}
                customValues={[ageDateAttribution]}
                compute={(_, customValues) => customValues?.[0]}
                InputProps={{
                  inputComponent: MaskNumber as any,
                  inputProps: {
                    numberMask: { scale: 0 },
                    suffix: '\\ans',
                    nullable: true,
                  } as IMaskNumberProps,
                }}
                editedFieldsName={editedFieldsName}
              />
            ) : ageDateAttribution ? (
              t('prejudice.capitalisation.form.fields.ageDateAttribution.age', {
                age: ageDateAttribution,
              })
            ) : (
              '-'
            )}
          </TableCell>
        </TableRow>
      )}
      {hide?.sexe ? null : (
        <TableRow>
          <TableCell>
            {titles?.sexe ||
              t('prejudice.capitalisation.form.fields.sexe.title')}
          </TableCell>
          <TableCell align="right">
            {capitalize(t(`victime.fields.sexe.options.${sexe}.label`))}
          </TableCell>
        </TableRow>
      )}
      {hide?.sommeACapitaliser || !(component?.bareme || name.bareme) ? null : (
        <TableRow>
          <TableCell>
            {titles?.sommeACapitaliser ||
              t('prejudice.capitalisation.form.fields.sommeACapitaliser.title')}
          </TableCell>
          <TableCell align="right">
            {component?.sommeACapitaliser ||
              (!!name.sommeACapitaliser &&
                (defaultValuesNames?.victimeSommeACapitaliser ? (
                  <ComputedTextFieldForm<
                    TFieldValues,
                    any[],
                    TSommeACapitaliserName,
                    any
                  >
                    control={control}
                    name={name.sommeACapitaliser}
                    watchFields={[defaultValuesNames.victimeSommeACapitaliser]}
                    compute={([sommeACapitaliser]: any[]) =>
                      sommeACapitaliser || 0
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    sx={{ minWidth: 268 }}
                    editedFieldsName={editedFieldsName}
                  />
                ) : (
                  <TextFieldForm
                    control={control}
                    name={name.sommeACapitaliser}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    sx={{ minWidth: 268 }}
                  />
                )))}
          </TableCell>
        </TableRow>
      )}
      {/* {hide?.enableCapitalisationDifferee ||
      !name.enableCapitalisationDifferee ? null : (
        <ComputedPropsForm
          control={control}
          customValues={[ageDateAttribution]}
          watchFields={[
            ...(name.ageDateAttribution ? [name.ageDateAttribution] : []),
          ]}
          compute={([watchedAgeDateAttribution], customValues) => {
            const ageDateAttribution: number =
              watchedAgeDateAttribution || watchedAgeDateAttribution === 0
                ? watchedAgeDateAttribution
                : customValues?.[0] || customValues?.[0] === 0
                  ? customValues[0]
                  : getAgeDateAttribution({
                      dateNaissance,
                      dateLiquidation,
                      dateDeces,
                      dateAttribution: props.dateAttribution,
                    })?.age ?? 0;
            const ageLiquidation =
              dateLiquidation && dateNaissance
                ? CalculsGlobal.getAge(dateNaissance, dateLiquidation)
                : null;
            const difference =
              ageLiquidation !== null
                ? ageDateAttribution - ageLiquidation
                : null;
            const isCapitalisationDifferee =
              (difference !== null && difference > 0) ||
              (!!props.dateAttribution &&
                !!dateLiquidation &&
                isAfter(props.dateAttribution, dateLiquidation));
            return { hidden: !isCapitalisationDifferee };
          }}
          render={() =>
            !!name.enableCapitalisationDifferee ? (
              <TableRow>
                <TableCell>
                  {titles?.enableCapitalisationDifferee ||
                    t(
                      'prejudice.capitalisation.form.fields.enableCapitalisationDifferee.title',
                    )}
                </TableCell>
                <TableCell align="right">
                  <FormControlLabel
                    label={t(
                      'prejudice.capitalisation.form.fields.enableCapitalisationDifferee.label',
                    )}
                    labelPlacement="start"
                    control={
                      <CheckboxFormField
                        control={control}
                        name={name.enableCapitalisationDifferee}
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )
          }
        />
      )} */}
      {hide?.bareme || !(component?.bareme || name.bareme) ? null : (
        <TableRow>
          <TableCell>
            {titles?.bareme ||
              t('prejudice.capitalisation.form.fields.bareme.title')}
          </TableCell>
          <TableCell align="right">
            {component?.bareme ||
              (!!name.bareme && (
                <SelectBareme
                  control={control}
                  name={name.bareme}
                  baremes={baremes}
                  afterOnChange={(value) => {
                    if (!name.montantCapitalise) {
                      return;
                    }
                    const baremeSource = baremes.find(
                      (bareme) => bareme._id === value,
                    )?.source;
                    if (baremeSource === 'CJ') {
                      setValue(name.montantCapitalise, 0 as any);
                    }
                  }}
                />
              ))}
          </TableCell>
        </TableRow>
      )}

      {hide?.baremeType || !(component?.baremeType || name.bareme) ? null : (
        <TableRow sx={{ maxWidth: '200px' }}>
          <TableCell>
            {titles?.baremeType ||
              t('prejudice.capitalisation.form.fields.bareme.baremeType.title')}
          </TableCell>
          <TableCell align="right">
            {!!name.bareme && (
              <TypeBareme
                control={control}
                baremeName={name.bareme}
                baremes={baremes}
              />
            )}
          </TableCell>
        </TableRow>
      )}
      {hide?.ageDernierArrerage ||
      hide?.isLastArrerageViager ||
      !(
        component?.ageDernierArrerage ||
        (name.ageDernierArrerage && name.bareme && name.isLastArrerageViager)
      ) ? null : name.ageDernierArrerage &&
        name.bareme &&
        name.isLastArrerageViager ? (
        <ComputedPropsForm
          control={control}
          customValues={[ageDateAttribution]}
          watchFields={[name.bareme]}
          compute={([baremeCapitalisation]) => ({
            props: {
              displayAgeDernierArrerage: !(
                baremes.find((bareme) => bareme._id === baremeCapitalisation)
                  ?.source === 'CJ'
              ),
              observedAgeDateAttribution: ageDateAttribution,
            },
          })}
          render={({
            displayAgeDernierArrerage,
            observedAgeDateAttribution,
          }) =>
            displayAgeDernierArrerage &&
            name.bareme &&
            name.ageDernierArrerage &&
            name.isLastArrerageViager ? (
              <TableRow>
                <TableCell>
                  {titles?.ageDernierArrerage ||
                    t(
                      'prejudice.capitalisation.form.fields.ageDernierArrerage.title',
                    )}
                </TableCell>
                <TableCell align="right">
                  <SelectDerniersArrerages
                    control={control}
                    baremeName={name.bareme}
                    ageDernierArrerageName={name.ageDernierArrerage}
                    isLastArrerageViagerName={name.isLastArrerageViager}
                    baremes={baremes}
                    sexe={sexe}
                    ageDateAttribution={observedAgeDateAttribution}
                  />
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )
          }
        />
      ) : null}
      {hide?.coefficient ||
      !(
        component?.montantCapitalise ||
        (name.bareme &&
          name.ageDernierArrerage &&
          name.isLastArrerageViager &&
          name.coefficient)
      ) ? null : (
        <TableRow>
          <TableCell>
            {titles?.coefficient ||
              t('prejudice.capitalisation.form.fields.coefficient.title')}
          </TableCell>
          <TableCell align="right">
            {component?.coefficient ||
              (name.bareme &&
              name.ageDernierArrerage &&
              name.isLastArrerageViager &&
              name.coefficient ? (
                <Coefficient
                  control={control}
                  baremeName={name.bareme}
                  ageDernierArrerageName={name.ageDernierArrerage}
                  isLastArrerageViagerName={name.isLastArrerageViager}
                  baremes={baremes}
                  sexe={sexe}
                  coefficientName={name.coefficient}
                  dateNaissance={dateNaissance}
                  dateLiquidation={dateLiquidation}
                  editedFieldsName={editedFieldsName}
                  ageDateAttribution={ageDateAttribution}
                  ageDateAttributionName={name.ageDateAttribution}
                  dateAttribution={props.dateAttribution}
                  dateDeces={dateDeces}
                  enableCapitalisationDiffereeName={
                    name.enableCapitalisationDifferee
                  }
                />
              ) : null)}
          </TableCell>
        </TableRow>
      )}
      {hide?.montantCapitalise ||
      !(
        component?.montantCapitalise ||
        (name.montantCapitalise && name.coefficient && name.montantCapitalise)
      ) ? null : (
        <TableRow>
          <TableCell>
            {titles?.montantCapitalise ||
              t('prejudice.capitalisation.form.fields.montantCapitalise.title')}
          </TableCell>
          <TableCell align="right">
            {component?.montantCapitalise ||
              (name.sommeACapitaliser &&
              name.coefficient &&
              name.montantCapitalise ? (
                name.bareme ? (
                  <ComputedPropsForm
                    control={control}
                    watchFields={[name.bareme]}
                    compute={([baremeCapitalisation]) => ({
                      props: {
                        isTextField:
                          baremes.find(
                            (bareme) => bareme._id === baremeCapitalisation,
                          )?.source === 'CJ',
                      },
                    })}
                    render={({ isTextField }) =>
                      isTextField && name.montantCapitalise ? (
                        <TextFieldForm
                          control={control}
                          name={name.montantCapitalise}
                          InputProps={{
                            inputComponent: MaskNumber as any,
                            inputProps: {
                              numberMask: { scale: 2 },
                              suffix: '€',
                            } as IMaskNumberProps,
                          }}
                        />
                      ) : !isTextField &&
                        name.montantCapitalise &&
                        name.sommeACapitaliser &&
                        name.coefficient ? (
                        <MontantCapitalise
                          control={control}
                          sommeACapitaliserName={name.sommeACapitaliser}
                          coefficientName={name.coefficient}
                          montantCapitaliseName={name.montantCapitalise}
                          isEditable={editable?.victime?.montantCapitalise}
                          editedFieldsName={editedFieldsName}
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                ) : (
                  <MontantCapitalise
                    control={control}
                    sommeACapitaliserName={name.sommeACapitaliser}
                    coefficientName={name.coefficient}
                    montantCapitaliseName={name.montantCapitalise}
                    isEditable={editable?.victime?.montantCapitalise}
                    editedFieldsName={editedFieldsName}
                  />
                )
              ) : null)}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
